import React, { useState, useEffect } from 'react';
import { ArrowLeft, Coins, DollarSign, FileText, CreditCard, Clock } from 'lucide-react';
import { ref, push, set, serverTimestamp, get } from 'firebase/database';
import { auth, database } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';

const AmountInput = ({ value, onChange, selectedAsset }) => {
  const [cryptoPrice, setCryptoPrice] = useState(null);
  const [cryptoAmount, setCryptoAmount] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await fetch(
          `https://api.coingecko.com/api/v3/simple/price?ids=${getCoinGeckoId(selectedAsset)}&vs_currencies=usd`
        );
        if (!response.ok) throw new Error('Failed to fetch price');
        const data = await response.json();
        setCryptoPrice(data[getCoinGeckoId(selectedAsset)].usd);
        setIsLoading(false);
        setError(null);
      } catch (err) {
        console.error('Error fetching price:', err);
        setError('Failed to fetch price');
        setIsLoading(false);
      }
    };

    fetchPrice();
    const interval = setInterval(fetchPrice, 30000);
    return () => clearInterval(interval);
  }, [selectedAsset]);

  const getCoinGeckoId = (asset) => {
    const mapping = {
      'BTC': 'bitcoin',
      'ETH': 'ethereum',
      'USDT': 'tether'
    };
    return mapping[asset];
  };

  const handleUsdInput = (e) => {
    const usdAmount = e.target.value;
    onChange(usdAmount);

    if (cryptoPrice && usdAmount) {
      const cryptoValue = (parseFloat(usdAmount) / cryptoPrice).toFixed(8);
      setCryptoAmount(cryptoValue);
    } else {
      setCryptoAmount('');
    }
  };

  return (
    <div className="space-y-2">
      <label className="block text-sm text-gray-300 font-medium mb-1">Amount to Sell</label>
      <div className="relative">
        <input
          type="number"
          value={value}
          onChange={handleUsdInput}
          placeholder="Enter USD amount"
          className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-gray-200 placeholder-gray-500 focus:border-yellow-500 focus:ring-yellow-500"
          min="0"
          step="any"
        />
        <div className="absolute right-3 top-2 text-sm text-gray-400">USD</div>
      </div>
      
      {isLoading ? (
        <div className="text-sm text-gray-400">Loading {selectedAsset} price...</div>
      ) : error ? (
        <div className="text-sm text-red-400">{error}</div>
      ) : cryptoAmount ? (
        <div className="flex justify-between text-sm text-gray-400">
          <span>≈ {cryptoAmount} {selectedAsset}</span>
          <span className="text-gray-500">@ ${cryptoPrice?.toLocaleString()} per {selectedAsset}</span>
        </div>
      ) : null}
    </div>
  );
};



const CreateOfferSteps = ({ setShowCreateOffer }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [offerData, setOfferData] = useState({
    asset: 'BTC',
    amount: '',
    minLimit: '',
    maxLimit: '',
    instructions: '',
    paymentMethod: '',
    paymentInfo: '',
    expiryTime: '30',
  });

  const steps = [
    {
      title: 'Select Asset',
      icon: <Coins className="w-5 h-5" />,
      fields: (
        <div className="space-y-4">
          <div>
            <label className="block text-sm text-gray-300 font-medium mb-1">Select Asset</label>
            <select
              value={offerData.asset}
              onChange={(e) => setOfferData({ ...offerData, asset: e.target.value })}
              className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-gray-200 focus:border-yellow-500 focus:ring-yellow-500"
            >
              <option value="BTC">Bitcoin (BTC)</option>
              <option value="ETH">Ethereum (ETH)</option>
              <option value="USDT">Tether (USDT)</option>
            </select>
          </div>
          <AmountInput 
            value={offerData.amount}
            onChange={(amount) => setOfferData({ ...offerData, amount })}
            selectedAsset={offerData.asset}
          />
        </div>
      )
    },
    {
      title: 'Set Limits',
      icon: <DollarSign className="w-5 h-5" />,
      fields: (
        <div className="space-y-4">
          <div>
            <label className="block text-sm text-gray-300 font-medium mb-1">Minimum Limit ($)</label>
            <input
              type="number"
              value={offerData.minLimit}
              onChange={(e) => setOfferData({ ...offerData, minLimit: e.target.value })}
              className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-gray-200 focus:border-yellow-500 focus:ring-yellow-500"
              placeholder="Enter minimum trade limit"
            />
          </div>
          <div>
            <label className="block text-sm text-gray-300 font-medium mb-1">Maximum Limit ($)</label>
            <input
              type="number"
              value={offerData.maxLimit}
              onChange={(e) => setOfferData({ ...offerData, maxLimit: e.target.value })}
              className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-gray-200 focus:border-yellow-500 focus:ring-yellow-500"
              placeholder="Enter maximum trade limit"
            />
            {parseFloat(offerData.maxLimit) <= parseFloat(offerData.minLimit) && offerData.maxLimit && (
              <p className="text-sm text-red-400 mt-1">Maximum limit must be greater than minimum limit</p>
            )}
          </div>
        </div>
      )
    },
    {
      title: 'Buyer Instructions',
      icon: <FileText className="w-5 h-5" />,
      fields: (
        <div>
          <label className="block text-sm text-gray-300 font-medium mb-1">Instructions for Buyer</label>
          <textarea
            value={offerData.instructions}
            onChange={(e) => setOfferData({ ...offerData, instructions: e.target.value })}
            placeholder="Enter detailed instructions for the buyer..."
            className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-gray-200 placeholder-gray-500 focus:border-yellow-500 focus:ring-yellow-500 h-32"
          />
        </div>
      )
    },
    {
      title: 'Payment Details',
      icon: <CreditCard className="w-5 h-5" />,
      fields: (
        <div className="space-y-4">
          <div>
            <label className="block text-sm text-gray-300 font-medium mb-1">Payment Method</label>
            <select
              value={offerData.paymentMethod}
              onChange={(e) => setOfferData({ ...offerData, paymentMethod: e.target.value })}
              className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-gray-200 focus:border-yellow-500 focus:ring-yellow-500"
            >
              <option value="">Select payment method</option>
              <option value="Bank Transfer">Bank Transfer</option>
              <option value="M-pesa">M-Pesa</option>
              <option value="PayPal">PayPal</option>
              <option value="Western Union">Western Union</option>
              <option value="ACH Transfer">ACH Transfer</option>
              <option value="SWIFT Transfer">SWIFT Transfer</option>
              <option value="Wise">Wise</option>
  <option value="Skrill">Skrill</option>
  <option value="Neteller">Neteller</option>
  <option value="Perfect Money">Perfect Money</option>
  <option value="Payoneer">Payoneer</option>
  <option value="WebMoney">WebMoney</option>
  <option value="AirTM">AirTM</option>
  <option value="MoneyGram">MoneyGram</option>
  <option value="Ria Money Transfer">Ria Money Transfer</option>
  <option value="Remitly">Remitly</option>
  <option value="WorldRemit">WorldRemit</option>
  <option value="Apple Pay">Apple Pay</option>
  <option value="Google Pay">Google Pay</option>
  <option value="Samsung Pay">Samsung Pay</option>
  <option value="Cash App">Cash App</option>
  <option value="Venmo">Venmo</option>
  <option value="Zelle">Zelle</option>

            </select>
          </div>
          <div>
            <label className="block text-sm text-gray-300 font-medium mb-1">Payment Information</label>
            <textarea
              value={offerData.paymentInfo}
              onChange={(e) => setOfferData({ ...offerData, paymentInfo: e.target.value })}
              placeholder="Enter your payment details..."
              className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-gray-200 placeholder-gray-500 focus:border-yellow-500 focus:ring-yellow-500 h-32"
            />
          </div>
        </div>
      )
    },
    {
      title: 'Trade Expiry',
      icon: <Clock className="w-5 h-5" />,
      fields: (
        <div>
          <label className="block text-sm text-gray-300 font-medium mb-1">Trade Expiry Time (minutes)</label>
          <input
            type="number"
            value={offerData.expiryTime}
            onChange={(e) => setOfferData({ ...offerData, expiryTime: e.target.value })}
            min="30"
            className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-gray-200 focus:border-yellow-500 focus:ring-yellow-500"
          />
          <p className="text-sm text-gray-500 mt-2">Minimum expiry time is 30 minutes</p>
        </div>
      )
    }
  ];

  useEffect(() => {
    const fetchUserData = async (user) => {
      if (!user) return;

      try {
        const userRef = ref(database, `users/${user.uid}`);
        const snapshot = await get(userRef);
        
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setCurrentUser({
            uid: user.uid,
            username: userData.username,
          });
        } else {
          throw new Error('User data not found');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setShowCreateOffer(false);
        alert('Unable to fetch user data. Please try again later.');
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData(user);
      } else {
        setCurrentUser(null);
        setShowCreateOffer(false);
        window.location.href = '/login';
      }
    });

    return () => unsubscribe();
  }, [setShowCreateOffer]);

  const validateOfferData = () => {
    if (!offerData.asset || !['BTC', 'ETH', 'USDT'].includes(offerData.asset)) {
      throw new Error('Invalid asset selected');
    }

    const amount = parseFloat(offerData.amount);
    if (isNaN(amount) || amount <= 0) {
      throw new Error('Invalid amount');
    }

    const minLimit = parseFloat(offerData.minLimit);
    if (isNaN(minLimit) || minLimit <= 0) {
      throw new Error('Please enter a valid minimum limit');
    }

    const maxLimit = parseFloat(offerData.maxLimit);
    if (isNaN(maxLimit) || maxLimit <= 0) {
      throw new Error('Please enter a valid maximum limit');
    }

    if (minLimit >= maxLimit) {
      throw new Error('Maximum limit must be greater than minimum limit');
    }

    if (!offerData.instructions.trim()) {
      throw new Error('Instructions are required');
    }

    if (!offerData.paymentMethod || !offerData.paymentInfo.trim()) {
      throw new Error('Payment details are required');
    }

    const expiryTime = parseInt(offerData.expiryTime);
    if (isNaN(expiryTime) || expiryTime < 30) {
      throw new Error('Expiry time must be at least 30 minutes');
    }

    return {
      asset: offerData.asset,
      amount: amount,
      minLimit: minLimit,
      maxLimit: maxLimit,
      instructions: offerData.instructions.trim(),
      paymentMethod: offerData.paymentMethod,
      paymentInfo: offerData.paymentInfo.trim(),
      expiryTime: expiryTime,
    };
  };

  const handleCreateOffer = async () => {
    if (!currentUser?.uid) {
      alert('Please log in to create an offer');
      return;
    }

    try {
      setLoading(true);
      
      const validatedData = validateOfferData();

      const offersRef = ref(database, 'offers');
      const newOfferRef = push(offersRef);
      
      const offerToSave = {
        ...validatedData,
        status: 'active',
        timestamp: serverTimestamp(),
        expiryTimestamp: new Date(Date.now() + validatedData.expiryTime * 60000).getTime(),
        sellerId: currentUser.uid,
        sellerName: currentUser.username,
      };

      await set(newOfferRef, offerToSave);
      setLoading(false);
      alert('Offer created successfully!');
      setShowCreateOffer(false);
    } catch (error) {
      console.error('Error creating offer:', error);
      setLoading(false);
      alert(error.message || 'Failed to create offer. Please try again.');
    }
  };

  const validateStep = (step) => {
    try {
      switch (step) {
        case 1:
          return offerData.asset && parseFloat(offerData.amount) > 0;
        case 2:
          const minLimit = parseFloat(offerData.minLimit);
          const maxLimit = parseFloat(offerData.maxLimit);
          return !isNaN(minLimit) && !isNaN(maxLimit) && 
                 minLimit > 0 && maxLimit > 0 && 
                 maxLimit > minLimit;
        case 3:
          return offerData.instructions.trim().length > 0;
        case 4:
          return offerData.paymentMethod && offerData.paymentInfo.trim().length > 0;
        case 5:
          return parseInt(offerData.expiryTime) >= 30;
        default:
          return true;
      }
    } catch {
      return false;
    }
  };


  if (!currentUser) {
    return (
      <div className="flex items-center justify-center min-h-screen w-full bg-gray-900 text-gray-300">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-500"></div>
        <span className="ml-2">Loading user data...</span>
      </div>
    );
  }

  return (
    <div className="min-h-screen w-full bg-gray-900">
      {/* Wrapper for content with max width but full dark background */}
      <div className="max-w-2xl mx-auto p-4">
        <button
          onClick={() => {
            if (currentStep === 1) {
              setShowCreateOffer(false);
            } else {
              setCurrentStep(currentStep - 1);
            }
          }}
          className="flex items-center text-gray-300 hover:text-yellow-500 mb-6 transition-colors duration-200"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back
        </button>

        <div className="mb-4">
          <p className="text-sm text-gray-400">Creating offer as:</p>
          <p className="font-medium text-gray-200">{currentUser.username}</p>
        </div>

        <div className="flex items-center justify-between mb-8">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`flex flex-col items-center ${
                index + 1 === currentStep
                  ? 'text-yellow-500'
                  : index + 1 < currentStep
                  ? 'text-green-500'
                  : 'text-gray-600'
              }`}
            >
              <div className={`p-2 rounded-full mb-2 ${
                index + 1 === currentStep
                  ? 'bg-yellow-500 bg-opacity-20'
                  : index + 1 < currentStep
                  ? 'bg-green-500 bg-opacity-20'
                  : 'bg-gray-800'
              }`}>
                {step.icon}
              </div>
              <span className="text-xs">{step.title}</span>
            </div>
          ))}
        </div>

        <div className="bg-gray-800 p-6 rounded-lg shadow-lg border border-gray-700">
          <h2 className="text-xl font-semibold mb-4 text-gray-200">{steps[currentStep - 1].title}</h2>
          {steps[currentStep - 1].fields}
          
          <div className="mt-6 flex justify-end">
            {currentStep < steps.length ? (
              <button
                onClick={() => setCurrentStep(currentStep + 1)}
                disabled={!validateStep(currentStep)}
                className="px-4 py-2 bg-yellow-500 hover:bg-yellow-600 text-gray-900 font-medium rounded-md disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
              >
                Next Step
              </button>
            ) : (
              <button
                onClick={handleCreateOffer}
                disabled={!validateStep(currentStep) || loading}
                className="px-4 py-2 bg-yellow-500 hover:bg-yellow-600 text-gray-900 font-medium rounded-md disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
              >
                {loading ? 'Creating Offer...' : 'Create My Offer'}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateOfferSteps;