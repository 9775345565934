import React, { useState } from 'react';
import Header from './header';
import { useNavigate } from 'react-router-dom';
import {
  Trophy,
  Users,
  Copy,
  TrendingUp,
  Shield,
  Globe2,
  ChevronRight,
  Star,
  Wallet,
  AlertCircle
} from 'lucide-react';

const CopyTradingWebsite = () => {
  const [userBalance] = useState(0);
  const navigate = useNavigate();

  const handleDeposit = () => {
    navigate('/deposit');
  };


  const traders = [
    {
      name: "Alex Thomson",
      winRate: "89%",
      followers: "2.4K",
      profit: "534%",
      avatar: "/api/placeholder/100/100"
    },
    {
      name: "Sarah Chen",
      winRate: "92%",
      followers: "3.1K",
      profit: "721%",
      avatar: "/api/placeholder/100/100"
    },
    {
      name: "Michael Rodriguez",
      winRate: "87%",
      followers: "1.8K",
      profit: "428%",
      avatar: "/api/placeholder/100/100"
    }
  ];

  const features = [
    {
      icon: <Shield className="w-5 h-5" />,
      title: "Secure Trading",
      description: "Advanced encryption and security protocols to protect your assets"
    },
    {
      icon: <TrendingUp className="w-5 h-5" />,
      title: "Real-time Analytics",
      description: "Track performance and market movements in real-time"
    },
    {
      icon: <Globe2 className="w-5 h-5" />,
      title: "Global Access",
      description: "Trade and copy from anywhere, anytime"
    }
  ];

  

  return (
    <div className="pt-12 min-h-screen bg-black text-gray-100">
      <Header />
      <header className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-2xl md:text-3xl font-bold mb-4">
            Copy Trade with the Best
          </h1>
          <p className="text-base md:text-lg text-gray-400 mb-6">
            Follow top-performing traders and automatically copy their winning strategies
          </p>
          <div className="flex items-center justify-center gap-2 mb-4">
            <Wallet className="w-5 h-5 text-yellow-500" />
            <span className="text-sm">Your Balance: ${userBalance.toFixed(2)}</span>
          </div>
          {userBalance <= 0 && (
            <div className="flex items-center justify-center gap-2 bg-red-900/50 text-red-200 p-3 rounded-lg mb-4">
              <AlertCircle className="w-4 h-4" />
              <span className="text-sm">Please deposit funds to start copy trading</span>
            </div>
          )}
          <button 
            onClick={handleDeposit}
            className="bg-yellow-600 hover:bg-yellow-700 text-white px-6 py-3 rounded-lg text-sm font-semibold transition-colors flex items-center gap-2 mx-auto"
          >
            <Wallet className="w-4 h-4" />
            Deposit Now
          </button>
        </div>
      </header>

      {/* Pro Traders Section */}
      <section className="bg-gray-800 py-12">
        <div className="container mx-auto px-4">
          <h2 className="text-xl md:text-2xl font-bold mb-8 text-center">
            Top Performing Traders
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {traders.map((trader, index) => (
              <div key={index} className="bg-gray-900 rounded-xl p-4 hover:shadow-xl transition-shadow">
                <div className="flex items-center mb-3">
                  <img
                    src={trader.avatar}
                    alt={trader.name}
                    className="w-10 h-10 rounded-full mr-3"
                  />
                  <div>
                    <h3 className="font-semibold text-base">{trader.name}</h3>
                    <div className="flex items-center text-yellow-500">
                      <Star className="w-3 h-3 mr-1" />
                      <span className="text-xs">Top Trader</span>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-3 text-xs">
                  <div>
                    <p className="text-gray-400">Win Rate</p>
                    <p className="font-semibold">{trader.winRate}</p>
                  </div>
                  <div>
                    <p className="text-gray-400">Followers</p>
                    <p className="font-semibold">{trader.followers}</p>
                  </div>
                  <div>
                    <p className="text-gray-400">Total Profit</p>
                    <p className="font-semibold text-green-500">+{trader.profit}</p>
                  </div>
                </div>
                <button 
                  className="w-full mt-3 bg-yellow-600 hover:bg-yellow-700 text-white px-3 py-2 rounded-lg text-xs font-semibold transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={userBalance <= 0}
                  onClick={handleDeposit}
                >
                  {userBalance <= 0 ? 'Deposit to Copy' : 'Copy Trader'}
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-12">
        <div className="container mx-auto px-4">
          <h2 className="text-xl md:text-2xl font-bold mb-8 text-center">
            Why Choose Our Platform
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {features.map((feature, index) => (
              <div key={index} className="bg-gray-800 rounded-xl p-4 hover:shadow-xl transition-shadow">
                <div className="text-yellow-500 mb-3">{feature.icon}</div>
                <h3 className="text-base font-semibold mb-2">{feature.title}</h3>
                <p className="text-sm text-gray-400">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Join Section */}
      <section className="bg-gray-800 py-12">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-xl md:text-2xl font-bold mb-4">
              Ready to Start Copy Trading?
            </h2>
            <p className="text-sm text-gray-400 mb-6">
              Join thousands of successful traders and start copying their strategies today
            </p>
            <div className="flex flex-col md:flex-row gap-3 justify-center">
              <button 
                onClick={handleDeposit}
                className="bg-yellow-600 hover:bg-yellow-700 text-white px-4 py-2 rounded-lg text-xs font-semibold transition-colors flex items-center gap-2 justify-center"
              >
                <Wallet className="w-3 h-3" />
                Deposit Now
              </button>
              <button className="bg-gray-700 hover:bg-gray-600 text-white px-6 py-2 rounded-lg text-xs font-semibold transition-colors">
                Learn More
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CopyTradingWebsite;