import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase';
import { applyActionCode } from 'firebase/auth';
import { Loader2, CheckCircle, XCircle } from 'lucide-react';

const EmailVerificationHandler = ({ oobCode }) => {
  const [status, setStatus] = useState('verifying'); // 'verifying', 'success', 'error'
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        await applyActionCode(auth, oobCode);
        setStatus('success');
        // Redirect after 3 seconds
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } catch (error) {
        console.error('Verification error:', error);
        setStatus('error');
        setError(error.message);
      }
    };

    if (oobCode) {
      verifyEmail();
    } else {
      setStatus('error');
      setError('Invalid verification link');
    }
  }, [oobCode, navigate]);

  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-gray-800 rounded-lg shadow-xl p-8">
        {status === 'verifying' && (
          <div className="flex flex-col items-center space-y-4">
            <Loader2 className="h-12 w-12 text-yellow-500 animate-spin" />
            <h2 className="text-xl font-semibold text-white">Verifying Your Email</h2>
            <p className="text-gray-400 text-center">Please wait while we verify your email address...</p>
          </div>
        )}

        {status === 'success' && (
          <div className="flex flex-col items-center space-y-4">
            <CheckCircle className="h-12 w-12 text-green-500" />
            <h2 className="text-xl font-semibold text-white">Email Verified Successfully!</h2>
            <p className="text-gray-400 text-center">
              Your email has been verified. You will be redirected to the login page shortly.
            </p>
            <button
              onClick={() => navigate('/login')}
              className="px-4 py-2 bg-yellow-500 text-gray-900 rounded-md hover:bg-yellow-600 transition-colors"
            >
              Go to Login
            </button>
          </div>
        )}

        {status === 'error' && (
          <div className="flex flex-col items-center space-y-4">
            <XCircle className="h-12 w-12 text-red-500" />
            <h2 className="text-xl font-semibold text-white">Verification Failed</h2>
            <p className="text-gray-400 text-center">{error}</p>
            <button
              onClick={() => navigate('/login')}
              className="px-4 py-2 bg-yellow-500 text-gray-900 rounded-md hover:bg-yellow-600 transition-colors"
            >
              Go to Login
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailVerificationHandler;