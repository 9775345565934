import React, { useState, useEffect } from 'react';
import { Eye, EyeOff, ArrowLeftRight, LineChart, Clock, Filter } from 'lucide-react';
import { ref, onValue } from 'firebase/database';
import { database, auth } from './firebase';
import Header from './header';
import { onAuthStateChanged } from 'firebase/auth';


const AssetsWebsite = () => {
  const [balance, setBalance] = useState(0);
  const [btcValue, setBtcValue] = useState(0);
  const [activeTab, setActiveTab] = useState('account');
  const [loading, setLoading] = useState(true);
  const [showBalance, setShowBalance] = useState(true);
  const [btcPrice, setBtcPrice] = useState(0);
  const [user, setUser] = useState(null);

  useEffect(() => {
    let unsubscribeAuth = null;
    let unsubscribeBalance = null;
    let priceInterval = null;

    // Function to fetch BTC price and calculate value
    const fetchBtcPrice = async (currentBalance) => {
        try {
            const response = await fetch(
                'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd'
            );
            const data = await response.json();
            const btcUsdPrice = data.bitcoin.usd;
            setBtcPrice(btcUsdPrice);
            
            // Calculate BTC equivalent
            if (currentBalance && btcUsdPrice) {
                const btcAmount = currentBalance / btcUsdPrice;
                console.log("BTC equivalent:", btcAmount);
                setBtcValue(btcAmount);
            }
        } catch (error) {
            console.error('Error fetching BTC price:', error);
            setBtcValue(0);
        }
    };

    // Start auth listener
    unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
        if (user) {
            setUser(user);
            console.log("User authenticated with ID:", user.uid);

            // Use the correct path: /users/{userId}/balance
            const balanceRef = ref(database, `users/${user.uid}/balance`);
            
            unsubscribeBalance = onValue(balanceRef, async (snapshot) => {
                try {
                    const balanceValue = snapshot.val();
                    console.log("Raw balance value:", balanceValue);

                    if (balanceValue !== null) {
                        const numBalance = Number(balanceValue);
                        console.log("Setting balance to:", numBalance);
                        setBalance(numBalance);
                        
                        // Fetch initial BTC price and calculate equivalent
                        await fetchBtcPrice(numBalance);
                        if (!priceInterval) {
                            priceInterval = setInterval(() => fetchBtcPrice(numBalance), 60000);
                        }
                    } else {
                        console.log("No balance value found");
                        setBalance(0);
                        setBtcValue(0);
                    }
                } catch (error) {
                    console.error("Error processing balance:", error);
                    setBalance(0);
                    setBtcValue(0);
                } finally {
                    setLoading(false);
                }
            });
        } else {
            console.log("No user authenticated");
            setUser(null);
            setBalance(0);
            setBtcValue(0);
            setLoading(false);
        }
    });

    // Cleanup function
    return () => {
        console.log("Cleaning up subscriptions");
        if (unsubscribeAuth) unsubscribeAuth();
        if (unsubscribeBalance) unsubscribeBalance();
        if (priceInterval) clearInterval(priceInterval);
    };
}, []);
  

  const toggleBalanceVisibility = () => {
    setShowBalance(!showBalance);
  };

  const formatBalance = (value) => {
    return showBalance ? value.toFixed(2) : '****';
  };

  const formatBtcValue = (value) => {
    return showBalance ? value.toFixed(8) : '****';
  };

  return (
    <div className="pt-16 min-h-screen bg-black text-white">
     <Header/>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-xl font-semibold">My Assets</h1>
          <button className="text-gray-400 hover:text-white">
            <ArrowLeftRight className="w-5 h-5" />
          </button>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Left Column */}
          <div className="lg:col-span-1">
            <div className="bg-gray-900 rounded-xl p-4">
              <div className="flex items-center gap-2 text-gray-400 mb-3">
                <span className="text-base">Total Assets</span>
                <button onClick={toggleBalanceVisibility} className="hover:text-white">
                  {showBalance ? <Eye className="w-4 h-4" /> : <EyeOff className="w-4 h-4" />}
                </button>
              </div>
              
              <div className="flex items-center gap-2 mb-3">
                {loading ? (
                  <span className="text-2xl font-bold">Loading...</span>
                ) : (
                  <>
                    <span className="text-2xl font-bold">${formatBalance(balance)}</span>
                  </>
                )}
              </div>

              <div className="flex items-center gap-2 text-gray-400 text-sm mb-2">
                <span>≈ {formatBtcValue(btcValue)} BTC</span>
                <button className="w-4 h-4 rounded-full border border-gray-400 text-[10px] flex items-center justify-center">i</button>
              </div>
            </div>

            {/* Portfolio Analytics */}
            <div className="bg-gray-900 rounded-xl p-4 mt-4">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-base font-medium">Portfolio Analytics</h3>
                <LineChart className="w-4 h-4 text-gray-400" />
              </div>
              <div className="flex items-center justify-center h-32 text-gray-400 text-sm">
                No data available yet
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="lg:col-span-2">
            <div className="flex gap-4 text-sm mb-4">
              <button 
                onClick={() => setActiveTab('account')}
                className={`pb-2 ${activeTab === 'account' ? 'text-white font-medium border-b-2 border-yellow-600' : 'text-gray-500 hover:text-gray-300'}`}
              >
                Account
              </button>
              <button 
                onClick={() => setActiveTab('asset')}
                className={`pb-2 ${activeTab === 'asset' ? 'text-white font-medium border-b-2 border-yellow-600' : 'text-gray-500 hover:text-gray-300'}`}
              >
                Asset
              </button>
            </div>

            {activeTab === 'account' ? (
              <>
                {/* Account Balances */}
                <div className="space-y-3 mb-6">
      <button className="w-full bg-gray-900 p-4 rounded-xl flex items-center justify-between hover:bg-gray-800 transition-colors">
        <div>
          <div className="text-gray-400 text-sm mb-1">Funding</div>
          <div className="flex items-center gap-2">
            <span className="text-base font-medium">
              {loading ? (
                "Loading..."
              ) : (
                showBalance ? `$${balance.toFixed(2)}` : "****"
              )}
            </span>
          </div>
        </div>
        <ArrowLeftRight className="w-4 h-4 text-gray-400" />
      </button>

      <button className="w-full bg-gray-900 p-4 rounded-xl flex items-center justify-between hover:bg-gray-800 transition-colors">
        <div>
          <div className="text-gray-400 text-sm mb-1">Unified Trading</div>
          <div className="flex items-center gap-2">
            <span className="text-base font-medium">$0.00</span>
          </div>
        </div>
        <ArrowLeftRight className="w-4 h-4 text-gray-400" />
      </button>
    </div>

                {/* Transaction History */}
                <div className="bg-gray-900 rounded-xl p-4">
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-base font-medium">Recent Transactions</h3>
                    <button className="text-gray-400 hover:text-white">
                      <Filter className="w-4 h-4" />
                    </button>
                  </div>
                  <div className="flex items-center justify-center h-40 text-gray-400 text-sm">
                    No transaction history yet
                  </div>
                </div>
              </>
            ) : (
              <div className="bg-gray-900 rounded-xl p-4 flex items-center justify-center h-64">
                <span className="text-gray-400 text-sm">No asset data available</span>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default AssetsWebsite;