import React from 'react';
import paypalLogo from './assets/images/paypal.png';
import mpesaLogo from './assets/images/mpesa.png';
import bankLogo from './assets/images/bank.png';

const PaymentMethodBadge = ({ method }) => {
  const getPaymentLogo = (methodName) => {
    const methodLower = methodName.toLowerCase();
    if (methodLower.includes('paypal')) return paypalLogo;
    if (methodLower.includes('mpesa')) return mpesaLogo;
    if (methodLower.includes('bank')) return bankLogo;
    return null;
  };

  const logo = getPaymentLogo(method);

  return (
    <div className="flex items-center px-2 py-1 bg-gray-700 rounded text-sm text-white space-x-2">
      {logo && (
        <img
          src={logo}
          alt={`${method} logo`}
          className="w-12 h-12 object-contain"
        />
      )}
      <span>{method}</span>
    </div>
  );
};

export default PaymentMethodBadge;