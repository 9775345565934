import React, { useState, useEffect } from 'react';
import { Plus, RefreshCw, Loader2, Info, Clock, Check, Wallet, BadgeCheck, ArrowRight, ThumbsUp, Timer, AlertCircle, Trash2, User, Globe, Search, X, ChevronDown, ChevronUp, TrendingUp, ArrowUpRight, Shield } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { ref, onValue, push, serverTimestamp, remove } from 'firebase/database';
import { auth, database } from './firebase';
import CreateOfferSteps from './createoffers';
import badgeIcon from './assets/images/badge.png';
import Header from './header';
import PaymentMethodBadge from './paymentmethod';
import ChatList from './mychats';
import P2POffersList from './offerslist';


const P2PTrading = () => {
  const navigate = useNavigate();
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [showCreateOffer, setShowCreateOffer] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [showOfferDetails, setShowOfferDetails] = useState(null);
  const [activeTrades, setActiveTrades] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [ordersData, setOrdersData] = useState({});
  const [completionData, setCompletionData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFocused, setSearchFocused] = useState(false);
  const [showActiveTrades, setShowActiveTrades] = useState(true);
  const [unreadChatsCount, setUnreadChatsCount] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userBalance, setUserBalance] = useState(0);
  const [cancelTradeConfirmation, setCancelTradeConfirmation] = useState(null);
  const [offerData, setOfferData] = useState({
    asset: 'USD',
    amount: '',
    minLimit: '258',
    maxLimit: '1090',
    instructions: '',
    paymentMethod: '',
    paymentInfo: '',
    expiryTime: '30',
  });
  const handleCancelTrade = async (tradeId) => {
    try {
      // Remove the trade from the database
      const tradeRef = ref(database, `trades/${tradeId}`);
      await remove(tradeRef);
      
      // Close the confirmation modal
      setCancelTradeConfirmation(null);
      
      // Show success message
      alert('Trade cancelled successfully');
    } catch (error) {
      console.error('Error cancelling trade:', error);
      alert('Failed to cancel trade. Please try again.');
    }
  };

  // Add Cancel Trade Confirmation Modal component
  const CancelTradeConfirmationModal = ({ trade, onClose }) => (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-gray-900 rounded-lg p-6 max-w-md w-full border border-gray-800">
        <div className="flex items-center gap-3 text-red-500 mb-4">
          <AlertCircle className="w-6 h-6" />
          <h3 className="text-lg font-bold">Cancel Trade</h3>
        </div>

        <p className="text-gray-300 mb-4">
          Are you sure you want to cancel this trade? This action cannot be undone.
        </p>

        <div className="flex flex-col space-y-2 text-sm text-gray-400 mb-6">
          <p>• Amount: {trade.cryptoAmount} {trade.asset}</p>
          <p>• Fiat Amount: ${trade.fiatAmount}</p>
          <p>• Payment Method: {trade.paymentMethod}</p>
          {auth.currentUser.uid === trade.sellerId ? (
            <p>• With Buyer: {trade.buyerUsername}</p>
          ) : (
            <p>• With Seller: {trade.sellerName}</p>
          )}
        </div>

        <div className="flex gap-3">
          <button
            onClick={onClose}
            className="flex-1 px-4 py-2 border border-gray-700 text-white rounded-md hover:bg-gray-800"
          >
            Keep Trade
          </button>
          <button
            onClick={() => handleCancelTrade(trade.id)}
            className="flex-1 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
          >
            Cancel Trade
          </button>
        </div>
      </div>
    </div>
  );

  const UserAvatar = ({ name, className = "" }) => (
    <div className={`relative ${className}`}>
      <div className="w-7 h-7 bg-gray-700 rounded-full flex items-center justify-center text-white text-sm">
        {name.charAt(0).toUpperCase()}
      </div>
      {/* Online status indicator */}
      <div className="absolute bottom-0 right-0 w-2.5 h-2.5 bg-green-500 rounded-full border-2 border-gray-800"></div>
    </div>
  );
  const clearSearch = () => {
    setSearchTerm('');
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const filterOffersBySearch = (offers) => {
    if (!searchTerm.trim()) return offers;

    const searchLower = searchTerm.toLowerCase();
    return offers.filter(offer =>
      offer.sellerName?.toLowerCase().includes(searchLower) ||
      offer.paymentMethod?.toLowerCase().includes(searchLower) ||
      offer.asset?.toLowerCase().includes(searchLower)
    );
  };
  const filterOffers = (offers) => {
    // First filter by search term if it exists
    let filteredOffers = offers;
    if (searchTerm.trim()) {
      const searchLower = searchTerm.toLowerCase();
      filteredOffers = offers.filter(offer =>
      (offer.sellerName?.toLowerCase().includes(searchLower) ||
        offer.paymentMethod?.toLowerCase().includes(searchLower) ||
        offer.asset?.toLowerCase().includes(searchLower))
      );
    }
    const myOffers = filteredOffers.filter(offer => isUserOffer(offer));
    const otherOffers = filteredOffers.filter(offer => !isUserOffer(offer));

    return { myOffers, otherOffers };
  };

  const isUserOffer = (offer) => {
    return auth.currentUser && offer.sellerId === auth.currentUser.uid;
  };
  const handleDeleteOffer = async (offerId) => {
    try {
      const offerRef = ref(database, `offers/${offerId}`);
      await remove(offerRef);
      setDeleteConfirmation(null);
      alert('Offer deleted successfully');
    } catch (error) {
      console.error('Error deleting offer:', error);
      alert('Failed to delete offer. Please try again.');
    }
  };
  const NoOffersSection = () => (
    <div className="bg-gray-800/50 rounded-lg p-6 text-center">
      <div className="text-gray-400 mb-4">
        You haven't posted any buy or sell offers yet
      </div>
      {userBalance > 0 ? (
        <button
          onClick={() => setShowCreateOffer(true)}
          className="px-4 py-2 text-sm bg-yellow-700 text-white rounded-md hover:bg-gray-700 transition-all duration-200"
        >
          Create Your First Offer
        </button>
      ) : (
        <div className="space-y-3">
          <div className="flex items-center justify-center text-yellow-500 gap-2">
            <Info className="w-5 h-5" />
            <span className="text-sm">Insufficient balance to create an offer</span>
          </div>
          <button
            onClick={() => navigate('/deposit')}
            className="px-4 py-2 text-sm bg-[#02C076] text-white rounded-md hover:bg-[#02A566] transition-all duration-200"
          >
            Buy Crypto First
          </button>
        </div>
      )}
    </div>
  );
  const DeleteConfirmationModal = ({ offer, onClose }) => (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-gray-900 rounded-lg p-6 max-w-md w-full border border-gray-800">
        <div className="flex items-center gap-3 text-red-500 mb-4">
          <AlertCircle className="w-6 h-6" />
          <h3 className="text-lg font-bold">Delete Offer</h3>
        </div>

        <p className="text-gray-300 mb-4">
          Are you sure you want to delete your {offer.asset} offer? This action cannot be undone.
        </p>

        <div className="flex flex-col space-y-2 text-sm text-gray-400 mb-6">
          <p>• Amount: {offer.amount} {offer.asset}</p>
          <p>• Price Range: ${offer.minLimit} - ${offer.maxLimit}</p>
          <p>• Payment Method: {offer.paymentMethod}</p>
        </div>

        <div className="flex gap-3">
          <button
            onClick={onClose}
            className="flex-1 px-4 py-2 border border-gray-700 text-white rounded-md hover:bg-gray-800"
          >
            Cancel
          </button>
          <button
            onClick={() => handleDeleteOffer(offer.id)}
            className="flex-1 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
          >
            Delete Offer
          </button>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    console.log("Starting data fetch...");
    setLoading(true);

    const offersRef = ref(database, 'offers');
    const tradesRef = ref(database, 'trades');
    const ordersRef = ref(database, 'orders');
    const completionRef = ref(database, 'completion');

    let unsubscribeOffers;
    let unsubscribeBalance;

    // Only set up balance listener if user is authenticated
    if (auth.currentUser) {
      const balanceRef = ref(database, `users/${auth.currentUser.uid}/balance`);
      unsubscribeBalance = onValue(balanceRef, (snapshot) => {
        if (snapshot.exists()) {
          setUserBalance(snapshot.val());
        } else {
          setUserBalance(0);
        }
      });
    }

    let hasOrdersData = false;
    let hasCompletionData = false;
    let currentOrdersData = {};
    let currentCompletionData = {};

    const unsubscribeOrders = onValue(ordersRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        currentOrdersData = data;
        setOrdersData(data);
      } else {
        currentOrdersData = {};
        setOrdersData({});
      }
      hasOrdersData = true;
      updateOffersIfReady();
    });

    const unsubscribeCompletion = onValue(completionRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const processedData = Object.entries(data).reduce((acc, [key, value]) => {
          const numValue = parseFloat(value);
          acc[key] = isNaN(numValue) ? 0 : numValue;
          return acc;
        }, {});
        currentCompletionData = processedData;
        setCompletionData(processedData);
      } else {
        currentCompletionData = {};
        setCompletionData({});
      }
      hasCompletionData = true;
      updateOffersIfReady();
    });

    function updateOffersIfReady() {
      if (hasOrdersData && hasCompletionData) {
        if (unsubscribeOffers) {
          unsubscribeOffers();
        }

        unsubscribeOffers = onValue(offersRef, (snapshot) => {
          if (snapshot.exists()) {
            const offersData = snapshot.val();
            const offersArray = Object.entries(offersData).map(([id, data]) => ({
              id,
              ...data,
              orders: currentOrdersData[data.sellerId] || 0,
              completion: currentCompletionData[data.sellerId] || 0
            }));

            setOffers(offersArray.sort((a, b) =>
              b.timestamp ? b.timestamp - a.timestamp : -1
            ));
          } else {
            setOffers([]);
          }
          setLoading(false);
        });
      }
    }

    // Corrected trades listener with proper filtering
    const unsubscribeTrades = onValue(tradesRef, (snapshot) => {
      if (snapshot.exists() && auth.currentUser) {
        const tradesData = snapshot.val();
        const activeTradesArray = Object.entries(tradesData)
          .map(([id, data]) => ({
            id,
            ...data
          }))
          .filter(trade =>
            (trade.buyerId === auth.currentUser.uid || trade.sellerId === auth.currentUser.uid) &&
            trade.status === 'paid'
          )
          .sort((a, b) => b.createdAt - a.createdAt);

        setActiveTrades(activeTradesArray);
      } else {
        setActiveTrades([]);
      }
    }, (error) => {
      console.error('Firebase trades error:', error);
      setLoading(false);
    });

    return () => {
      if (unsubscribeBalance) unsubscribeBalance();
      if (unsubscribeOffers) unsubscribeOffers();
      unsubscribeTrades();
      unsubscribeOrders();
      unsubscribeCompletion();
    };
  }, []); // Empty dependency array
  const handleLoadMore = async () => {
    setLoadingMore(true);
    // Simulate loading more offers
    await new Promise(resolve => setTimeout(resolve, 1500));
    // Add your actual pagination logic here
    setLoadingMore(false);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px] text-black">
        <Loader2 className="w-8 h-8 animate-spin" />
        <span className="ml-2">Loading offers...</span>
      </div>
    );
  }
  const formatDisputeTime = (trade) => {
    const paidAt = trade.paidAt;
    const disputeWindow = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
    const now = Date.now();
    const timeLeft = (paidAt + disputeWindow) - now;

    if (timeLeft <= 0) return 'Dispute window closed';

    const minutes = Math.floor(timeLeft / (60 * 1000));
    const seconds = Math.floor((timeLeft % (60 * 1000)) / 1000);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };


  const OfferDetails = ({ offer, onClose }) => (
    <div className="fixed inset-0 bg-black/80 flex items-center justify-center p-4 backdrop-blur-sm">
      <div className="bg-gray-900 rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto border border-gray-800">
        <h2 className="text-xl font-bold mb-4 text-white">Offer Details</h2>
        <div className="space-y-4">
          <div>
            <p className="text-sm text-gray-400">Seller</p>
            <p className="font-medium text-white">{offer.sellerName}</p>
          </div>
          <div>
            <p className="text-sm text-gray-400">Asset</p>
            <p className="font-medium text-white">{offer.amount} {offer.asset}</p>
          </div>
          <div>
            <p className="text-sm text-gray-400">Trade Limits</p>
            <p className="font-medium text-white">${offer.minLimit} - ${offer.maxLimit}</p>
          </div>
          <div>
            <p className="text-sm text-gray-400">Instructions</p>
            <p className="font-medium text-white whitespace-pre-wrap">{offer.instructions}</p>
          </div>
          <div>
            <p className="text-sm text-gray-400">Payment Method</p>
            <p className="font-medium text-white">{offer.paymentMethod}</p>
          </div>
          <div>
            <p className="text-sm text-gray-400">Payment Information</p>
            <p className="font-medium text-white whitespace-pre-wrap">{offer.paymentInfo}</p>
          </div>
          <div>
            <p className="text-sm text-gray-400">Expiry Time</p>
            <p className="font-medium text-white">{offer.expiryTime} minutes</p>
          </div>
          <div className="flex justify-end gap-2 mt-6">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm border border-gray-700 rounded-md hover:bg-gray-800 text-gray-300"
            >
              Close
            </button>
            <button
              onClick={() => {
                alert(`Starting trade with ${offer.sellerName} for ${offer.amount} ${offer.asset}`);
                onClose();
              }}
              className="px-4 py-2 text-sm bg-yellow-600 text-white rounded-md hover:bg-yellow-700"
            >
              Buy Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px] text-black">
        <Loader2 className="w-8 h-8 animate-spin" />
        <span className="ml-2">Loading offers...</span>
      </div>
    );
  }

  if (showCreateOffer) {
    return (
      <CreateOfferSteps
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        offerData={offerData}
        setOfferData={setOfferData}
        setShowCreateOffer={setShowCreateOffer}
      />
    );
  }


  return (
    <div className="pt-16 min-h-screen w-full bg-black">
       <Header />
      {loadingMore && (
        <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm z-50 flex items-center justify-center">
          <div className="bg-gray-800 rounded-lg p-6 flex items-center space-x-3">
            <Loader2 className="w-6 h-6 animate-spin text-yellow-500" />
            <span className="text-white font-medium">Loading more offers...</span>
          </div>
        </div>
      )}
      <div className="p-4 max-w-7xl mx-auto">
       
        {/* Header Section */}
        <div className="mb-8">
          <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-6">
            {/* Title and Subtitle */}
            <div className="space-y-2">
              <h1 className="text-2xl md:text-3xl font-bold text-white">
                Buy with more than 300 payment methods
              </h1>
              <p className="text-gray-400 text-sm md:text-base">
                Buy and sell cryptocurrencies directly with other traders
              </p>
            </div>

            {/* Enhanced Search Input */}
            <div className="relative group">
              <div className={`
                relative flex items-center transition-all duration-300
                ${searchFocused ? 'scale-105' : 'scale-100'}
              `}>
                {/* Enhanced Search Input */}
                <div className="relative w-full md:w-auto">
                  <div className={`
    relative transition-all duration-300
    ${searchFocused ? 'scale-102' : 'scale-100'}
  `}>
                    <input
                      type="text"
                      placeholder="Search offers..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onFocus={() => setSearchFocused(true)}
                      onBlur={() => setSearchFocused(false)}
                      className={`
        w-full md:w-[320px] px-4 py-2 pl-10
        bg-gray-800/50 backdrop-blur-sm
        border border-gray-700/50
        rounded-lg text-white
        placeholder-gray-500
        transition-all duration-300
        focus:outline-none focus:border-yellow-500/30 focus:ring-1 focus:ring-yellow-500/20
        hover:border-gray-600/80
        text-sm
      `}
                    />
                    <Search
                      className={`
        w-4 h-4 absolute left-3 top-1/2 transform -translate-y-1/2
        transition-colors duration-300
        ${searchFocused ? 'text-yellow-500' : 'text-gray-500'}
      `}
                    />
                    {searchTerm && (
                      <button
                        onClick={clearSearch}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 p-0.5 rounded-full text-gray-500 hover:text-gray-300 hover:bg-gray-700/50 transition-all duration-200"
                        aria-label="Clear search"
                      >
                        <X className="w-3.5 h-3.5" />
                      </button>
                    )}
                  </div>
                </div>
              </div>

              {/* Search Tooltip */}
              <div className="absolute -bottom-12 left-0 transform translate-y-2 opacity-0 pointer-events-none group-hover:opacity-100 transition-all duration-300">
                <div className="bg-gray-800 text-gray-300 text-xs py-2 px-3 rounded-md shadow-lg whitespace-nowrap">
                  Search by seller name, payment method, or asset
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Active Trades Section */}
        {activeTrades.length > 0 ? (
          <div className="mb-6">
            <button
              onClick={() => setShowActiveTrades(!showActiveTrades)}
              className="w-full flex items-center justify-between hover:bg-gray-800/30 transition-colors duration-200 rounded-md py-1.5 px-2"
            >
              <div className="flex items-center gap-1.5">
                <Clock className="w-4 h-4 text-yellow-500" />
                <h2 className="text-sm font-medium text-white flex items-center gap-1.5">
                  Active Trades <span className="text-yellow-500 text-xs">({activeTrades.length})</span>
                </h2>
              </div>
              <div className="flex items-center gap-1.5">
                <span className="text-xs text-gray-400">
                  {showActiveTrades ? 'Hide' : 'Show'}
                </span>
                {showActiveTrades ? (
                  <ChevronUp className="w-3.5 h-3.5 text-gray-400" />
                ) : (
                  <ChevronDown className="w-3.5 h-3.5 text-gray-400" />
                )}
              </div>
            </button>

            {showActiveTrades && (
  <div className="space-y-2 mt-3 animate-fadeIn">
    {activeTrades.map((trade) => (
      <div key={trade.id} className="bg-gray-800/50 border border-yellow-500/20 rounded-md p-3">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-3">
          {/* Trade Details Section */}
          <div className="flex items-center flex-wrap gap-3 w-full sm:w-auto">
         {auth.currentUser.uid === trade.sellerId ? (
    // Seller View
    <div className="flex items-center gap-1.5">
      <span className="text-sm font-medium text-white">
        You are selling {trade.cryptoAmount} {trade.asset}
      </span>
      <span className="text-xs text-gray-400">
        (${trade.fiatAmount}) to {trade.buyerUsername || 'Buyer'} {/* Added this */}
      </span>
    </div>
  ) : (
    <>
    <div className="flex items-center gap-1.5">
      <span className="text-sm text-white">You are buying</span>
      <span className="text-sm font-medium text-white">{trade.cryptoAmount} {trade.asset}</span>
      <span className="text-xs text-gray-400">(${trade.fiatAmount})</span>
    </div>

    <div className="hidden sm:block w-px h-4 bg-gray-700" />

    <div className="flex items-center gap-1.5">
      <span className="text-xs text-gray-400">From:</span>
      <span className="text-sm font-medium text-white">{trade.sellerName}</span>
    </div>
  </>
  )}

            <div className="hidden sm:block w-px h-4 bg-gray-700" />

            {/* Payment Method */}
            <div className="inline-flex items-center bg-gray-800/70 rounded px-1.5 py-1 border border-gray-700/50">
              <div className="w-0.5 h-3 bg-[#02C076] rounded-full mr-1.5"></div>
              <span className="text-xs text-white">{trade.paymentMethod}</span>
            </div>
          </div>

          {/* Action Section */}
          <div className="flex items-center justify-between sm:justify-end gap-3 w-full sm:w-auto">
                  <div className="flex items-center text-yellow-500">
                    <AlertCircle className="w-3.5 h-3.5 mr-1" />
                    <span className="text-xs">Time: {formatDisputeTime(trade)}</span>
                  </div>
                  <div className="flex gap-2">
                    <button
                      onClick={() => setCancelTradeConfirmation(trade)}
                      className="px-3 py-1.5 bg-red-600 text-white text-xs rounded hover:bg-red-700"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => navigate(`/trade/process/${trade.id}`)}
                      className="px-3 py-1.5 bg-[#02C076] text-white text-xs rounded hover:bg-[#02A566]"
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
       {/* Add the Cancel Trade Confirmation Modal */}
       {cancelTradeConfirmation && (
        <CancelTradeConfirmationModal
          trade={cancelTradeConfirmation}
          onClose={() => setCancelTradeConfirmation(null)}
        />
      )}
          </div>
        ) : (
          <div className="mb-6">
            <div className="flex items-center gap-1.5">
              <Clock className="w-4 h-4 text-yellow-500" />
              <h2 className="text-sm font-medium text-white">Active Trades</h2>
            </div>
            <div className="flex flex-col items-center justify-center text-center p-4 mt-3 border border-gray-800 rounded-md bg-gray-800/50">
              <Info className="w-8 h-8 text-gray-600 mb-2" />
              <p className="text-sm text-gray-400">
                No active trades at the moment
              </p>
            </div>
          </div>
        )}
<ChatList/>

       {/* My Offers Section */}
{/* My Offers Section */}
<div className="mb-8">
  <div className="flex items-center gap-2 mb-4">
    <User className="w-5 h-5 text-yellow-500" />
    <h2 className="text-lg font-semibold text-white">My Offers</h2>
  </div>

  {filterOffers(offers).myOffers.length > 0 ? (
    <div className="space-y-4">
      {filterOffers(offers).myOffers.map((offer) => (
        <div key={offer.id} className="group p-4 sm:p-5 bg-gray-800/30 backdrop-blur-xl rounded-xl border border-gray-700/50 hover:border-yellow-500/50 transition-all">
          <div className="flex justify-between items-start">
            <div className="space-y-2">
              <div className="text-lg font-medium bg-gradient-to-r from-yellow-500 to-yellow-300 bg-clip-text text-transparent">
                {offer.amount} {offer.asset}
              </div>
              <div className="space-y-1.5">
                <div className="inline-flex items-center bg-gray-800/70 rounded px-2 py-1 border border-gray-700/50">
                  <div className="w-0.5 h-3 bg-yellow-500 rounded-full mr-1.5"></div>
                  <span className="text-sm text-white">{offer.paymentMethod}</span>
                </div>
                <div className="flex items-center gap-4 text-sm text-gray-400">
                  <div>
                    Limits: <span className="text-gray-300">${offer.minLimit} - ${offer.maxLimit}</span>
                  </div>
                  <div>
                    Price: <span className="text-gray-300">{offer.price || '0.990'} USD</span>
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={() => setDeleteConfirmation(offer)}
              className="p-2 text-red-500 hover:bg-red-500/10 rounded-full transition-all"
            >
              <Trash2 className="w-5 h-5" />
            </button>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <NoOffersSection />
  )}
</div>

        {/* Available Offers Section */}
        <P2POffersList 
  offers={filterOffers(offers).otherOffers}
  onBuyClick={(offer) => setShowOfferDetails(offer)}
  onChatClick={(offer) => {/* handle chat */}}
  isUserOffer={isUserOffer}
  onDeleteOffer={(offer) => setDeleteConfirmation(offer)}
/>

        {filterOffers(offers).otherOffers.length > 0 && (
          <div className="mt-4 flex justify-center">
            <button
              onClick={handleLoadMore}
              disabled={loadingMore}
              className={`
              group relative inline-flex items-center gap-2
              px-5 py-2 text-sm
              bg-gradient-to-r from-[#02C076]/90 to-[#02A566]/90
              hover:from-[#02C076] hover:to-[#02A566]
              text-white font-medium
              rounded-md
              transition-all duration-200
              shadow-lg shadow-green-900/20
              disabled:opacity-50 disabled:cursor-not-allowed
              disabled:hover:from-[#02C076]/90 disabled:hover:to-[#02A566]/90
            `}
            >
              {loadingMore ? (
                <>
                  <Loader2 className="w-3.5 h-3.5 animate-spin" />
                  <span>Loading more offers</span>
                </>
              ) : (
                <>
                  <RefreshCw className="w-3.5 h-3.5 transition-transform group-hover:rotate-180 duration-500" />
                  <span>Load more offers</span>
                </>
              )}

              {/* Shine effect */}
              <div className="absolute inset-0 rounded-md overflow-hidden">
                <div
                  className={`
                  absolute top-0 -inset-full h-full w-1/2
                  block transform -skew-x-12
                  bg-gradient-to-r from-transparent to-white/10
                  group-hover:animate-shine
                  ${loadingMore ? 'opacity-0' : 'opacity-100'}
                `}
                />
              </div>
            </button>
          </div>
        )}
        {/* Delete confirmation modal */}
        {deleteConfirmation && (
          <DeleteConfirmationModal
            offer={deleteConfirmation}
            onClose={() => setDeleteConfirmation(null)}
          />
        )}

        {/* Offer Details Modal */}
        {showOfferDetails && (
  <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center p-4 z-50">
    <div className="bg-gray-900/90 backdrop-blur-xl rounded-xl p-6 max-w-2xl w-full border border-gray-800/60 shadow-2xl animate-in fade-in duration-300">
      {/* Header */}
      <div className="flex items-center gap-4 mb-6">
        <div className="h-10 w-10 rounded-lg bg-gradient-to-br from-[#02C076] to-[#02A566] flex items-center justify-center">
          <Wallet className="text-white" size={20} />
        </div>
        <div>
          <h2 className="text-xl font-bold text-white">Confirm Purchase</h2>
          <p className="text-sm text-gray-400">Review trade details before proceeding</p>
        </div>
      </div>

      {/* Trade Details */}
      <div className="bg-gray-800/50 rounded-lg p-4 mb-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="space-y-3">
            <div>
              <div className="text-sm text-gray-400">Seller</div>
              <div className="flex items-center gap-2 mt-1">
                <div className="h-6 w-6 rounded bg-gradient-to-br from-gray-700 to-gray-600 flex items-center justify-center">
                  <span className="text-xs font-bold text-white">{showOfferDetails.sellerName[0]}</span>
                </div>
                <span className="text-white font-medium">{showOfferDetails.sellerName}</span>
                <BadgeCheck className="text-[#02C076]" size={16} />
              </div>
            </div>
            <div>
              <div className="text-sm text-gray-400">Payment Method</div>
              <div className="flex items-center gap-2 mt-1">
                <div className="inline-flex items-center bg-gray-800 rounded px-2 py-1 border border-gray-700/50">
                  <div className="w-0.5 h-3 bg-[#02C076] rounded-full mr-1.5"></div>
                  <span className="text-white">{showOfferDetails.paymentMethod}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-3">
            <div>
              <div className="text-sm text-gray-400">Limits</div>
              <div className="text-white mt-1">
  {(showOfferDetails.paymentMethod || '').toLowerCase().includes('pesa') ? (
    <>
      <div>KES {(showOfferDetails.minLimit * 129).toLocaleString()} - {(showOfferDetails.maxLimit * 129).toLocaleString()}</div>
      <div className="text-sm text-gray-400">
        (${showOfferDetails.minLimit.toLocaleString()} - ${showOfferDetails.maxLimit.toLocaleString()} USD)
      </div>
    </>
  ) : (
    <div>${showOfferDetails.minLimit.toLocaleString()} - ${showOfferDetails.maxLimit.toLocaleString()} USD</div>
  )}
</div>
            </div>
            <div>
              <div className="text-sm text-gray-400">Response Time</div>
              <div className="flex items-center gap-2 mt-1 text-white">
                <Clock size={14} className="text-[#02C076]" />
                <span>Usually responds in {showOfferDetails.responseTime || 'minutes'}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Security Notice */}
      <div className="flex items-start gap-3 mb-6 p-3 bg-yellow-500/10 rounded-lg border border-yellow-500/20">
        <Shield className="text-yellow-500 flex-shrink-0" size={20} />
        <div>
          <h4 className="text-sm font-medium text-yellow-500">Secure Payment Process</h4>
          <p className="text-xs text-gray-400 mt-1">
            Your funds will be securely held in escrow until the trade is completed. Cancel anytime if seller is unresponsive.
          </p>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex flex-col-reverse sm:flex-row justify-end gap-2 mt-6">
        <button
          onClick={() => setShowOfferDetails(null)}
          className="px-4 py-2 text-sm border border-gray-700 rounded-lg hover:bg-gray-800 text-gray-300 transition-colors"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            navigate(`/trade/${showOfferDetails.id}`);
          }}
          className="flex items-center justify-center gap-2 px-6 py-2 text-sm bg-gradient-to-r from-[#02C076] to-[#02A566] hover:from-[#02A566] hover:to-[#02C076] text-white rounded-lg font-medium transition-all duration-300 group"
        >
          Proceed to Trade
          <ArrowRight size={16} className="group-hover:translate-x-0.5 transition-transform" />
        </button>
      </div>
    </div>
  </div>
)}
      </div>
    </div>
  );
};


export default P2PTrading;