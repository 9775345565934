import React, { useState, useEffect } from 'react';
import Header from './header';
import { 
  ExternalLink, 
  Clock, 
  Tag,
  Loader,
  ThumbsUp
} from 'lucide-react';

const CryptoBlog = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=10&page=1&sparkline=false', {
        headers: {
          'Accept': 'application/json',
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      const formattedNews = data.map(coin => ({
        id: coin.id,
        title: `${coin.name} (${coin.symbol.toUpperCase()}) Market Update`,
        description: `Current price: $${coin.current_price}. Market cap: $${coin.market_cap.toLocaleString()}. 24h change: ${coin.price_change_percentage_24h?.toFixed(2)}%`,
        created_at: new Date().toISOString(),
        thumb_2x: coin.image,
        url: `https://www.coingecko.com/en/coins/${coin.id}`,
        categories: 'Market Update',
        kind: coin.price_change_percentage_24h >= 0 ? 'Positive' : 'Negative',
        sentiment: coin.price_change_percentage_24h >= 0 ? 'positive' : 'negative'
      }));

      setNews(formattedNews);
    } catch (err) {
      setError('Failed to load crypto data. Please try again later.');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="pt-16 min-h-screen bg-black text-gray-100">
      <Header />
      
      <main className="max-w-5xl mx-auto px-4 py-6">
        <h1 className="text-lg font-medium mb-4">Crypto Market Updates</h1>

        {error && (
          <div className="bg-red-900/50 text-red-200 p-3 rounded-lg mb-4 text-xs">
            {error}
          </div>
        )}

        {loading ? (
          <div className="flex justify-center items-center h-48">
            <Loader className="w-6 h-6 animate-spin text-yellow-500" />
          </div>
        ) : (
          <div className="grid gap-4">
            {news.map((item) => (
              <article 
                key={item.id} 
                className="bg-gray-800 rounded-lg overflow-hidden shadow hover:shadow-lg transition-shadow"
              >
                <div className="p-4">
                  <div className="flex gap-3">
                    {item.thumb_2x && (
                      <img 
                        src={item.thumb_2x} 
                        alt={item.title}
                        className="w-12 h-12 object-contain rounded-lg"
                      />
                    )}
                    <div className="flex-1 min-w-0">
                      <div className="flex items-center gap-2 mb-1.5 text-xs text-gray-400">
                        <span>{new Date(item.created_at).toLocaleDateString()}</span>
                        <span>•</span>
                        <span className={`flex items-center gap-1 ${
                          item.sentiment === 'positive' ? 'text-green-400' : 'text-red-400'
                        }`}>
                          <ThumbsUp size={12} />
                          24h {item.kind}
                        </span>
                      </div>

                      <h2 className="text-sm font-medium mb-1.5 truncate">
                        {item.title}
                      </h2>

                      <p className="text-gray-400 text-xs mb-3">
                        {item.description}
                      </p>

                      <div className="flex items-center justify-between">
                        <div className="flex gap-2">
                          <span className="flex items-center text-xs bg-gray-700/50 px-2 py-0.5 rounded">
                            <Tag size={10} className="mr-1" />
                            {item.categories}
                          </span>
                        </div>

                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center gap-1 text-yellow-500 hover:text-yellow-400 text-xs"
                        >
                          View Details
                          <ExternalLink size={12} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            ))}
          </div>
        )}
      </main>
    </div>
  );
};

export default CryptoBlog;