import React from 'react';
import Header from './header'; 
import { Link } from 'react-router-dom';

import {
  Coins,
  Bell,
  Wallet,
  CheckCircle,
  Clock,
  DollarSign,
  Shield,
  UserCheck
} from 'lucide-react';

const CryptoEarningWebsite = () => {
  return (
    <div className="pt-16 min-h-screen bg-black text-gray-100">
      <Header/>
      {/* Navigation - Same as before */}
      

      {/* Main Content */}
      <div className="max-w-6xl mx-auto px-4 py-6">
        {/* Stats Overview */}
        <div className="mb-8">
          <h2 className="text-xl font-bold mb-4">Your Progress</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-gray-800 p-4 rounded-lg border border-gray-700">
              <div className="flex items-center justify-between mb-2">
                <span className="text-sm text-gray-400">Tasks Completed</span>
                <span className="text-lg font-bold">1/3</span>
              </div>
              <div className="w-full bg-gray-700 rounded-full h-1.5">
                <div className="bg-yellow-600 h-1.5 rounded-full" style={{width: '33%'}}></div>
              </div>
            </div>
            <div className="bg-gray-800 p-4 rounded-lg border border-gray-700">
              <div className="text-sm text-gray-400 mb-1">Potential Earnings</div>
              <div className="text-lg font-bold">35 USDT + $0 Fees</div>
            </div>
            <div className="bg-gray-800 p-4 rounded-lg border border-gray-700">
              <div className="text-sm text-gray-400 mb-1">Time Remaining</div>
              <div className="text-lg font-bold">29 Days</div>
            </div>
          </div>
        </div>

        {/* Tasks Section */}
        <div className="mb-8">
          <h2 className="text-xl font-bold mb-4">Earning Tasks</h2>
          <div className="space-y-3">
            {/* Task 1 - Signup */}
            <div className="bg-gray-800 rounded-lg p-4 border border-gray-700">
              <div className="flex items-start justify-between">
                <div className="flex-1">
                  <div className="flex items-center space-x-2 mb-1">
                    <UserCheck className="h-5 w-5 text-green-500" />
                    <h3 className="text-base font-semibold">Sign Up</h3>
                    <span className="bg-green-500/10 text-green-500 px-2 py-0.5 rounded-full text-xs">Completed</span>
                  </div>
                  <p className="text-sm text-gray-400 mb-2">Create your account and start earning</p>
                  <div className="text-sm text-yellow-600 font-medium">Reward: $0 fees for 1 month</div>
                </div>
                <CheckCircle className="h-6 w-6 text-green-500 flex-shrink-0" />
              </div>
            </div>

            {/* Task 2 - KYC */}
            <div className="bg-gray-800 rounded-lg p-4 border border-gray-700">
              <div className="flex items-start justify-between">
                <div className="flex-1">
                  <div className="flex items-center space-x-2 mb-1">
                    <Shield className="h-5 w-5 text-gray-400" />
                    <h3 className="text-base font-semibold">Complete KYC</h3>
                    <span className="bg-yellow-600/10 text-yellow-600 px-2 py-0.5 rounded-full text-xs">In Progress</span>
                  </div>
                  <p className="text-sm text-gray-400 mb-2">Verify your identity to unlock full platform features</p>
                  <div className="text-sm text-yellow-600 font-medium">Reward: 10 USDT</div>
                </div>
                <button className="bg-yellow-600 hover:bg-yellow-700 text-gray-900 px-4 py-1.5 text-sm rounded-lg font-medium">
                  Start KYC
                </button>
              </div>
            </div>

            {/* Task 3 - Deposit (Now Unlocked) */}
            <div className="bg-gray-800 rounded-lg p-4 border border-gray-700">
              <div className="flex items-start justify-between">
                <div className="flex-1">
                  <div className="flex items-center space-x-2 mb-1">
                    <DollarSign className="h-5 w-5 text-gray-400" />
                    <h3 className="text-base font-semibold">Make Your First Deposit</h3>
                    <span className="bg-yellow-600/10 text-yellow-600 px-2 py-0.5 rounded-full text-xs">Available</span>
                  </div>
                  <p className="text-sm text-gray-400 mb-2">Deposit $100 or more to earn BTC rewards</p>
                  <div className="text-sm text-yellow-600 font-medium">Reward: $25 in BTC</div>
                </div>
                <Link 
  to="/deposit" 
  className="bg-yellow-600 hover:bg-yellow-700 text-gray-900 px-4 py-1.5 text-sm rounded-lg font-medium"
>
  Deposit Now
</Link>
              </div>
            </div>
          </div>
        </div>

        {/* Time Remaining Notice */}
        <div className="bg-gray-800 p-4 rounded-lg border border-gray-700">
          <div className="flex items-center space-x-3">
            <Clock className="h-5 w-5 text-yellow-600" />
            <div>
              <h3 className="text-sm font-semibold">Limited Time Offer</h3>
              <p className="text-xs text-gray-400">Complete all tasks within 30 days to claim your rewards</p>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gray-800 border-t border-gray-700 py-6 mt-8">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="flex items-center space-x-2 mb-4 md:mb-0">
              <Coins className="h-5 w-5 text-yellow-600" />
              <span className="text-sm font-bold">Nexiacoin Earn</span>
            </div>
            <div className="text-xs text-gray-400">
              © 2024 NexiaCoin. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default CryptoEarningWebsite;