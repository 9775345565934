import React, { useState, useEffect, useRef } from 'react';
import { ResponsiveContainer, ComposedChart, Line, Bar, XAxis, YAxis, Tooltip } from 'recharts';
import { Search, ChevronDown, Clock, TrendingUp, ChevronRight, ArrowUpDown, AlertCircle, Menu } from 'lucide-react';
import TradingChart from './tradingchart'; // Adjust the path based on your file structure
const TradingPairs = () => {
  const [selectedPair, setSelectedPair] = useState({ base: 'BTC', quote: 'USDT', symbol: 'BTCUSDT' });
  const [klineData, setKlineData] = useState([]);
  const [marketInfo, setMarketInfo] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showPairSelector, setShowPairSelector] = useState(false);
  const [orderType, setOrderType] = useState('limit');
  const [orderSide, setOrderSide] = useState('buy');
  const [amount, setAmount] = useState('');
  const [price, setPrice] = useState('');
  const [timeRange, setTimeRange] = useState('1h');
  const [showSidebar, setShowSidebar] = useState(true);
  const ws = useRef(null);

  const timeRanges = [
    { label: '1m', value: '1m', interval: 60000 },
    { label: '5m', value: '5m', interval: 300000 },
    { label: '1h', value: '1h', interval: 3600000 },
    { label: '4h', value: '4h', interval: 14400000 },
    { label: '1d', value: '1d', interval: 86400000 },
  ];

  const popularPairs = [
    { base: 'BTC', quote: 'USDT', symbol: 'BTCUSDT' },
    { base: 'ETH', quote: 'USDT', symbol: 'ETHUSDT' },
    { base: 'BNB', quote: 'USDT', symbol: 'BNBUSDT' },
    { base: 'SOL', quote: 'USDT', symbol: 'SOLUSDT' },
    { base: 'XRP', quote: 'USDT', symbol: 'XRPUSDT' }
  ];

  // Function to fetch data based on time range
  const fetchTimeRangeData = async (symbol, range) => {
    try {
      const interval = timeRanges.find(t => t.value === range)?.value || '1h';
      const response = await fetch(
        `https://api.binance.com/api/v3/klines?symbol=${symbol}&interval=${interval}&limit=100`
      );
      const data = await response.json();
      return formatKlineData(data);
    } catch (error) {
      console.error('Error fetching time range data:', error);
      return [];
    }
  };

  const formatKlineData = (data) => {
    const formattedData = data.map(k => ({
      time: new Date(k[0]).toLocaleString(),
      open: parseFloat(k[1]),
      high: parseFloat(k[2]),
      low: parseFloat(k[3]),
      close: parseFloat(k[4]),
      volume: parseFloat(k[5]),
      color: parseFloat(k[4]) >= parseFloat(k[1]) ? '#26a69a' : '#ef5350'
    }));

    const ma7 = calculateMA(formattedData, 7);
    const ma25 = calculateMA(formattedData, 25);
    const ma99 = calculateMA(formattedData, 99);

    return formattedData.map((item, index) => ({
      ...item,
      MA7: ma7[index],
      MA25: ma25[index],
      MA99: ma99[index]
    }));
  };

  // Update data when time range changes
  useEffect(() => {
    fetchTimeRangeData(selectedPair.symbol, timeRange).then(data => {
      setKlineData(data);
    });
  }, [timeRange, selectedPair]);

  // Function to calculate Moving Averages
  const calculateMA = (data, period) => {
    const maData = [];
    for (let i = 0; i < data.length; i++) {
      if (i < period - 1) {
        maData.push(null);
        continue;
      }
      const slice = data.slice(i - period + 1, i + 1);
      const sum = slice.reduce((acc, val) => acc + parseFloat(val.close), 0);
      maData.push(sum / period);
    }
    return maData;
  };

  // Initial data fetch
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await fetch(
          `https://api.binance.com/api/v3/klines?symbol=${selectedPair.symbol}&interval=1h&limit=100`
        );
        const data = await response.json();
        
        const formattedData = data.map(k => ({
          time: new Date(k[0]).toLocaleDateString(),
          open: parseFloat(k[1]),
          high: parseFloat(k[2]),
          low: parseFloat(k[3]),
          close: parseFloat(k[4]),
          volume: parseFloat(k[5]),
          color: parseFloat(k[4]) >= parseFloat(k[1]) ? '#26a69a' : '#ef5350'
        }));

        // Calculate MAs
        const ma7 = calculateMA(formattedData, 7);
        const ma25 = calculateMA(formattedData, 25);
        const ma99 = calculateMA(formattedData, 99);

        const enrichedData = formattedData.map((item, index) => ({
          ...item,
          MA7: ma7[index],
          MA25: ma25[index],
          MA99: ma99[index]
        }));

        setKlineData(enrichedData);
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchInitialData();
  }, [selectedPair]);

  // WebSocket connection
  useEffect(() => {
    // Close existing connection
    if (ws.current) {
      ws.current.close();
    }

    // Create new WebSocket connection
    ws.current = new WebSocket(`wss://stream.binance.com:9443/ws/${selectedPair.symbol.toLowerCase()}@kline_1h`);

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.k) {
        const { t: time, o: open, h: high, l: low, c: close, v: volume } = data.k;

        setKlineData(prevData => {
          const newData = [...prevData];
          const lastCandle = {
            time: new Date(time).toLocaleDateString(),
            open: parseFloat(open),
            high: parseFloat(high),
            low: parseFloat(low),
            close: parseFloat(close),
            volume: parseFloat(volume),
            color: parseFloat(close) >= parseFloat(open) ? '#26a69a' : '#ef5350'
          };

          // Update last candle or add new one
          if (newData[newData.length - 1]?.time === lastCandle.time) {
            newData[newData.length - 1] = lastCandle;
          } else {
            newData.push(lastCandle);
            if (newData.length > 100) newData.shift();
          }

          // Recalculate MAs
          const ma7 = calculateMA(newData, 7);
          const ma25 = calculateMA(newData, 25);
          const ma99 = calculateMA(newData, 99);

          return newData.map((item, index) => ({
            ...item,
            MA7: ma7[index],
            MA25: ma25[index],
            MA99: ma99[index]
          }));
        });

        // Update market info
        setMarketInfo({
          price: parseFloat(close),
          change24h: ((parseFloat(close) - parseFloat(open)) / parseFloat(open) * 100).toFixed(2),
          volume: parseFloat(volume)
        });
      }
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [selectedPair]);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="bg-gray-800/90 p-2 rounded border border-gray-700 text-xs">
          <p className="text-gray-300">{data.time}</p>
          <div className="grid grid-cols-2 gap-2 mt-1">
            <p className="text-gray-300">O: <span className="text-gray-100">${data.open.toFixed(2)}</span></p>
            <p className="text-gray-300">C: <span className="text-gray-100">${data.close.toFixed(2)}</span></p>
            <p className="text-gray-300">H: <span className="text-gray-100">${data.high.toFixed(2)}</span></p>
            <p className="text-gray-300">L: <span className="text-gray-100">${data.low.toFixed(2)}</span></p>
          </div>
          <p className="mt-1 text-gray-300">Vol: <span className="text-gray-100">${data.volume.toLocaleString()}</span></p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="flex flex-col lg:flex-row bg-black p-2 md:p-4 min-h-screen">
      {/* Mobile menu button */}
      <button
        className="lg:hidden mb-2 p-2 text-gray-300 hover:bg-gray-800 rounded"
        onClick={() => setShowSidebar(!showSidebar)}
      >
        <Menu className="w-6 h-6" />
      </button>

      {/* Left sidebar - Market pairs */}
      <div className={`${
        showSidebar ? 'block' : 'hidden'
      } lg:block w-full lg:w-64 bg-black rounded-lg p-4 mb-4 lg:mb-0 lg:mr-4`}>
        <div className="relative">
          <Search className="absolute left-3 top-3 w-4 h-4 text-gray-400" />
          <input
            type="text"
            placeholder="Search markets"
            className="w-full pl-10 pr-4 py-2 bg-gray-700 rounded-lg text-sm text-gray-100"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        <div className="mt-4">
          <div className="flex items-center justify-between text-xs text-gray-300 px-2 py-1 bg-gray-750">
            <span>Pair</span>
            <span>Price</span>
            <span>24h %</span>
          </div>
          <div className="mt-2 space-y-1">
            {popularPairs.map(pair => (
              <button
                key={pair.symbol}
                className={`w-full flex items-center justify-between p-2 rounded hover:bg-gray-700 text-sm text-gray-100 ${
                  selectedPair.symbol === pair.symbol ? 'bg-gray-700' : ''
                }`}
                onClick={() => setSelectedPair(pair)}
              >
                <span className="font-medium">{pair.base}/{pair.quote}</span>
                <span>${marketInfo?.price.toFixed(2) || '0.00'}</span>
                <span className={marketInfo?.change24h >= 0 ? 'text-green-400' : 'text-red-400'}>
                  {marketInfo?.change24h}%
                </span>
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="flex-1 bg-black">
        {/* Top bar */}
        <div className="bg-black rounded-lg p-4 mb-4">
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <div className="flex items-center space-x-4 mb-4 md:mb-0">
              <div className="flex items-center space-x-2">
                <span className="text-lg font-bold text-gray-100">{selectedPair.base}/{selectedPair.quote}</span>
                <span className={`text-sm ${marketInfo?.change24h >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                  {marketInfo?.change24h}%
                </span>
              </div>
              <div className="text-2xl font-bold text-gray-100">${marketInfo?.price.toFixed(2) || '0.00'}</div>
            </div>
            <div className="grid grid-cols-3 gap-4 text-sm">
              <div>
                <div className="text-xs text-gray-400">24h High</div>
                <div className="text-gray-100">${(marketInfo?.price * 1.05).toFixed(2) || '0.00'}</div>
              </div>
              <div>
                <div className="text-xs text-gray-400">24h Low</div>
                <div className="text-gray-100">${(marketInfo?.price * 0.95).toFixed(2) || '0.00'}</div>
              </div>
              <div>
                <div className="text-xs text-gray-400">24h Volume</div>
                <div className="text-gray-100">${marketInfo?.volume.toLocaleString() || '0.00'}</div>
              </div>
            </div>
          </div>
        </div>

        {/* Chart and trading panel */}
        <div className="flex flex-col lg:flex-row gap-4">
          {/* Chart */}
          <div className="w-full lg:w-2/3 bg-gray-800 rounded-lg p-4">
  <div className="flex items-center space-x-2 mb-4 overflow-x-auto">
    {timeRanges.map(range => (
      <button
        key={range.value}
        onClick={() => setTimeRange(range.value)}
        className={`px-3 py-1 text-xs rounded whitespace-nowrap ${
          timeRange === range.value
            ? 'bg-indigo-600 text-gray-100'
            : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
        }`}
      >
        {range.label}
      </button>
    ))}
  </div>
  <div className="h-[400px] lg:h-[500px]">
    <TradingChart 
      timeRange={timeRange}
      symbol={selectedPair.symbol}
      initialData={klineData}
    />
  </div>
</div>

          {/* Trading panel */}
          <div className="w-full lg:w-1/3 bg-gray-800 rounded-lg p-4">
            {/* ... Keep existing trading panel code but update text colors ... */}
            <div className="flex border-b border-gray-700">
              <button
                className={`flex-1 pb-2 text-sm font-medium ${
                  orderSide === 'buy' ? 'text-green-400 border-b-2 border-green-400' : 'text-gray-400'
                }`}
                onClick={() => setOrderSide('buy')}
              >
                Buy {selectedPair.base}
              </button>
              <button
                className={`flex-1 pb-2 text-sm font-medium ${
                  orderSide === 'sell' ? 'text-red-400 border-b-2 border-red-400' : 'text-gray-400'
                }`}
                onClick={() => setOrderSide('sell')}
              >
                Sell {selectedPair.base}
              </button>
            </div>

            <div className="mt-4 space-y-4">
              <div className="flex space-x-2">
                <button
                  className={`px-3 py-1 text-xs rounded ${
                    orderType === 'limit' ? 'bg-indigo-600 text-gray-100' : 'bg-gray-700 text-gray-300'
                  }`}
                  onClick={() => setOrderType('limit')}
                >
                  Limit
                </button>
                <button
                  className={`px-3 py-1 text-xs rounded ${
                    orderType === 'market' ? 'bg-indigo-600 text-gray-100' : 'bg-gray-700 text-gray-300'
                  }`}
                  onClick={() => setOrderType('market')}
                >
                  Market
                </button>
                <button
                  className={`px-3 py-1 text-xs rounded ${
                    orderType === 'stop' ? 'bg-indigo-600 text-gray-100' : 'bg-gray-700 text-gray-300'
                  }`}
                  onClick={() => setOrderType('stop')}
                >
                  Stop-Limit
                </button>
              </div>

              {orderType !== 'market' && (
                <div className="space-y-2">
                  <label className="block text-xs text-gray-400">Price</label>
                  <div className="relative">
                    <input
                      type="text"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      className="w-full px-3 py-2 bg-gray-700 rounded text-sm text-gray-100"
                      placeholder="0.00"
                    />
                    <span className="absolute right-3 top-2 text-sm text-gray-400">{selectedPair.quote}</span>
                  </div>
                </div>
              )}

              <div className="space-y-2">
                <label className="block text-xs text-gray-400">Amount</label>
                <div className="relative">
                  <input
                    type="text"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="w-full px-3 py-2 bg-gray-700 rounded text-sm text-gray-100"
                    placeholder="0.00"
                  />
                  <span className="absolute right-3 top-2 text-sm text-gray-400">{selectedPair.base}</span>
                </div>
              </div>

              <div className="flex space-x-2 text-xs">
                <button className="px-2 py-1 bg-gray-700 text-gray-300 hover:bg-gray-600 rounded">25%</button>
                <button className="px-2 py-1 bg-gray-700 text-gray-300 hover:bg-gray-600 rounded">50%</button>
                <button className="px-2 py-1 bg-gray-700 text-gray-300 hover:bg-gray-600 rounded">75%</button>
                <button className="px-2 py-1 bg-gray-700 text-gray-300 hover:bg-gray-600 rounded">100%</button>
              </div>

              <div className="space-y-2 text-sm">
                <div className="flex justify-between text-gray-400">
                  <span>Available Balance</span>
                  <span>0.00 {selectedPair.quote}</span>
                </div>
                <div className="flex justify-between text-gray-400">
                  <span>Total</span>
                  <span>{(parseFloat(amount || 0) * parseFloat(price || 0)).toFixed(2)} {selectedPair.quote}</span>
                </div>
              </div>

              <button
                className={`w-full py-3 rounded-lg font-medium text-gray-100 ${
                  orderSide === 'buy'
                    ? 'bg-green-500 hover:bg-green-600'
                    : 'bg-red-500 hover:bg-red-600'
                }`}
              >
                {orderSide === 'buy' ? 'Buy' : 'Sell'} {selectedPair.base}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingPairs;