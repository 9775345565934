import React, { useState, useEffect, useRef } from 'react';
import { ref, push, onValue, serverTimestamp, set, get } from 'firebase/database';
import { auth, database } from './firebase';
import { MessageSquare, Send, ChevronDown, Info, Check, CheckCheck } from 'lucide-react';

const TradeChat = ({ offer, buyer, seller }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [showChat, setShowChat] = useState(false);
  const [chatInitialized, setChatInitialized] = useState(false);
  const [autoScroll, setAutoScroll] = useState(true);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const inputRef = useRef(null);

  const currentUser = auth.currentUser;
  const isUserBuyer = currentUser.uid === buyer?.id;
  const otherUser = isUserBuyer ? seller : buyer;
  
  const chatRoomId = offer.id && buyer?.id && seller?.id 
    ? `${offer.id}_${buyer.id}_${seller.id}`
    : null;

  useEffect(() => {
    if (showChat) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showChat]);

  useEffect(() => {
    // Prevent zoom on input focus for iOS
    const metaViewport = document.querySelector('meta[name=viewport]');
    const originalContent = metaViewport?.getAttribute('content');
    
    if (isInputFocused) {
      metaViewport?.setAttribute(
        'content',
        'width=device-width, initial-scale=1, maximum-scale=1'
      );
    } else {
      metaViewport?.setAttribute('content', originalContent || 'width=device-width, initial-scale=1');
    }

    return () => {
      metaViewport?.setAttribute('content', originalContent || 'width=device-width, initial-scale=1');
    };
  }, [isInputFocused]);

  useEffect(() => {
    if (!currentUser || !offer || !offer.id) return null;

    if (!chatRoomId || !offer || !buyer || !seller) {
      console.log('Missing required data:', { chatRoomId, offer, buyer, seller });
      return;
    }

    const initializeChat = async () => {
      const chatRef = ref(database, `chats/${chatRoomId}`);
      const chatSnapshot = await get(chatRef);

      if (!chatSnapshot.exists()) {
        await set(chatRef, {
          offerId: offer.id,
          participants: {
            buyerId: buyer.id,
            sellerId: seller.id,
            buyerName: buyer.displayName || 'Buyer',
            sellerName: seller.displayName || offer.sellerName
          },
          offerDetails: {
            asset: offer.asset,
            amount: offer.amount,
            paymentMethod: offer.paymentMethod
          },
          createdAt: serverTimestamp(),
          messages: {}
        });
      }
      setChatInitialized(true);
    };

    initializeChat();

    const messagesRef = ref(database, `chats/${chatRoomId}/messages`);
    const unsubscribe = onValue(messagesRef, (snapshot) => {
      if (snapshot.exists()) {
        const messagesData = snapshot.val();
        const messagesList = Object.entries(messagesData)
          .map(([id, data]) => ({
            id,
            ...data,
          }))
          .sort((a, b) => a.timestamp - b.timestamp);
        
        setMessages(messagesList);
        if (autoScroll) {
          scrollToBottom();
        }
      }
    });
    return () => unsubscribe();
  }, [chatRoomId, offer, buyer, seller, autoScroll]);

  const handleScroll = (e) => {
    e.stopPropagation();
    if (!chatContainerRef.current) return;
    
    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
    const isNearBottom = scrollHeight - scrollTop - clientHeight < 100;
    setAutoScroll(isNearBottom);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() || !chatInitialized) return;

    const messageData = {
      text: newMessage.trim(),
      senderId: currentUser.uid,
      senderName: currentUser.displayName || (isUserBuyer ? 'Buyer' : 'Seller'),
      receiverId: otherUser.id,
      receiverName: otherUser.displayName || (isUserBuyer ? offer.sellerName : 'Buyer'),
      timestamp: serverTimestamp(),
      status: 'sent'
    };

    try {
      const messagesRef = ref(database, `chats/${chatRoomId}/messages`);
      await push(messagesRef, messageData);
      setNewMessage('');
      setAutoScroll(true);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };
  
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  return (
    <>
      {showChat && (
        <div 
          className="fixed inset-0 bg-black/50 z-40"
          onClick={() => setShowChat(false)}
        />
      )}

      <div 
        className={`
          fixed bottom-0 right-0 md:right-4 
          w-full md:w-96 
          bg-gray-900 
          rounded-t-lg shadow-xl 
          border border-gray-800 
          max-w-full
          z-50
          ${isInputFocused ? 'h-auto' : ''}
          transition-all duration-200 ease-in-out
        `}
      >
        <div 
          className="flex items-center justify-between p-3 border-b border-gray-800 cursor-pointer bg-gray-800"
          onClick={() => !isInputFocused && setShowChat(!showChat)}
        >
          <div className="flex items-center gap-2 min-w-0">
            <MessageSquare className="w-5 h-5 text-yellow-500 flex-shrink-0" />
            <span className="font-medium text-white truncate text-sm">
              Chat with {isUserBuyer ? seller.displayName || offer.sellerName : buyer.displayName || 'Buyer'}
            </span>
          </div>
          <ChevronDown 
            className={`
              w-5 h-5 text-gray-400 
              transform transition-transform flex-shrink-0
              ${showChat ? 'rotate-180' : ''}
            `} 
          />
        </div>

        {showChat && (
          <>
            <div className="bg-gray-800/50 p-2 border-b border-gray-700">
              <div className="flex items-center gap-2">
                <Info className="w-4 h-4 text-yellow-500 flex-shrink-0" />
                <p className="text-xs text-gray-300 truncate">
                  Please keep your conversation within Nexiacoin platform
                </p>
              </div>
            </div>

            <div 
              ref={chatContainerRef}
              className={`
                overflow-y-auto 
                px-3 py-2 
                space-y-2
                ${isInputFocused ? 'h-[40vh]' : 'h-[50vh] md:h-96'}
                overscroll-contain
                will-change-scroll
                touch-pan-y
                scrollbar-thin
                scrollbar-thumb-gray-700
                scrollbar-track-transparent
              `}
              onScroll={handleScroll}
              onTouchStart={(e) => e.stopPropagation()}
              onTouchMove={(e) => e.stopPropagation()}
            >
              <div className="min-h-full">
                {messages.map((message) => (
                  <div
                    key={message.id}
                    className={`flex ${message.senderId === currentUser.uid ? 'justify-end' : 'justify-start'} mb-3`}
                  >
                    <div
                      className={`
                        max-w-[85%] 
                        rounded-lg 
                        p-2 
                        ${message.senderId === currentUser.uid
                          ? 'bg-yellow-500/20 text-white'
                          : 'bg-gray-800 text-gray-100'
                        }
                      `}
                    >
                      <p className="text-sm break-words leading-relaxed">{message.text}</p>
                      <div className="flex items-center justify-end gap-1 mt-1">
                        <span className="text-[10px] text-gray-400">
                          {formatTimestamp(message.timestamp)}
                        </span>
                        {message.senderId === currentUser.uid && (
                          message.status === 'read' 
                            ? <CheckCheck className="w-3 h-3 text-blue-400" />
                            : <Check className="w-3 h-3 text-gray-400" />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                <div ref={messagesEndRef} className="h-4" />
              </div>
            </div>

            <form 
              onSubmit={handleSendMessage} 
              className="sticky bottom-0 p-2 border-t border-gray-800 bg-gray-900"
            >
              <div className="flex items-center gap-2">
                <input
                  ref={inputRef}
                  type="text"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  placeholder="Type your message..."
                  className="
                    flex-1 
                    bg-gray-800 
                    border border-gray-700 
                    rounded-full 
                    px-4 py-2 
                    text-white 
                    placeholder-gray-500 
                    focus:outline-none 
                    focus:border-yellow-500 
                    text-base
                    min-w-0
                    appearance-none
                  "
                  style={{ fontSize: '16px' }}
                />
                <button
                  type="submit"
                  disabled={!newMessage.trim() || !chatInitialized}
                  className="
                    p-2
                    bg-yellow-500 
                    text-white 
                    rounded-full
                    hover:bg-yellow-600 
                    disabled:opacity-50 
                    disabled:cursor-not-allowed 
                    flex-shrink-0
                    transition-colors
                    shadow-lg
                  "
                >
                  <Send className="w-5 h-5" />
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </>
  );
};

export default TradeChat;