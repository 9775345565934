import React, { useState, useEffect } from 'react';
import Header from './header'; 

import { 
  Wallet, 
  ArrowRight, 
  PlusCircle, 
  AlertCircle, 
  ChevronRight, 
  Info,
  RefreshCw,
  Clock
} from 'lucide-react';

const WithdrawPage = () => {
  const [balance] = useState(0);
  const [amount, setAmount] = useState('');
  const [address, setAddress] = useState('');
  const [network, setNetwork] = useState('');
  const [error, setError] = useState('');
  const [networkFee, setNetworkFee] = useState('0.0001');
  const [processingTime, setProcessingTime] = useState('5-10 minutes');
  const [selectedSpeed, setSelectedSpeed] = useState('normal');

  const networks = [
    { value: 'btc', label: 'Bitcoin (BTC)', balance: '0.00000000', minWithdraw: '0.0001', maxWithdraw: '2.0000' },
    { value: 'eth', label: 'Ethereum (ETH)', balance: '0.00000000', minWithdraw: '0.01', maxWithdraw: '100.0000' },
    { value: 'usdt', label: 'USDT (TRC20)', balance: '0.00', minWithdraw: '20', maxWithdraw: '1000000' },
    { value: 'bnb', label: 'BNB Smart Chain (BEP20)', balance: '0.00000000', minWithdraw: '0.01', maxWithdraw: '1000.0000' }
  ];

  const speedOptions = {
    slow: { fee: networkFee, time: '15-20 minutes', multiplier: 0.8 },
    normal: { fee: networkFee, time: '5-10 minutes', multiplier: 1 },
    fast: { fee: networkFee, time: '2-5 minutes', multiplier: 1.2 }
  };

  useEffect(() => {
    setProcessingTime(speedOptions[selectedSpeed].time);
  }, [selectedSpeed]);

  const getSelectedNetwork = () => {
    return networks.find(net => net.value === network) || networks[0];
  };

  const validateWithdrawal = () => {
    setError('');
    
    if (!amount || !address || !network) {
      setError('Please fill in all fields');
      return false;
    }

    const withdrawAmount = parseFloat(amount);
    const currentNetwork = getSelectedNetwork();
    const minAmount = parseFloat(currentNetwork.minWithdraw);
    const maxAmount = parseFloat(currentNetwork.maxWithdraw);
    const totalWithFee = withdrawAmount + parseFloat(networkFee);

    if (balance <= 0) {
      setError('Your balance is zero. Please deposit funds first.');
      return false;
    }

    if (withdrawAmount < minAmount) {
      setError(`Minimum withdrawal amount is ${currentNetwork.minWithdraw} ${currentNetwork.value.toUpperCase()}`);
      return false;
    }

    if (withdrawAmount > maxAmount) {
      setError(`Maximum withdrawal amount is ${currentNetwork.maxWithdraw} ${currentNetwork.value.toUpperCase()}`);
      return false;
    }

    if (totalWithFee > balance) {
      setError('Insufficient balance (including network fee)');
      return false;
    }

    return true;
  };

  const handleWithdraw = (e) => {
    e.preventDefault();
    if (validateWithdrawal()) {
      console.log('Withdrawal initiated:', { 
        amount, 
        address, 
        network,
        networkFee,
        processingTime,
        totalAmount: parseFloat(amount) + parseFloat(networkFee)
      });
    }
  };

  const handleSpeedChange = (speed) => {
    setSelectedSpeed(speed);
    const baseFee = parseFloat(networkFee);
    const newFee = (baseFee * speedOptions[speed].multiplier).toFixed(8);
    setNetworkFee(newFee);
  };

  return (
    <div className="pt-16 min-h-screen bg-black text-white">
      <Header />
      <div className="border-b border-gray-800">
        <div className="max-w-6xl mx-auto px-4 py-6">
          <div className="flex items-center space-x-2 text-gray-400">
            <span>Assets</span>
            <ChevronRight className="h-4 w-4" />
            <span className="text-white">Withdraw</span>
          </div>
          <h1 className="text-2xl font-bold mt-4 text-white">Withdraw your assets </h1>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-6xl mx-auto px-4 py-8">
        {/* Asset Selection Grid */}
        <div className="mb-8">
          <h2 className="text-lg font-medium mb-4 text-gray-300">Select Asset</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {networks.map((net) => (
              <button
                key={net.value}
                onClick={() => setNetwork(net.value)}
                className={`p-4 rounded-lg border ${
                  network === net.value
                    ? 'border-yellow-600 bg-yellow-600/10'
                    : 'border-gray-800 hover:border-gray-700 bg-gray-800/50'
                } transition-all`}
              >
                <div className="flex items-center justify-between mb-2">
                  <span className="font-medium text-white">{net.label}</span>
                </div>
                <div className="text-sm text-gray-400">Available</div>
                <div className="text-lg font-medium text-white">{net.balance}</div>
                <div className="mt-2 text-xs text-gray-400">
                  Min: {net.minWithdraw} | Max: {net.maxWithdraw}
                </div>
              </button>
            ))}
          </div>
        </div>

        {/* Withdrawal Form */}
        <div className="flex flex-col md:flex-row md:space-x-8">
  {/* Withdrawal Form Column */}
  <div className="w-full md:w-1/2 max-w-2xl">
    <h2 className="text-lg font-medium mb-6 text-gray-300">Withdrawal Details</h2>
    <form onSubmit={handleWithdraw} className="space-y-6">
      <div>
        <label className="block text-sm font-medium mb-2 text-gray-300">Enter Recipient Address</label>
        <input
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="Enter wallet address"
          className="w-full bg-gray-800 border border-gray-700 rounded-lg p-3 text-white placeholder-gray-500 focus:ring-2 focus:ring-yellow-600 focus:border-transparent"
        />
      </div>

      <div>
        <label className="block text-sm font-medium mb-2 text-gray-300">Amount</label>
        <div className="relative">
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="0.00000000"
            step="0.00000001"
            className="w-full bg-gray-800 border border-gray-700 rounded-lg p-3 text-white placeholder-gray-500 focus:ring-2 focus:ring-yellow-600 focus:border-transparent"
          />
          <button
            type="button"
            onClick={() => setAmount(balance.toString())}
            className="absolute right-3 top-1/2 -translate-y-1/2 text-sm text-yellow-500 hover:text-yellow-400"
          >
            MAX
          </button>
        </div>
      </div>

      <div className="bg-gray-800/50 rounded-lg p-4 space-y-4">
        <div className="flex items-center justify-between">
          <span className="text-sm font-medium text-gray-300">Network Fee</span>
          <span className="text-sm text-white">{networkFee} {network.toUpperCase()}</span>
        </div>
        
        <div className="grid grid-cols-3 gap-3">
          {Object.entries(speedOptions).map(([speed, details]) => (
            <button
              key={speed}
              type="button"
              onClick={() => setSelectedSpeed(speed)}
              className={`p-3 rounded-lg text-center ${
                selectedSpeed === speed
                  ? 'bg-yellow-600/20 border border-yellow-600'
                  : 'bg-gray-800 border border-gray-700 hover:border-gray-600'
              }`}
            >
              <div className="text-sm font-medium text-white capitalize">{speed}</div>
              <div className="text-xs text-gray-400 mt-1">{details.time}</div>
            </button>
          ))}
        </div>
      </div>

      <div className="bg-gray-800/50 rounded-lg p-4 space-y-3">
        <h3 className="text-sm font-medium text-gray-300">Transaction Summary</h3>
        <div className="flex justify-between">
          <span className="text-sm text-gray-400">Amount</span>
          <span className="text-sm text-white">{amount} {network.toUpperCase()}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-sm text-gray-400">Network Fee</span>
          <span className="text-sm text-white">{networkFee} {network.toUpperCase()}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-sm text-gray-400">Total</span>
          <span className="text-sm font-semibold text-white">{(parseFloat(amount) + parseFloat(networkFee)).toFixed(8)} {network.toUpperCase()}</span>
        </div>
      </div>

      {error && (
        <div className="bg-red-600 text-white p-3 rounded-lg">
          <span className="inline-block mr-2">&#9888;</span>
          {error}
        </div>
      )}

      <button
        type="submit"
        className="w-full py-3 px-6 bg-yellow-600 hover:bg-yellow-500 text-white font-medium rounded-lg transition"
      >
        Withdraw
      </button>
    </form>
  </div>

  {/* Illustration / Information Column - Only on Desktop */}
  <div className="hidden md:block md:w-1/2 bg-gray-900 rounded-lg p-6">
    <h2 className="text-lg font-medium mb-4 text-gray-300">Important Information</h2>
    <ul className="space-y-4 text-gray-400">
      <li>Ensure the address you provide is correct. Incorrect addresses may result in permanent loss of funds.</li>
      <li>Withdrawal fees are calculated based on the network and may vary depending on current network traffic.</li>
      <li>Once a transaction is initiated, it cannot be reversed. Double-check all details before submitting.</li>
      <li>Transactions may take up to an hour to process depending on the selected network fee and network conditions.</li>
    </ul>
  </div>
</div>

      </div>
    </div>
  );
};

export default WithdrawPage;
