import React, { useState, useEffect, useRef } from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';

const TradingChart = ({ timeRange, symbol, initialData }) => {
  const [chartData, setChartData] = useState(() => 
    initialData.map(item => ({
      time: new Date(item.time || item[0]).toLocaleString(),
      price: parseFloat(item.close || item[4]),
      volume: parseFloat(item.volume || item[5])
    }))
  );
  const containerRef = useRef(null);

  // Rest of data fetching and WebSocket logic remains the same...
  useEffect(() => {
    const fetchHistoricalData = async () => {
      try {
        const response = await fetch(
          `https://api.binance.com/api/v3/klines?symbol=${symbol}&interval=${timeRange}&limit=100`
        );
        const data = await response.json();
        setChartData(data.map(item => ({
          time: new Date(item[0]).toLocaleString(),
          price: parseFloat(item[4]),
          volume: parseFloat(item[5])
        })));
      } catch (error) {
        console.error('Error fetching historical data:', error);
      }
    };

    fetchHistoricalData();
  }, [symbol, timeRange]);

  useEffect(() => {
    const ws = new WebSocket(`wss://stream.binance.com:9443/ws/${symbol.toLowerCase()}@kline_${timeRange}`);
    
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.k) {
        const { t: time, c: close, v: volume } = data.k;
        
        setChartData(prevData => {
          const newPoint = {
            time: new Date(time).toLocaleString(),
            price: parseFloat(close),
            volume: parseFloat(volume)
          };

          const updated = [...prevData];
          if (updated.length > 0 && updated[updated.length - 1].time === newPoint.time) {
            updated[updated.length - 1] = newPoint;
          } else {
            if (updated.length >= 100) updated.shift();
            updated.push(newPoint);
          }
          
          return updated;
        });
      }
    };

    return () => ws.close();
  }, [symbol, timeRange]);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload?.[0]) {
      const data = payload[0].payload;
      return (
        <div className="backdrop-blur-md bg-gray-800/80 p-3 rounded-lg border border-gray-700/50 shadow-xl">
          <div className="text-gray-300 text-xs mb-2">{data.time}</div>
          <div className="flex items-center gap-2">
            <span className="text-gray-400">Price:</span>
            <span className="text-gray-100 font-medium">${data.price?.toFixed(2)}</span>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div 
      ref={containerRef} 
      className="w-full h-full relative bg-black"
      style={{ minHeight: '300px', maxHeight: '500px' }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <LineChart 
          data={chartData}
          margin={{ top: 5, right: 30, left: 10, bottom: 5 }}
        >
          <defs>
            <linearGradient id="lineGradient" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor="#3B82F6" />
              <stop offset="100%" stopColor="#60A5FA" />
            </linearGradient>
          </defs>

          <XAxis 
            dataKey="time"
            stroke="#9CA3AF"
            tickLine={false}
            minTickGap={50}
            tick={{ fill: '#9CA3AF', fontSize: 11 }}
            height={20}
            padding={{ left: 10, right: 10 }}
          />
          
          <YAxis
            dataKey="price"
            orientation="right"
            stroke="#9CA3AF"
            tickLine={false}
            tickFormatter={(value) => `$${value.toLocaleString()}`}
            tick={{ fill: '#9CA3AF', fontSize: 11 }}
            width={60}
            domain={['auto', 'auto']}
            padding={{ top: 10, bottom: 10 }}
          />
          
          <Tooltip 
            content={<CustomTooltip />}
            cursor={{
              stroke: '#6B7280',
              strokeDasharray: '3 3'
            }}
          />

          <Line
            type="basis"
            dataKey="price"
            stroke="url(#lineGradient)"
            strokeWidth={2}
            dot={false}
            activeDot={{
              r: 4,
              stroke: '#60A5FA',
              strokeWidth: 2,
              fill: '#fff'
            }}
            connectNulls={true}
            isAnimationActive={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TradingChart;