import React, { useState, useEffect } from 'react';
import { ref, onValue, get, update } from 'firebase/database';
import { MessageSquare, User, ChevronDown, ChevronUp, Trash2, AlertCircle } from 'lucide-react';
import { auth, database } from './firebase';
import TradeChat from './chat';


const ChatList = () => {
  const [activeChats, setActiveChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showChats, setShowChats] = useState(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const markMessagesAsRead = async (chatId) => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    const chatRef = ref(database, `chats/${chatId}/messages`);
    const snapshot = await get(chatRef);

    if (snapshot.exists()) {
      const messages = snapshot.val();
      const updates = {};

      // Update all unread messages where the current user is the receiver
      Object.entries(messages).forEach(([messageId, message]) => {
        if (message.receiverId === currentUser.uid && message.status === 'sent') {
          updates[`chats/${chatId}/messages/${messageId}/status`] = 'read';
        }
      });

      // Apply updates if there are any
      if (Object.keys(updates).length > 0) {
        await update(ref(database), updates);
      }
    }
  };
  const handleChatSelect = (chat) => {
    setSelectedChat(chat);
    markMessagesAsRead(chat.chatId);
  };

  const handleDeleteAllChats = async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) return;

      // Get all chats where the user is a participant
      const userChatIds = activeChats.map(chat => chat.chatId);
      
      // Delete each chat
      const updates = {};
      userChatIds.forEach(chatId => {
        updates[`chats/${chatId}`] = null;
      });

      // Apply all deletions in one update
      if (Object.keys(updates).length > 0) {
        await update(ref(database), updates);
        setActiveChats([]);
        setSelectedChat(null);
        setShowDeleteConfirmation(false);
        alert('All chats deleted successfully');
      }
    } catch (error) {
      console.error('Error deleting chats:', error);
      alert('Failed to delete chats. Please try again.');
    }
  };

  // Add Delete Confirmation Modal
  const DeleteConfirmationModal = ({ onConfirm, onClose }) => (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-gray-900 rounded-lg p-6 max-w-md w-full border border-gray-800">
        <div className="flex items-center gap-3 text-red-500 mb-4">
          <AlertCircle className="w-6 h-6" />
          <h3 className="text-lg font-bold">Delete All Chats</h3>
        </div>

        <p className="text-gray-300 mb-6">
          Are you sure you want to delete all your chats? This action cannot be undone and will remove all your chat history.
        </p>

        <div className="flex gap-3">
          <button
            onClick={onClose}
            className="flex-1 px-4 py-2 border border-gray-700 text-white rounded-md hover:bg-gray-800"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="flex-1 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
          >
            Delete All
          </button>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    const chatsRef = ref(database, 'chats');
    const unsubscribe = onValue(chatsRef, async (snapshot) => {
      if (snapshot.exists()) {
        const chatsData = snapshot.val();
        const relevantChats = [];

        for (const [chatRoomId, chat] of Object.entries(chatsData)) {
          // Check if current user is a participant in this chat
          const isParticipant = 
            chat.participants?.buyerId === currentUser.uid || 
            chat.participants?.sellerId === currentUser.uid;

          if (isParticipant) {
            // Parse the chatRoomId to get offerId (format: offerId_buyerId_sellerId)
            const [offerId] = chatRoomId.split('_');
            
            try {
              // Get offer details
              const offerRef = ref(database, `offers/${offerId}`);
              const offerSnapshot = await get(offerRef);
              const offerData = offerSnapshot.val();

              if (offerData) {
                // Get messages where user is either sender or receiver
                const messages = chat.messages ? Object.values(chat.messages).filter(msg => 
                  msg.senderId === currentUser.uid || msg.receiverId === currentUser.uid
                ) : [];

                const latestMessage = messages.length > 0 
                  ? messages.sort((a, b) => b.timestamp - a.timestamp)[0]
                  : null;

                // Only add to relevantChats if there are messages
                if (messages.length > 0) {
                  relevantChats.push({
                    chatId: chatRoomId,
                    offer: { ...offerData, id: offerId },
                    participants: chat.participants,
                    latestMessage,
                    isUserSeller: chat.participants.sellerId === currentUser.uid,
                    unreadCount: messages.filter(msg => 
                      msg.status === 'sent' && 
                      msg.receiverId === currentUser.uid
                    ).length
                  });
                }
              }
            } catch (error) {
              console.error('Error fetching offer details:', error);
            }
          }
        }

        // Sort by latest message timestamp
        setActiveChats(relevantChats.sort((a, b) => 
          (b.latestMessage?.timestamp || 0) - (a.latestMessage?.timestamp || 0)
        ));
        setLoading(false);
      } else {
        setActiveChats([]);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  if (loading) {
    return <div className="text-gray-400">Loading chats...</div>;
  }

  return (
    <div className="mb-8 bg-black">
    {/* Header Section */}
    <div className="flex flex-col md:flex-row md:items-center justify-between gap-2 md:gap-4 mb-4">
      <button
        onClick={() => setShowChats(!showChats)}
        className="flex items-center justify-between hover:bg-gray-800/30 transition-colors duration-200 rounded-md py-1.5 px-2"
      >
        <div className="flex items-center gap-1.5">
          <MessageSquare className="w-5 h-5 text-yellow-500" />
          <h2 className="text-lg font-semibold text-white">
            Chats with P2P
            {activeChats.length > 0 && (
              <span className="text-yellow-500 text-sm ml-2">
                ({activeChats.length})
              </span>
            )}
          </h2>
        </div>
        <div className="flex items-center gap-2 ml-4">
            <span className="text-sm text-gray-400">
              {showChats ? 'Hide' : 'Show'}
            </span>
            {showChats ? (
              <ChevronUp className="w-4 h-4 text-gray-400" />
            ) : (
              <ChevronDown className="w-4 h-4 text-gray-400" />
            )}
          </div>
      </button>

      {activeChats.length > 0 && (
        <button
          onClick={() => setShowDeleteConfirmation(true)}
          className="w-full md:w-auto px-4 py-2 md:py-1.5 text-red-500 hover:bg-red-500/10 
                   rounded-md transition-colors flex items-center justify-center gap-2 
                   border border-red-500/20 hover:border-red-500/30"
        >
          <Trash2 className="w-4 h-4" />
          <span className="text-sm font-medium">Delete All Chats</span>
        </button>
      )}
    </div>

    {/* Chat List Section */}
    {showChats && (
      <div className="space-y-2 animate-fadeIn">
        {activeChats.length > 0 ? (
          <div className="space-y-2">
            {activeChats.map((chat) => (
              <div 
                key={chat.chatId}
                className="bg-gray-800/50 hover:bg-gray-800 rounded-lg p-4 cursor-pointer"
                onClick={() => handleChatSelect(chat)}
              >
                <div className="flex justify-between items-start">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center space-x-2">
                      <User className="w-4 h-4 text-gray-400 flex-shrink-0" />
                      <span className="text-white font-medium truncate">
                        {chat.isUserSeller ? chat.participants.buyerName : chat.participants.sellerName}
                      </span>
                    </div>
                    
                    <div className="mt-1 text-sm text-gray-400 truncate">
                      You opened a trade for {chat.offer.asset}, paid through {chat.offer.paymentMethod}
                    </div>
                    
                    {chat.latestMessage && (
                      <div className="mt-2">
                        <div className="text-sm text-gray-300 line-clamp-2">
                          {chat.latestMessage.text}
                        </div>
                        <div className="text-xs text-gray-500 mt-1">
                          {formatTimestamp(chat.latestMessage.timestamp)}
                        </div>
                      </div>
                    )}
                  </div>

                  {chat.unreadCount > 0 && (
                    <div className="ml-3 flex-shrink-0 bg-yellow-500 text-black px-2 py-1 rounded-full text-xs font-medium">
                      {chat.unreadCount}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="bg-gray-800/50 rounded-lg p-6 text-center">
            <div className="text-gray-400 mb-2">
              No active chats
            </div>
          </div>
        )}
      </div>
    )}

    {/* TradeChat Component */}
    {selectedChat && (
      <div className="mt-4">
        <TradeChat 
          offer={selectedChat.offer}
          buyer={{
            id: selectedChat.participants.buyerId,
            displayName: selectedChat.participants.buyerName
          }}
          seller={{
            id: selectedChat.participants.sellerId,
            displayName: selectedChat.participants.sellerName
          }}
        />
      </div>
    )}

    {/* Delete Confirmation Modal */}
    {showDeleteConfirmation && (
      <DeleteConfirmationModal
        onConfirm={handleDeleteAllChats}
        onClose={() => setShowDeleteConfirmation(false)}
      />
    )}
  </div>
);
};

export default ChatList;