import React, { useState, useEffect } from 'react';
import {
  Wallet,
  Menu,
  X,
  ChevronDown,
  BadgeDollarSign,
  TrendingUp,
  BookOpen,
  Cpu,
  Gem,
  Globe,
  Clock,
  Activity,
  Bell,
  Layout,
  LineChart,
  MoreHorizontal,
  ArrowRightLeft,
  Zap,
  Mail, 
  GanttChart,
  Shield,
  ExternalLink,
} from 'lucide-react';
import axios from 'axios';
import NexiaLogo from './assets/images/nexiawhite.png';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuAnimating, setIsMenuAnimating] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [currentTime, setCurrentTime] = useState('');
  const [activeMarket, setActiveMarket] = useState('USD');
  const [showNotification, setShowNotification] = useState(false);
  const [openMenus, setOpenMenus] = useState({});
  const [isScrollingUp, setIsScrollingUp] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const currentYear = new Date().getFullYear();
  const toggleDropdown = (menuName) => {
    setOpenMenus(prev => {
      // First, get the current state of the specific menu
      const isCurrentlyOpen = prev[menuName];
      
      // Close all other menus and toggle the clicked one
      return {
        ...Object.keys(prev).reduce((acc, key) => ({
          ...acc,
          [key]: false
        }), {}),
        [menuName]: !isCurrentlyOpen
      };
    });
  };
  const toggleBodyScroll = (disable) => {
    if (disable) {
      // Save the current scroll position
      const scrollY = window.scrollY;
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = '100%';
    } else {
      // Restore the scroll position
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  };
  const handleMenuToggle = () => {
    if (isMenuOpen) {
      setIsMenuAnimating(false);
      setTimeout(() => {
        setIsMenuOpen(false);
        toggleBodyScroll(false); // Re-enable body scroll
      }, 300);
    } else {
      setIsMenuOpen(true);
      toggleBodyScroll(true); // Disable body scroll
      setTimeout(() => {
        setIsMenuAnimating(true);
      }, 50);
    }
  };
  useEffect(() => {
    return () => {
      // Ensure body scroll is re-enabled when component unmounts
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
    };
  }, []);
  const [marketData, setMarketData] = useState({
    price: 0,
    change24h: 0,
    volume24h: '0',
    marketCap: '0',
    positive: true,
    sparklineData: Array(20).fill(0) // Initialize with 20 zero points
  });
  const formatNumber = (num) => {
    if (num >= 1e9) {
      return `${(num / 1e9).toFixed(1)}B`;
    } else if (num >= 1e6) {
      return `${(num / 1e6).toFixed(1)}M`;
    } else if (num >= 1e3) {
      return `${(num / 1e3).toFixed(1)}K`;
    }
    return num.toFixed(2);
  };
  const fetchMarketData = async () => {
    try {
      // Get 24hr ticker data for BTCUSDT
      const [tickerResponse, klineResponse] = await Promise.all([
        axios.get('https://api.binance.com/api/v3/ticker/24hr', {
          params: { symbol: 'BTCUSDT' }
        }),
        // Get kline (candlestick) data for sparkline
        // 1h interval, last 20 data points
        axios.get('https://api.binance.com/api/v3/klines', {
          params: {
            symbol: 'BTCUSDT',
            interval: '1h',
            limit: 20
          }
        })
      ]);
  
      const ticker = tickerResponse.data;
      const klines = klineResponse.data;
  
      // Extract closing prices from klines for sparkline data
      // Each kline array element has structure: [openTime, open, high, low, close, volume, ...]
      const sparklinePrices = klines.map(kline => parseFloat(kline[4]));
  
      setMarketData({
        price: parseFloat(ticker.lastPrice),
        change24h: parseFloat(ticker.priceChangePercent),
        volume24h: formatNumber(parseFloat(ticker.volume)),
        marketCap: formatNumber(parseFloat(ticker.quoteVolume)), // Note: This is actually trading volume in quote asset (USDT)
        positive: parseFloat(ticker.priceChangePercent) >= 0,
        sparklineData: sparklinePrices
      });
    } catch (error) {
      console.error('Error fetching market data:', error);
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      // Only update scroll-related states if mobile menu is closed
      if (!isMenuOpen) {
        setIsScrollingUp(currentScrollY < lastScrollY);
        setLastScrollY(currentScrollY);
        setScrolled(currentScrollY > 20);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY, isMenuOpen]);


  useEffect(() => {
    fetchMarketData();

    // Fetch new data every 30 seconds
    const interval = setInterval(fetchMarketData, 30000);

    return () => clearInterval(interval);
  }, []);

  // Handle scroll effect with threshold
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Update time
  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      setCurrentTime(now.toLocaleTimeString());
    };
    updateTime();
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, []);

  // Simulate price updates

  const navItems = [
    {
      name: 'Dashboard',
      icon: Layout,
      submenu: [
        { name: 'Home', link: '/homepage' },
        { name: 'Wallet', link: '/wallet' },
        { name: 'Withdraw', link: '/withdraw' },
        { name: 'Deposit', link: '/deposit' },
        { name: 'P2P', link: '/p2p' },
        { name: 'Convert', link: '/convert' },
      ]
    },
    {
      name: 'Tools',
      icon: LineChart,
      submenu: [
        { name: 'Copy Trading', link: '/copytrading' },
        { name: 'Loans', link: '/ordersloans' },
        { name: 'Lending/Borrowing', link: '/ordersloans' },
        { name: 'Earn', link: '/earncrypto' },
      ]
    },
    {
      name: 'Explore',
      icon: ArrowRightLeft,
      submenu: [
        { name: 'Blog', link: '/blog' },
        { name: 'Community Forum', link: '/community' },
        { name: 'Event Calendar', link: '/events' }
      ]
    },
    {
      name: 'More',
      icon: MoreHorizontal,
      submenu: [
        { name: 'Transactions', link: '/transactions' },
        { name: 'Orders', link: '/orders' },
        { name: 'Help & Support', link: '/helpcenter' },
        { name: 'Security', link: '/settings' },
        { name: 'Sign out', link: '/login' }
      ]
    }
  ];
  const normalizeData = (data) => {
    if (!data || data.length === 0) return [];
    // Get the last 20 data points for a smoother look
    const priceData = data.slice(-20);
    const min = Math.min(...priceData);
    const max = Math.max(...priceData);
    const range = max - min;
    return priceData.map(price => ({
      value: range === 0 ? 50 : ((price - min) / range) * 100,
      // Track if price went up or down compared to previous point
      isUp: price > min
    }));
  };

  const MiniChart = ({ data }) => {
    // Add check for empty or invalid data
    if (!data || data.length === 0) {
      return <div className="relative h-5 w-14" />; // Return empty container while loading
    }

    const normalizedData = normalizeData(data);

    return (
      <div className="relative h-5 w-14">
        <svg width="100%" height="100%" viewBox="0 0 56 20" preserveAspectRatio="none">
          {/* Only render if we have normalized data */}
          {normalizedData.length > 0 && (
            <>
              <polyline
                points={normalizedData.map((point, index) =>
                  `${(index * (56 / (normalizedData.length - 1)))},${20 - (point.value * 20 / 100)}`
                ).join(' ')}
                fill="none"
                stroke={marketData.positive ? "rgb(34, 197, 94)" : "rgb(239, 68, 68)"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="opacity-80"
              />

              <linearGradient id="chartGradient" x1="0" x2="0" y1="0" y2="1">
                <stop offset="0%" stopColor={marketData.positive ? "rgb(34, 197, 94)" : "rgb(239, 68, 68)"} stopOpacity="0.1" />
                <stop offset="100%" stopColor={marketData.positive ? "rgb(34, 197, 94)" : "rgb(239, 68, 68)"} stopOpacity="0" />
              </linearGradient>
              <path
                d={`
                  M 0,${20 - (normalizedData[0]?.value || 0) * 20 / 100}
                  ${normalizedData.map((point, index) =>
                  `L ${(index * (56 / (normalizedData.length - 1)))},${20 - (point.value * 20 / 100)}`
                ).join(' ')}
                  L ${56},20 L 0,20 Z
                `}
                fill="url(#chartGradient)"
              />
            </>
          )}
        </svg>
      </div>
    );
  };

  return (
    <>
      <header className={`fixed top-0 w-full z-50 transition-all duration-500 ${scrolled ? 'bg-gray-black backdrop-blur-md shadow-xl' : 'bg-black'
        }`}>
        {/* Top bar with market stats */}
        <div className="hidden lg:block bg-gray-800/50 border-b border-gray-700/50">
          <div className="container mx-auto px-4">
            <div className="flex items-center justify-between h-8 text-sm">
              <div className="flex items-center space-x-4">
                <div className="flex items-center text-gray-400">
                  <Clock size={12} className="mr-1" />
                  <span>{currentTime}</span>
                </div>
                <div className="flex items-center text-gray-400">
                  <Globe size={12} className="mr-1" />
                  <span>Network Status: </span>
                  <span className="ml-1 text-green-400">Active</span>
                </div>
              </div>
              <div className="flex items-center space-x-6">
                <span className="text-gray-400">24h Volume: <span className="text-amber-400">${marketData.volume24h}</span></span>
                <span className="text-gray-400">Market Cap: <span className="text-amber-400">${marketData.marketCap}</span></span>
              </div>
            </div>
          </div>
        </div>

        {/* Main header content */}
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            {/* Logo and Price Section */}
            <div className="flex items-center space-x-6">
              <a href="/homepage" className="flex items-center space-x-2 group">
                <div className="relative">
                  <img
                    src={NexiaLogo}
                    alt="Nexiacoin Logo"
                    className="w-8 h-8 transform transition-transform group-hover:rotate-12"
                  />
                  <div className="absolute -top-1 -right-1 w-2 h-2 bg-green-400 rounded-full animate-pulse" />
                </div>
                <span className="text-xl font-bold text-white">Nexiacoin</span>
              </a>

              {/* Advanced Price Ticker */}
              <div className="hidden lg:flex items-center space-x-2">
                <div className="group relative">
                  <div className="flex items-center space-x-3 py-1.5 px-4 bg-gray-800/50 rounded-lg hover:bg-gray-800 transition-colors cursor-pointer">
                    <div>
                      <div className="flex items-center">
                        <BadgeDollarSign size={14} className="text-amber-400 mr-1" />
                        <span className="text-white font-medium">${marketData.price}</span>
                      </div>
                      <div className={`text-xs ${marketData.positive ? 'text-green-400' : 'text-red-400'}`}>
                        {marketData.positive ? '↑' : '↓'} {marketData.change24h}%
                      </div>
                    </div>
                    <MiniChart data={marketData.sparklineData} />
                  </div>

                  {/* Hover tooltip */}
                  <div className="absolute top-full left-0 mt-2 w-64 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-300">
                    <div className="bg-gray-800 rounded-lg shadow-xl border border-gray-700 p-4">
                      <div className="space-y-2">
                        <div className="flex justify-between">
                          <span className="text-gray-400">24h High</span>
                          <span className="text-white">$0.0245</span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-gray-400">24h Low</span>
                          <span className="text-white">$0.0218</span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-gray-400">Market Rank</span>
                          <span className="text-white">#142</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Desktop Navigation */}
            <nav className="hidden lg:flex items-center space-x-1">
              {navItems.map((item) => (
                <div key={item.name} className="relative group px-2">
                  <button className={`flex items-center space-x-2 px-3 py-2 rounded-lg text-gray-300 hover:text-amber-400 hover:bg-gray-800/50 transition-all ${item.highlight ? 'text-amber-400' : ''
                    }`}>
                    <item.icon size={16} />
                    <span>{item.name}</span>
                    <ChevronDown size={14} className="transform group-hover:rotate-180 transition-transform" />
                  </button>

                  {/* Mega menu dropdown */}
                  <div className="absolute top-full left-0 transform -translate-x-1/4 mt-2 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-300">
                    <div className="w-56 bg-gray-800 rounded-lg shadow-xl border border-gray-700 p-2">
                      {item.submenu.map((subItem) => (
                        <a
                          key={subItem.name}
                          href={subItem.link}
                          className="flex items-center space-x-2 px-4 py-2 text-gray-300 hover:bg-gray-700 hover:text-amber-400 rounded-lg transition-colors group"
                        >
                          <span>{subItem.name}</span>
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </nav>


            {/* Action Buttons */}
            <div className="hidden lg:flex items-center space-x-2">
              {/* Notification Bell */}
              <button
                onClick={() => setShowNotification(!showNotification)}
                className="relative p-2 text-gray-400 hover:text-amber-400 transition-colors rounded-lg hover:bg-gray-800/50"
              >
                <Bell size={18} />
                <div className="absolute top-0 right-0 w-2 h-2 bg-amber-400 rounded-full" />
              </button>

              {/* Connect Wallet Button */}
              <button className="flex items-center space-x-2 bg-gradient-to-r from-amber-400 to-amber-500 hover:from-amber-500 hover:to-amber-600 text-gray-900 px-4 py-2 rounded-lg transition-all duration-300 font-medium shadow-lg hover:shadow-amber-400/20">
                <Wallet size={16} />
                <span>Wallet Connected</span>
                <Zap size={16} className="animate-pulse" />
              </button>
            </div>

            {/* Mobile Menu Button */}
            <button
              onClick={handleMenuToggle}
              className="lg:hidden relative p-2 text-gray-300 hover:text-amber-400 transition-colors rounded-lg hover:bg-gray-800/50"
            >
              {isMenuOpen ? (
                <X size={24} className="transition-transform duration-300 rotate-0 hover:rotate-90" />
              ) : (
                <Menu size={24} />
              )}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`lg:hidden fixed inset-0 z-50 transition-all duration-300 ${
            isMenuOpen ? 'visible' : 'invisible'
          }`}
          style={{
            backgroundColor: isMenuAnimating ? 'rgba(17, 24, 39, 0.95)' : 'rgba(17, 24, 39, 0)',
            transition: 'background-color 0.3s ease-in-out'
          }}
        >
          {/* Mobile Menu Content */}
          <div
            className={`fixed right-0 top-0 h-full w-full max-w-sm bg-gray-900 shadow-xl transform transition-all duration-300 ease-in-out overflow-y-auto ${
              isMenuAnimating ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'
            }`}
          >
            <div className="relative h-full">
              {/* Close button */}
              <button
                onClick={handleMenuToggle}
                className="absolute right-4 top-4 p-2 text-gray-300 hover:text-amber-400 transition-all duration-300 hover:rotate-90 transform"
              >
                <X size={24} />
              </button>

              {/* Scrollable content */}
              <div className="h-full overflow-y-auto py-16 px-4">
                <div className="space-y-4">
                  {/* Animated entrance for each menu item */}
                  <div className={`transform transition-all duration-500 ${isMenuAnimating ? 'translate-x-0 opacity-100' : 'translate-x-8 opacity-0'
                    }`}>
                    {/* Mobile Price Ticker */}
                    <div className="flex items-center justify-between p-3 bg-gray-800 rounded-lg mb-6">
                      <div className="flex items-center space-x-2">
                        <BadgeDollarSign size={18} className="text-amber-400" />
                        <div>
                          <div className="text-white font-medium">${marketData.price}</div>
                          <div className={`text-xs ${marketData.positive ? 'text-green-400' : 'text-red-400'}`}>
                            {marketData.positive ? '↑' : '↓'} {marketData.change24h}%
                          </div>
                        </div>
                      </div>
                      <MiniChart data={marketData.sparklineData} />
                    </div>

                    {/* Mobile Navigation with staggered animation */}
                    {navItems.map((item, index) => (
                      <div
                        key={item.name}
                        className="space-y-2 transform transition-all duration-300"
                        style={{
                          transitionDelay: `${150 + index * 50}ms`,
                          opacity: isMenuAnimating ? 1 : 0,
                          transform: isMenuAnimating ? 'translateX(0)' : 'translateX(2rem)'
                        }}
                      >
                        <button
                          onClick={() => toggleDropdown(item.name)}
                          className="w-full flex items-center justify-between text-gray-300 px-2 py-2 rounded-lg hover:bg-gray-800/50 transition-colors"
                        >
                          <div className="flex items-center space-x-2">
                            <item.icon size={16} className="transition-colors duration-300" />
                            <span className="font-medium">{item.name}</span>
                          </div>
                          <ChevronDown
                            size={14}
                            className={`transform transition-transform duration-300 ${openMenus[item.name] ? 'rotate-180' : ''
                              }`}
                          />
                        </button>
                        <div className={`pl-4 space-y-1 transition-all duration-300 ease-in-out ${openMenus[item.name] ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
                          }`}>
                          {item.submenu.map((subItem, subIndex) => (
                            <a
                              key={subItem.name}
                              href={subItem.link}
                              className="flex items-center space-x-2 px-4 py-2 text-sm text-gray-400 hover:text-amber-400 rounded-lg transition-all duration-300"
                              style={{
                                transitionDelay: openMenus[item.name] ? `${subIndex * 50}ms` : '0ms'
                              }}
                            >
                              <span>{subItem.name}</span>
                            </a>
                          ))}
                        </div>
                      </div>
                    ))}

                    {/* Mobile Connect Wallet Button */}
                    <div
                      className="transform transition-all duration-500"
                      style={{
                        transitionDelay: `${150 + navItems.length * 50}ms`,
                        opacity: isMenuAnimating ? 1 : 0,
                        transform: isMenuAnimating ? 'translateX(0)' : 'translateX(2rem)'
                      }}
                    >
                      <button className="w-full flex items-center justify-center space-x-2 bg-gradient-to-r from-amber-400 to-amber-500 hover:from-amber-500 hover:to-amber-600 text-gray-900 px-4 py-3 rounded-lg transition-all duration-300 font-medium shadow-lg mt-6">
                        <Wallet size={16} />
                        <span>Wallet Connected</span>
                        <Zap size={16} className="animate-pulse" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* Reduced spacer div height */}
      <div className="h-10 lg:h-[3.5rem]">

      </div>
     
    </>
    
  );
  
};

export default Header;