import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ref, get, push, set, serverTimestamp } from 'firebase/database';
import { auth, database } from './firebase';
import { ArrowLeft, AlertCircle, Clock, DollarSign, FileText, ShieldCheck, BadgeCheck, Wallet, ArrowDownRight, AlertTriangle,ArrowRight } from 'lucide-react';
import TradeChat from './chat';

const TradeAlertSection = () => (
  <div className="bg-gray-900 border border-yellow-500/50 rounded-lg p-5">
    <div className="flex items-center space-x-3 mb-4">
      <ShieldCheck className="h-5 w-5 text-yellow-500 shrink-0" />
      <h4 className="text-yellow-500 font-medium">System Verification Notice</h4>
    </div>

    <div className="space-y-3 pl-8">
      <div className="relative">
        <div className="absolute left-0 top-1.5 w-1.5 h-1.5 rounded-full bg-yellow-500/70"></div>
        <p className="text-gray-300 text-sm pl-4">
          <span className="text-yellow-500/90 font-medium">Payment Details Verified:</span>{' '}
          All payment information provided by the seller has been automatically captured and validated by our system.
        </p>
      </div>

      <div className="relative">
        <div className="absolute left-0 top-1.5 w-1.5 h-1.5 rounded-full bg-yellow-500/70"></div>
        <p className="text-gray-300 text-sm pl-4">
          <span className="text-yellow-500/90 font-medium">Seller Authentication:</span>{' '}
          The seller's account and provided payment details have been verified and confirmed.
        </p>
      </div>

      <div className="relative">
        <div className="absolute left-0 top-1.5 w-1.5 h-1.5 rounded-full bg-yellow-500/70"></div>
        <p className="text-gray-300 text-sm pl-4">
          <span className="text-yellow-500/90 font-medium">Payment Instructions:</span>{' '}
          Follow the seller's payment instructions precisely to ensure successful transaction processing.
        </p>
      </div>

      <div className="relative">
        <div className="absolute left-0 top-1.5 w-1.5 h-1.5 rounded-full bg-yellow-500/70"></div>
        <p className="text-gray-300 text-sm pl-4">
          <span className="text-yellow-500/90 font-medium">Automatic Release:</span>{' '}
          Assets will be automatically released to your wallet once payment confirmation is received.
        </p>
      </div>
    </div>

    <div className="mt-4 flex items-start space-x-2 bg-gray-900/50 p-3 rounded-md">
      <AlertCircle className="h-5 w-5 text-yellow-500 mt-0.5 shrink-0" />
      <p className="text-sm text-gray-400">
        For your security, only proceed with payment using the verified details provided above. Do not accept alternative payment instructions from external sources.
      </p>
    </div>
  </div>
);
const TradeDetailsHeader = ({ expiryTime }) => (
  <div className="mb-8">

    <div className="space-y-2.5">
      <div className="flex items-center space-x-3">
        <div className="w-1.5 h-1.5 rounded-full bg-yellow-500/70 mt-1 shrink-0"></div>
        <p className="text-sm text-gray-300">
          After completing the payment, click <span className="text-yellow-500 font-medium">"Mark as Paid"</span> to notify the seller
        </p>
      </div>

      <div className="flex items-center space-x-3">
        <div className="w-1.5 h-1.5 rounded-full bg-yellow-500/70 mt-1 shrink-0"></div>
        <p className="text-sm text-gray-300">
          Order will be automatically cancelled if payment is not made within <span className="text-yellow-500 font-medium">the allowed timeframe{expiryTime}</span>
        </p>
      </div>

      <div className="flex items-center space-x-3">
        <div className="w-1.5 h-1.5 rounded-full bg-yellow-500/70 mt-1 shrink-0"></div>
        <p className="text-sm text-gray-300">
          Please ensure to use your personal account/info when making the payment. Third-party payments are not accepted
        </p>
      </div>
    </div>
  </div>
);

const TradeDetails = () => {
  const [offerDetails, setOfferDetails] = useState(null);
  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cryptoAmount, setCryptoAmount] = useState(null);
  const [priceData, setPriceData] = useState(null);
  const navigate = useNavigate();
  const { offerId } = useParams();
  const [buyer, setBuyer] = useState(null);

  const fetchCryptoPrice = async (asset) => {
    try {
      const symbol = `${asset}USDT`;
      const response = await fetch(`https://api.binance.com/api/v3/ticker/price?symbol=${symbol}`);
      
      if (!response.ok) throw new Error('Failed to fetch price');
      
      const data = await response.json();
      return parseFloat(data.price);
    } catch (error) {
      console.error('Error fetching price:', error);
      return null;
    }
  };

  useEffect(() => {
    // Set buyer information when component mounts
    if (auth.currentUser) {
      setBuyer({
        id: auth.currentUser.uid,
        displayName: auth.currentUser.displayName || 'Buyer'
      });
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch offer details
        const offerRef = ref(database, `offers/${offerId}`);
        const snapshot = await get(offerRef);

        if (snapshot.exists()) {
          const offerData = snapshot.val();
          setOfferDetails(offerData);

          // Fetch current market price for the crypto
          const currentPrice = await fetchCryptoPrice(offerData.asset);
          setPriceData(currentPrice);
        } else {
          setError('Offer not found');
        }
        setLoading(false);
      } catch (err) {
        setError('Failed to load offer details');
        setLoading(false);
      }
    };

    fetchOfferDetails();
  }, [offerId]);


  useEffect(() => {
    if (amount && priceData) {
      const calculatedAmount = parseFloat(amount) / priceData;
      setCryptoAmount(calculatedAmount);
    }
  }, [amount, priceData]);

  const validateAmount = (inputAmount) => {
    const numAmount = parseFloat(inputAmount);
    if (isNaN(numAmount)) {
      setError('Please enter a valid amount');
      return false;
    }
    if (numAmount < parseFloat(offerDetails.minLimit)) {
      setError(`Amount must be at least $${offerDetails.minLimit}`);
      return false;
    }
    if (numAmount > parseFloat(offerDetails.maxLimit)) {
      setError(`Amount cannot exceed $${offerDetails.maxLimit}`);
      return false;
    }
    return true;
  };

  const handleAmountSubmit = () => {
    if (validateAmount(amount)) {
      setError('');
      setShowDetails(true);
    }
  };
  const fetchOfferDetails = async () => {
    try {
      const offerRef = ref(database, `offers/${offerId}`);
      const snapshot = await get(offerRef);

      if (snapshot.exists()) {
        const offerData = snapshot.val();
        setOfferDetails(offerData);

        // Fetch current market price for the crypto
        const currentPrice = await fetchCryptoPrice(offerData.asset);
        setPriceData(currentPrice);
      } else {
        setError('Offer not found');
      }
      setLoading(false);
    } catch (err) {
      console.error('Error fetching offer details:', err);
      setError('Failed to load offer details');
      setLoading(false);
    }
  };
  const formatCryptoAmount = (amount, asset) => {
    if (!amount) return '0';
    const decimals = {
      'BTC': 8,
      'ETH': 6,
      'USDT': 2
    };
    return amount.toFixed(decimals[asset] || 8);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
      </div>
    );
  }

  if (error && !showDetails) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="text-white">{error}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 p-4">
      <div className="max-w-3xl mx-auto">
        {/* Header */}
        <button
          onClick={() => navigate('/p2p')}
          className="flex items-center text-gray-400 hover:text-white mb-6"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to Offers
        </button>

        <div className="bg-gray-800 rounded-lg p-6">
        {!showDetails ? (
  <div className="space-y-8">
    {/* Header */}
    <div className="flex items-center gap-4">
      <div className="h-12 w-12 rounded-xl bg-gradient-to-br from-[#02C076] to-[#02A566] p-0.5">
        <div className="h-full w-full bg-gray-900 rounded-xl flex items-center justify-center">
          <Wallet className="text-[#02C076]" size={24} />
        </div>
      </div>
      <div>
        <h2 className="text-xl font-bold text-white">Enter Trade Amount</h2>
        <p className="text-sm text-gray-400 mt-1">How much do you want to buy?</p>
      </div>
    </div>

    <div className="space-y-6">
      {/* Amount Input Card */}
      <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-5 border border-gray-700/50">
        <div className="space-y-4">
          {/* USD Input */}
          <div>
            <div className="flex items-center justify-between mb-2">
              <label className="text-sm font-medium text-gray-400">Amount</label>
              
            </div>
            <div className="relative">
              <div className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400">$</div>
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="w-full pl-8 pr-16 py-3.5 bg-gray-900/50 border border-gray-700 rounded-lg text-white text-lg font-medium focus:outline-none focus:border-[#02C076] focus:ring-1 focus:ring-[#02C076]/20 transition-all"
                placeholder="0.00"
              />
              <div className="absolute right-4 top-1/2 -translate-y-1/2 text-sm text-gray-400 font-medium">
                USD
              </div>
            </div>

            {/* KES Conversion */}
            {(offerDetails.paymentMethod || '').toLowerCase().includes('pesa') && (
              <div className="flex items-center gap-2 mt-3 p-2 bg-gray-900/50 rounded-lg border border-gray-700/50">
                <ArrowDownRight size={14} className="text-[#02C076]" />
                <span className="text-sm text-gray-400">
                  ≈ <span className="text-white font-medium">KES {(amount * 129).toLocaleString()}</span>
                </span>
              </div>
            )}
          </div>

          {/* Quick Amount Buttons */}
          <div className="grid grid-cols-4 gap-2">
            {[100, 500, 1000, 5000].map((quickAmount) => (
              <button
                key={quickAmount}
                onClick={() => setAmount(quickAmount)}
                className="py-1.5 text-sm bg-gray-900/50 hover:bg-gray-900 text-gray-300 rounded-lg border border-gray-700/50 transition-colors"
              >
                ${quickAmount}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Trade Limits Card */}
      <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-5 border border-gray-700/50">
        <div className="flex items-start gap-3">
          <AlertCircle className="w-5 h-5 text-[#02C076] flex-shrink-0 mt-0.5" />
          <div>
            <h3 className="text-sm font-medium text-white mb-2">Available Trade Limits</h3>
            <div className="space-y-1">
              {(offerDetails.paymentMethod || '').toLowerCase().includes('pesa') ? (
                <>
                  <div className="text-lg font-medium text-white">
                    KES {(offerDetails.minLimit * 129).toLocaleString()} - {(offerDetails.maxLimit * 129).toLocaleString()}
                  </div>
                  <div className="text-sm text-gray-400">
                    (${offerDetails.minLimit.toLocaleString()} - ${offerDetails.maxLimit.toLocaleString()} USD)
                  </div>
                </>
              ) : (
                <div className="text-lg font-medium text-white">
                  ${offerDetails.minLimit.toLocaleString()} - ${offerDetails.maxLimit.toLocaleString()}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Error Message */}
      {error && (
        <div className="flex items-center gap-2 text-red-500 bg-red-500/10 px-4 py-3 rounded-lg">
          <AlertTriangle size={16} />
          <span className="text-sm font-medium">{error}</span>
        </div>
      )}

      {/* Action Button */}
      <button
        onClick={handleAmountSubmit}
        className="w-full py-3.5 bg-gradient-to-r from-[#02C076] to-[#02A566] hover:from-[#02A566] hover:to-[#02C076] text-white rounded-lg font-medium text-base transition-all duration-300 focus:ring-2 focus:ring-[#02C076]/20 group"
      >
        <span className="flex items-center justify-center gap-2">
          Continue to Review
          <ArrowRight size={18} className="group-hover:translate-x-0.5 transition-transform" />
        </span>
      </button>
    </div>
  </div>
) : (
            // Trade Details Section
            <div>
              <h2 className="text-xl font-bold text-white mb-6">Trade Details</h2>
              <TradeDetailsHeader />
              <div className="space-y-6">
                
                {/* Summary */}
                <div className="bg-gray-700/50 rounded-lg p-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <div className="text-sm text-gray-400 mb-1">You're buying</div>
                      <div className="text-white font-medium">
                        {formatCryptoAmount(cryptoAmount, offerDetails.asset)} {offerDetails.asset}
                      </div>
                      <div className="text-sm text-gray-400 mt-1">
                        @ ${priceData?.toLocaleString()} per {offerDetails.asset}
                      </div>
                    </div>
                    <div>
                      <div className="text-sm text-gray-400 mb-1">Total amount</div>
                      <div className="text-white font-medium">
                        ${parseFloat(amount).toLocaleString()} USD
                        {(offerDetails.paymentMethod || '').toLowerCase().includes('pesa') && (
                          <div className="text-sm text-gray-400 mt-1">
                            ≈ KES {(parseFloat(amount) * 129).toLocaleString()}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Time Limit */}
                <div className="flex items-center space-x-2 text-yellow-500">
                  <Clock className="w-5 h-5" />
                  <span>Please complete the payment within {offerDetails.expiryTime} minutes</span>
                </div>

                {/* Payment Method */}
                {/* Payment Method */}
                <div>
                  <h3 className="text-lg font-medium text-white mb-3">Payment Method</h3>
                  <div className="bg-gray-700/50 rounded-lg p-4">
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center">
                        <div className="w-1 h-5 bg-green-500 mr-2"></div>
                        <span className="text-white">{offerDetails.paymentMethod}</span>
                      </div>
                      <span className="text-sm text-gray-400">Preferred</span>
                    </div>
                    {/* Add KES payment info for M-Pesa */}
                    {(offerDetails.paymentMethod || '').toLowerCase().includes('pesa') && (
                      <div className="mt-2 text-sm text-gray-400">
                        Please send KES {(parseFloat(amount) * 129).toLocaleString()} to the provided M-Pesa details
                      </div>
                    )}
                  </div>
                </div>

                {/* Instructions */}
                <div>
                  <h3 className="text-lg font-medium text-white mb-3">Payment Instructions</h3>

                  <div className="space-y-4">
                    {/* Payment Info */}
                    <div className="bg-gray-800 rounded p-3">
                      <div className="text-sm text-gray-200 mb-1">Payment details provided by the seller</div>
                      <div className="flex items-center gap-2">
                        <div className="text-gray-400 whitespace-pre-wrap font-semibold">
                          {offerDetails.paymentInfo}
                        </div>
                      </div>
                      <div className="flex items-center gap-1 text-xs text-green-500">
                        <BadgeCheck className="w-3.5 h-3.5 flex-shrink-0" />
                        <span>Payment Details Verified</span>
                      </div>
                    </div>

                    {/* Instructions */}
                    <div className="bg-gray-800 rounded p-3">
                      <div className="text-sm text-gray-400 mb-1">Instructions from the seller</div>
                      <div className="text-white whitespace-pre-wrap">
                        {offerDetails.instructions}
                      </div>
                    </div>

                    {/* System Verification Alert */}
                    <TradeAlertSection />
                  </div>

                </div>


                <div className="flex gap-3">
                  <button
                    onClick={() => setShowDetails(false)}
                    className="flex-1 py-3 border border-gray-600 text-white rounded-lg font-medium hover:bg-gray-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      // First create a trade record in Firebase
                      const tradeRef = ref(database, 'trades');
                      const newTradeRef = push(tradeRef);

                      // Calculate KES amount if payment method is M-Pesa
                      const kesAmount = (offerDetails.paymentMethod || '').toLowerCase().includes('pesa')
                        ? parseFloat(amount) * 129
                        : null;

                      const tradeData = {
                        fiatAmount: parseFloat(amount),
                        cryptoAmount: cryptoAmount,
                        asset: offerDetails.asset,
                        paymentMethod: offerDetails.paymentMethod,
                        sellerName: offerDetails.sellerName,
                        sellerId: offerDetails.sellerId,
                        buyerId: auth.currentUser.uid,
                        offerId: offerId,
                        expiryTime: offerDetails.expiryTime,
                        status: 'active',
                        createdAt: serverTimestamp(),
                        kesAmount: kesAmount, // Add KES amount if payment is M-Pesa
                      };

                      // Save trade data and navigate
                      set(newTradeRef, tradeData)
                        .then(() => {
                          navigate(`/trade/process/${newTradeRef.key}`);
                        })
                        .catch((error) => {
                          console.error('Error creating trade:', error);
                          alert('Failed to initiate trade. Please try again.');
                        });
                    }}
                    className="flex-1 py-3 bg-[#02C076] hover:bg-[#02A566] text-white rounded-lg font-medium"
                  >
                    Initiate Payment
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Add the chat component */}
        {/* Add the chat component */}
        {showDetails && offerDetails && buyer && (
          <TradeChat
            offer={{
              ...offerDetails,
              id: offerId  // Add the offerId from useParams
            }}
            buyer={buyer}
            seller={{
              id: offerDetails.sellerId,
              displayName: offerDetails.sellerName
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TradeDetails;