import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ref, get, update } from 'firebase/database';
import { database, auth } from './firebase';
import {
  ArrowLeft, AlertTriangle, Clock, Shield, CheckCircle2,
  AlertOctagon, Timer
} from 'lucide-react';
import TradeChat from './chat';

const TradeProcess = () => {
  const [offerDetails, setOfferDetails] = useState(null);
  const [tradeDetails, setTradeDetails] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [disputeTimeLeft, setDisputeTimeLeft] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPaid, setIsPaid] = useState(false);
  const [showPaidConfirmation, setShowPaidConfirmation] = useState(false);
  const [buyer, setBuyer] = useState(null);
  const navigate = useNavigate();
  const { tradeId } = useParams();

  const handleMarkAsPaid = async () => {
    try {
      const tradeRef = ref(database, `trades/${tradeId}`);

      // Update trade status to 'paid'
      await update(tradeRef, {
        status: 'paid',
        paidAt: Date.now()
      });

      // Also update the corresponding offer status
      if (tradeDetails.offerId) {
        const offerRef = ref(database, `offers/${tradeDetails.offerId}`);
        await update(offerRef, {
          status: 'pending_release' // or whatever status you want for offers with paid trades
        });
      }

      // Show success message
      alert('Payment marked as sent. Waiting for seller to release assets.');

      // Optionally refresh the page or navigate to view updated status
      window.location.reload();
    } catch (error) {
      console.error('Error marking as paid:', error);
      alert('Failed to mark payment as sent. Please try again.');
    }
  };
  // Use this confirmation modal state
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleTradeCancel = async (reason) => {
    // Add confirmation dialog
    if (window.confirm('Are you sure you want to cancel this trade?')) {
      try {
        const tradeRef = ref(database, `trades/${tradeId}`);

        // Update trade status to 'cancelled'
        await update(tradeRef, {
          status: 'cancelled',
          cancelledAt: Date.now(),
          cancelReason: reason
        });

        // Also update the corresponding offer status back to active
        if (tradeDetails.offerId) {
          const offerRef = ref(database, `offers/${tradeDetails.offerId}`);
          await update(offerRef, {
            status: 'active'
          });
        }

        // Navigate back to P2P page
        navigate('/p2p');
      } catch (error) {
        console.error('Error cancelling trade:', error);
        alert('Failed to cancel trade. Please try again.');
      }
    }
  };

  // Format time function
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };
  useEffect(() => {
    if (auth.currentUser) {
      setBuyer({
        id: auth.currentUser.uid,
        displayName: auth.currentUser.displayName || 'Buyer'
      });
    }
  }, []);
  // Fetch trade details
  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const tradeRef = ref(database, `trades/${tradeId}`);
        const tradeSnapshot = await get(tradeRef);

        if (tradeSnapshot.exists()) {
          const tradeData = tradeSnapshot.val();
          setTradeDetails(tradeData);
          setTimeLeft(tradeData.expiryTime * 60);
          setDisputeTimeLeft(isPaid ? 7200 : null);

          // Fetch offer details using offerId from trade data
          if (tradeData.offerId) {
            const offerRef = ref(database, `offers/${tradeData.offerId}`);
            const offerSnapshot = await get(offerRef);
            if (offerSnapshot.exists()) {
              setOfferDetails(offerSnapshot.val());
            }
          }
        } else {
          setError('Trade not found');
        }
        setLoading(false);
      } catch (err) {
        setError('Failed to load trade details');
        setLoading(false);
      }
    };

    fetchDetails();
  }, [tradeId]);

  // Timer effect
  useEffect(() => {
    if (!isPaid && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            // Cancel trade when time expires
            handleTradeCancel('Time expired');
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timeLeft, isPaid]);

  // Dispute timer effect
  useEffect(() => {
    if (isPaid && disputeTimeLeft > 0) {
      const timer = setInterval(() => {
        setDisputeTimeLeft((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [disputeTimeLeft, isPaid]);



  const confirmPayment = async () => {
    try {
      await update(ref(database, `trades/${tradeId}`), {
        status: 'paid',
        paidAt: Date.now()
      });
      setIsPaid(true);
      setShowPaidConfirmation(false);
      setDisputeTimeLeft(7200); // Start 2-hour dispute window
    } catch (err) {
      setError('Failed to update payment status');
    }
  };



  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 p-4">
      <div className="max-w-3xl mx-auto">
        {/* Header */}
        <button
          onClick={() => navigate('/p2p')}
          className="flex items-center text-gray-400 hover:text-white mb-6"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to P2P
        </button>

        {/* Security Alert */}
        <div className="bg-yellow-500/10 border border-yellow-500/20 rounded-lg p-4 mb-6">
          <div className="flex items-start">
            <AlertTriangle className="w-5 h-5 text-yellow-500 mt-0.5 mr-3 flex-shrink-0" />
            <div>
              <h3 className="text-yellow-500 font-medium mb-1">Security Notice</h3>
              <p className="text-gray-300 text-sm">
                Only proceed with payment using the agreed payment method. The seller's assets are locked in escrow and will only be released after payment confirmation. Never share payment evidence outside this platform.
              </p>
            </div>
          </div>
        </div>

        {/* Trade Summary */}
        <div className="bg-gray-800 rounded-lg p-4 mb-6">
  <h2 className="text-base font-semibold text-white mb-4">Trade Summary</h2>

  <div className="space-y-6">
    {/* Amount to Pay Section */}
    <div className="relative pb-6 border-l-2 border-gray-700 pl-4">
      <div className="absolute -left-[5px] top-0 w-2 h-2 rounded-full bg-yellow-600"></div>
      <div className="space-y-1">
        <h3 className="text-sm font-medium text-white">Amount to Pay</h3>
        <p className="text-base font-semibold text-green-500">
          {(tradeDetails?.paymentMethod || '').toLowerCase().includes('pesa') 
            ? `KES ${(tradeDetails?.fiatAmount * 129).toLocaleString()}`
            : `$${tradeDetails?.fiatAmount.toLocaleString()}`
          }
        </p>
        <p className="text-xs text-gray-400">
          You are sending {(tradeDetails?.paymentMethod || '').toLowerCase().includes('pesa')
            ? `KES ${(tradeDetails?.fiatAmount * 129).toLocaleString()} (${tradeDetails?.fiatAmount.toLocaleString()} USD)`
            : `$${tradeDetails?.fiatAmount.toLocaleString()}`
          } to {tradeDetails?.sellerName} through {tradeDetails?.paymentMethod} {offerDetails?.paymentInfo}
        </p>
      </div>
    </div>

    {/* Payment Method Section */}
    <div className="relative pb-6 border-l-2 border-gray-700 pl-4">
      <div className="absolute -left-[5px] top-0 w-2 h-2 rounded-full bg-yellow-600"></div>
      <div className="space-y-1">
        <h3 className="text-sm font-medium text-white">Payment Method</h3>
        <p className="text-base font-medium text-white">
          {tradeDetails?.paymentMethod}
        </p>
        <p className="text-xs text-gray-400">
          Please complete your purchase through {tradeDetails?.paymentMethod} and once done, mark the order as paid
        </p>
      </div>
    </div>

    {/* You'll Receive Section */}
    <div className="relative pb-6 border-l-2 border-gray-700 pl-4">
      <div className="absolute -left-[5px] top-0 w-2 h-2 rounded-full bg-yellow-600"></div>
      <div className="space-y-1">
        <h3 className="text-sm font-medium text-white">You'll Receive</h3>
        <p className="text-base font-medium text-white">
          {tradeDetails?.cryptoAmount} {tradeDetails?.asset}
        </p>
        <p className="text-xs text-gray-400">
          You'll receive {tradeDetails?.cryptoAmount} {tradeDetails?.asset} to your wallet once the seller confirms your payment
        </p>
      </div>
    </div>

    {/* Seller Section */}
    <div className="relative border-l-2 border-gray-700 pl-4">
      <div className="absolute -left-[5px] top-0 w-2 h-2 rounded-full bg-yellow-600"></div>
      <div className="space-y-1">
        <h3 className="text-sm font-medium text-white">Seller</h3>
        <p className="text-base font-medium text-white">
          {tradeDetails?.sellerName}
        </p>
        <p className="text-xs text-gray-400">
          {tradeDetails?.sellerName} is waiting to receive your payment. Mark as paid once done
        </p>
      </div>
    </div>
  </div>
</div>

        {/* Timer and Status */}
        <div className="bg-gray-800 rounded-lg p-6 mb-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Clock className="w-5 h-5 text-yellow-500 mr-2" />
              {tradeDetails?.status === 'paid' ? (
                <div className="flex flex-col">
                  <span className="text-yellow-500 font-medium">
                    Dispute window: {formatTime(disputeTimeLeft)}
                  </span>
                  <span className="text-sm text-gray-400">
                    Time remaining to resolve any issues
                  </span>
                </div>
              ) : (
                <div className="flex flex-col">
                  <span className="text-yellow-500 font-medium">
                    Time remaining: {formatTime(timeLeft)}
                  </span>
                  <span className="text-sm text-gray-400">
                    Complete the payment before time expires
                  </span>
                </div>
              )}
            </div>
            <div className="flex items-center">
              <Shield className="w-5 h-5 text-green-500 mr-2" />
              <span className="text-green-500">Assets in Escrow</span>
            </div>
          </div>
        </div>


        {/* Action Buttons */}
        <div className="flex gap-4">
          {tradeDetails?.status === 'paid' ? (
            // If trade is already marked as paid
            <div className="w-full">
              <div className="bg-gray-800 rounded-lg p-6">
                <div className="flex items-center justify-center text-green-500 mb-4">
                  <CheckCircle2 className="w-8 h-8 mr-3" />
                  <span className="text-lg font-medium">Payment Marked as Sent</span>
                </div>
                <p className="text-center text-gray-400 mb-4">
                  You have marked this payment as sent. Waiting for the seller to release the assets.
                </p>
                <div className="flex items-center justify-center text-sm text-gray-500">
                  <Clock className="w-4 h-4 mr-1" />
                  Payment marked at: {new Date(tradeDetails.paidAt).toLocaleString()}
                </div>
              </div>

              {/* Dispute button appears after payment is marked */}
              {disputeTimeLeft > 0 && (
                <button
                  onClick={() => {/* Handle dispute action */ }}
                  className="w-full mt-4 py-3 border border-red-500 text-red-500 rounded-lg font-medium hover:bg-red-500/10"
                >
                  Open Dispute
                </button>
              )}
            </div>
          ) : (
            // If trade is not yet marked as paid
            <>
              <button
                onClick={() => handleTradeCancel('User cancelled')}
                className="flex-1 py-3 border border-gray-600 text-white rounded-lg font-medium hover:bg-gray-800"
                disabled={timeLeft === 0}
              >
                Cancel Trade
              </button>
              <button
                onClick={handleMarkAsPaid}
                className="flex-1 py-3 bg-[#02C076] hover:bg-[#02A566] text-white rounded-lg font-medium"
                disabled={timeLeft === 0}
              >
                Mark as Paid
              </button>
            </>
          )}
        </div>

        {/* Payment Confirmation Modal */}
        {showPaidConfirmation && (
          <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center p-4 z-50">
            <div className="bg-gray-800 rounded-lg p-6 max-w-md w-full">
              <h3 className="text-lg font-bold text-white mb-4">Confirm Payment</h3>
              <p className="text-gray-300 mb-4">
                I confirm that I have sent ${tradeDetails?.fiatAmount.toLocaleString()} to {tradeDetails?.sellerName} through {tradeDetails?.paymentMethod}.
              </p>
              <div className="flex gap-3">
                <button
                  onClick={() => setShowPaidConfirmation(false)}
                  className="flex-1 py-2 border border-gray-600 text-white rounded-md hover:bg-gray-700"
                >
                  Cancel
                </button>
                <button
                  onClick={confirmPayment}
                  className="flex-1 py-2 bg-[#02C076] hover:bg-[#02A566] text-white rounded-md"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
        {!loading && tradeDetails && buyer && (
          <div className="mt-6">
            <TradeChat
              offer={{
                id: tradeDetails.offerId,
                sellerName: tradeDetails.sellerName,
                sellerId: tradeDetails.sellerId,
                amount: tradeDetails.fiatAmount,  // Add the fiat amount
                asset: tradeDetails.asset,        // Add the asset type
                paymentMethod: tradeDetails.paymentMethod,  // Add payment method
                // Include any other offer details that might be needed
                status: tradeDetails.status,
                paymentInfo: tradeDetails.paymentInfo,
                instructions: tradeDetails.instructions,
                expiryTime: tradeDetails.expiryTime
              }}
              buyer={buyer}
              seller={{
                id: tradeDetails.sellerId,
                displayName: tradeDetails.sellerName
              }}
            />
          </div>
        )}

      </div>
    </div>
  );
};

export default TradeProcess;