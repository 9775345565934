import React, { useEffect, useState } from 'react';
import {
  Shield, Users, Clock, ChevronRight, Wallet,
  TrendingUp, BadgeCheck, ArrowUpRight,
  MessageCircle, Activity, ArrowUp, ArrowDown
} from 'lucide-react';
import badgeIcon from './assets/images/badge.png';

const P2POffersList = ({ offers, onBuyClick, onChatClick, isUserOffer, onDeleteOffer }) => {
  const [currentMarketPrice, setCurrentMarketPrice] = useState(null);
  const [showChatTooltip, setShowChatTooltip] = useState(null);
  const [btcLiquidity, setBtcLiquidity] = useState(null);

  const handleChatClick = (offerId) => {
    setShowChatTooltip(offerId);
    setTimeout(() => setShowChatTooltip(null), 3000); // Hide after 3 seconds
  };
  const getRiskColor = (level) => {
    const colors = {
      'Very Low': 'text-green-400',
      'Low': 'text-emerald-400',
      'Medium': 'text-yellow-400',
      'High': 'text-orange-400',
      'Very High': 'text-red-400'
    };
    return colors[level] || 'text-gray-400';
  };
  useEffect(() => {
    const fetchBTCPrice = async () => {
      try {
        const response = await fetch('https://api.binance.com/api/v3/ticker/24hr?symbol=BTCUSDT');
        const data = await response.json();
        
        setCurrentMarketPrice(parseFloat(data.lastPrice));
        setBtcLiquidity(parseFloat(data.volume)); // 24h volume in BTC
      } catch (error) {
        console.error('Error fetching BTC data:', error);
      }
    };
  
    fetchBTCPrice();
    // Update every minute
    const interval = setInterval(fetchBTCPrice, 60000);
    
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchBTCData = async () => {
      try {
        // Fetch both price and market data
        const response = await fetch(
          'https://api.coingecko.com/api/v3/coins/bitcoin?localization=false&tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false'
        );
        const data = await response.json();

        setCurrentMarketPrice(data.market_data.current_price.usd);
        // Total volume in BTC
        const volumeInBTC = data.market_data.total_volume.usd / data.market_data.current_price.usd;
        setBtcLiquidity(volumeInBTC);
      } catch (error) {
        console.error('Error fetching BTC data:', error);
      }
    };

    fetchBTCData();
    const interval = setInterval(fetchBTCData, 60000);

    return () => clearInterval(interval);
  }, []);
  const formatBTCVolume = (volume) => {
    if (!volume) return '...';
    if (volume >= 1000000) return `₿ ${(volume / 1000000).toFixed(1)}M`;
    if (volume >= 1000) return `₿ ${(volume / 1000).toFixed(1)}K`;
    return `₿ ${volume.toFixed(1)}`;
  };

  return (
    <div className="space-y-4">
      {offers.map((offer) => (
        <div key={offer.id} className="group">
          <div className="p-4 sm:p-5 bg-gray-800/30 backdrop-blur-xl rounded-xl border border-gray-700/50 hover:border-yellow-500/50 transition-all">
            <div className="grid grid-cols-12 gap-4 sm:gap-6">

              {/* Merchant Info */}
              <div className="col-span-12 sm:col-span-6 lg:col-span-4">
              <div className="flex items-start gap-3">
  <div className="relative flex-shrink-0">
    <div className="h-10 w-10 rounded-lg bg-gradient-to-br from-gray-700 to-gray-600 flex items-center justify-center">
      <span className="text-sm font-bold text-yellow-500">{offer.sellerName[0]}</span>
    </div>
    {offer.verified && (
      <BadgeCheck className="absolute -bottom-1 -right-1 text-yellow-500 bg-gray-900 rounded-full" size={16} />
    )}
  </div>

  <div className="min-w-0 flex-1">
    <div className="flex items-center gap-2">
      <h3 className="text-sm font-medium text-gray-100 truncate">{offer.sellerName}</h3>
      <img
        src={badgeIcon}
        alt="Verified"
        className="w-3.5 h-3.5 object-contain"
      />
      <div className="flex items-center gap-1.5 bg-[#02C076]/10 px-2 py-0.5 rounded-full">
        <div className="w-1.5 h-1.5 bg-[#02C076] rounded-full animate-pulse"></div>
        <span className="text-xs text-[#02C076] font-medium">Online now</span>
      </div>
    </div>

    <div className="mt-1 flex flex-wrap items-center gap-2 text-xs text-gray-400">
      
      <span className="flex items-center gap-1">
        <TrendingUp size={12} />
        {formatBTCVolume(btcLiquidity)}
      </span>
    </div>
  </div>
</div>



                {/* Quick Stats */}
                <div className="mt-3 grid grid-cols-2 gap-2">
                  <div className="bg-gray-900/50 rounded-lg p-2">
                    <div className="text-xs text-gray-400">Orders</div>
                    <div className="text-sm font-medium text-yellow-500">{offer.orders} orders</div>
                  </div>
                  <div className="bg-gray-900/50 rounded-lg p-2">
                    <div className="text-xs text-gray-400">Response</div>
                    <div className="text-sm font-medium text-yellow-500">fast</div>
                  </div>
                </div>
              </div>


              {/* Price and Trade Info */}
              <div className="col-span-12 sm:col-span-6 lg:col-span-5">
                <div className="flex flex-col h-full justify-between">
                  <div>
                    <div className="flex items-baseline gap-1.5">
                      <span className="text-lg sm:text-xl font-bold bg-gradient-to-r from-yellow-500 to-yellow-300 bg-clip-text text-transparent">
                        ${currentMarketPrice?.toLocaleString() || 'Loading...'}
                      </span>
                      <span className="text-xs text-gray-400">per {offer.asset}</span>
                      <span className="flex items-center text-xs text-green-400">
                        <ArrowDown size={12} />
                        same as market price
                      </span>
                    </div>

                    <div className="mt-2 space-y-1">
                    <div className="text-xs text-gray-400">
  Available: <span className="text-gray-300">
    {(offer.paymentMethod || '').toLowerCase().includes('pesa') 
      ? `KES ${(parseFloat(offer.amount) * 129).toLocaleString()}`
      : `$${offer.amount} USD`
    }
  </span>
</div>
                      <div className="flex gap-4">
                        <div className="text-xs text-gray-400">
                          Min: <span className="text-gray-300">
                            {(offer.paymentMethod || '').toLowerCase() === 'mpesa' ||
                              (offer.paymentMethod || '').toLowerCase() === 'm-pesa'
                              ? `KES ${(parseFloat(offer.minLimit) * 129).toLocaleString()}`
                              : `$${offer.minLimit}`
                            }
                          </span>
                        </div>
                        <div className="text-xs text-gray-400">
                          Max: <span className="text-gray-300">
                            {(offer.paymentMethod || '').toLowerCase() === 'mpesa' ||
                              (offer.paymentMethod || '').toLowerCase() === 'm-pesa'
                              ? `KES ${(parseFloat(offer.maxLimit) * 129).toLocaleString()}`
                              : `$${offer.maxLimit}`
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="text-xs text-gray-400 mb-1.5">Payment Methods</div>
                    <div className="flex flex-wrap gap-1.5">
                      {(offer.payment || [offer.paymentMethod]).map((method, idx) => (
                        <span key={idx} className="px-2 py-1 text-xs bg-gray-900/50 rounded-md text-gray-300 border border-gray-700/50">
                          {method}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* Action Buttons */}
              <div className="col-span-12 lg:col-span-3">
                <div className="flex sm:flex-col gap-2">
                  <button
                    onClick={() => onBuyClick(offer)}
                    className="flex-1 flex items-center justify-center gap-1.5 px-4 py-2 bg-gradient-to-r from-yellow-500 to-yellow-400 hover:from-yellow-400 hover:to-yellow-300 text-gray-900 text-sm font-medium rounded-lg transition-all"
                  >
                    Buy
                    <ArrowUpRight size={16} className="group-hover:translate-x-0.5 group-hover:-translate-y-0.5 transition-transform" />
                  </button>



                </div>

                <div className="mt-2 flex items-center justify-center gap-1 text-xs text-gray-400">
                  <Shield size={12} />
                  Protected Payment
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default P2POffersList;