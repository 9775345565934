import React, { useState, useEffect } from 'react';
import Header from './header'; 

import { 
  Coins,
  Mail, 
  User, 
  Phone, 
  Globe, 
  Lock,
  Eye,
  EyeOff,
  CheckCircle,
  ArrowRight,
  X,
  FileCheck,
  UserCheck,
  ShieldCheck
} from 'lucide-react';
import { auth, database } from './firebase';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { ref, get, set, query, child, orderByChild, equalTo } from 'firebase/database';


const SignupPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [animationStep, setAnimationStep] = useState(0);
  const [verificationSent, setVerificationSent] = useState(false);
  const [usernameStatus, setUsernameStatus] = useState('');
  const [checkingUsername, setCheckingUsername] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    username: '',
    phone: '',
    country: '',
    balance: 0,
    password: ''
  });
  useEffect(() => {
    let timeoutId;
    
    const checkUsername = async (username) => {
      if (!username) {
        setUsernameStatus('');
        return;
      }
    
      setCheckingUsername(true);
      try {
        // Check directly in the usernames node
        const usernameRef = ref(database, `usernames/${username}`);
        const snapshot = await get(usernameRef);
        
        setUsernameStatus(snapshot.exists() ? 'taken' : 'available');
      } catch (error) {
        console.error('Error checking username:', error);
        setUsernameStatus('error');
      } finally {
        setCheckingUsername(false);
      }
    };

    if (formData.username) {
      // Clear any existing timeout
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      // Set new timeout for debouncing
      timeoutId = setTimeout(() => {
        checkUsername(formData.username);
      }, 500);
    } else {
      setUsernameStatus('');
    }

    // Cleanup timeout on component unmount or username change
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [formData.username]);



  // Animation effect when modal opens
  React.useEffect(() => {
    if (showSuccessModal) {
      const timer1 = setTimeout(() => setAnimationStep(1), 500);
      const timer2 = setTimeout(() => setAnimationStep(2), 1000);
      const timer3 = setTimeout(() => setAnimationStep(3), 1500);
      
      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
        clearTimeout(timer3);
      };
    } else {
      setAnimationStep(0);
    }
  }, [showSuccessModal]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (usernameStatus === 'taken') {
      setError('Please choose a different username');
      return;
    }
    setLoading(true);
    setError('');
    setVerificationSent(false);
  
    try {
      // Create user auth
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
  
      const user = userCredential.user;
  
      if (user) {
        try {
          await sendEmailVerification(user);
          console.log('Verification email requested for:', user.email);
          setVerificationSent(true);
  
          // Store user data
          await Promise.all([
            // Store user data
            set(ref(database, 'users/' + user.uid), {
              username: formData.username,
              email: formData.email,
              phone: formData.phone,
              country: formData.country,
              balance: formData.balance,
              emailVerified: false,
              createdAt: new Date().toISOString()
            }),
            // Reserve username
            set(ref(database, `usernames/${formData.username}`), user.uid)
          ]);
  
          setFormData({
            email: '',
            username: '',
            phone: '',
            country: '',
            balance: 0,
            password: ''
          });
          
          setShowSuccessModal(true);
  
        } catch (verificationError) {
          console.error('Verification error:', verificationError);
          setError(`Email verification failed: ${verificationError.message}`);
        }
      }
      
    } catch (error) {
      console.error('Registration error:', error);
      let errorMessage = 'An error occurred during registration';
      
      switch (error.code) {
        case 'auth/email-already-in-use':
          errorMessage = 'This email is already registered';
          break;
        case 'auth/invalid-email':
          errorMessage = 'Invalid email address';
          break;
        case 'auth/weak-password':
          errorMessage = 'Password should be at least 6 characters';
          break;
        default:
          errorMessage = error.message;
      }
      
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // Success Modal Component with Animation
  const SuccessModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-gray-800 rounded-lg p-6 max-w-4xl w-full relative">
        <button 
          onClick={() => setShowSuccessModal(false)}
          className="absolute top-4 right-4 text-gray-400 hover:text-white"
        >
          <X className="h-6 w-6" />
        </button>
        
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Animation Section - Only visible on desktop */}
          <div className="hidden md:flex flex-col items-center justify-center w-1/2 p-6">
            <div className="flex flex-col items-center space-y-8">
              <div className={`transform transition-all duration-500 ${
                animationStep >= 1 ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'
              }`}>
                <FileCheck 
                  className="h-16 w-16 text-yellow-500"
                  strokeWidth={1.5}
                />
                <p className="text-gray-300 mt-2">Details Verified</p>
              </div>
              
              <div className={`transform transition-all duration-500 ${
                animationStep >= 2 ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'
              }`}>
                <UserCheck 
                  className="h-16 w-16 text-green-500"
                  strokeWidth={1.5}
                />
                <p className="text-gray-300 mt-2">Account Created</p>
              </div>
              
              <div className={`transform transition-all duration-500 ${
                animationStep >= 3 ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'
              }`}>
                <ShieldCheck 
                  className="h-16 w-16 text-blue-500"
                  strokeWidth={1.5}
                />
                <p className="text-gray-300 mt-2">Verification Email Sent</p>
              </div>
            </div>
          </div>
          
          {/* Content Section */}
          <div className="md:w-1/2 flex flex-col items-center text-center">
            <CheckCircle className="h-16 w-16 text-green-500 mb-4" />
            <h3 className="text-2xl font-bold text-white mb-2">Registration Successful!</h3>
            <p className="text-gray-300 mb-6">
              Your account has been created successfully. Please check your email to verify your account before logging in.
            </p>
            
            <button
              onClick={() => window.location.href = '/login'}
              className="flex items-center justify-center space-x-2 bg-yellow-500 text-gray-900 px-6 py-3 rounded-lg font-medium hover:bg-yellow-600 transition-colors"
            >
              <span>Proceed to Login</span>
              <ArrowRight className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );


  return (
    <div className="min-h-screen bg-gray-900 flex flex-col items-center justify-center p-4">
      {showSuccessModal && <SuccessModal />}
      
      <div className="max-w-md w-full space-y-8">
        {/* Rest of the component remains the same */}
        <div className="text-center">
          <div className="flex justify-center mb-4">
            <Coins className="h-12 w-12 text-yellow-500" />
          </div>
          <h2 className="text-3xl font-bold text-white">Welcome to NexiaCoin</h2>
          <p className="mt-2 text-gray-400">Register to start your trading journey</p>
        </div>

        {error && (
          <div className="bg-red-500 text-white p-3 rounded-md text-center">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="mt-8 space-y-6 bg-gray-800 p-8 rounded-lg shadow-lg">
          {/* Email */}
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
              <Mail className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="block w-full pl-10 pr-3 py-2 border border-gray-700 rounded-md bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
              placeholder="Email address"
              required
              disabled={loading}
            />
          </div>

          {/* Username */}
          <div className="relative">
    <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
      <User className="h-5 w-5 text-gray-400" />
    </div>
    <input
      type="text"
      name="username"
      value={formData.username}
      onChange={handleChange}
      className={`block w-full pl-10 pr-3 py-2 border border-gray-700 rounded-md bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent ${
        usernameStatus === 'available' ? 'border-green-500' : 
        usernameStatus === 'taken' ? 'border-red-500' : ''
      }`}
      placeholder="Username"
      required
      disabled={loading}
    />
    {formData.username && (
      <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm">
        {checkingUsername ? (
          <span className="text-gray-400">Checking...</span>
        ) : usernameStatus === 'available' ? (
          <span className="text-green-500">Username available</span>
        ) : usernameStatus === 'taken' ? (
          <span className="text-red-500">Username taken</span>
        ) : usernameStatus === 'error' ? (
          <span className="text-red-500">Error checking username</span>
        ) : null}
      </div>
    )}
  </div>

          {/* Phone */}
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
              <Phone className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="block w-full pl-10 pr-3 py-2 border border-gray-700 rounded-md bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
              placeholder="Phone number"
              required
              disabled={loading}
            />
          </div>

          {/* Country */}
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
              <Globe className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              name="country"
              value={formData.country}
              onChange={handleChange}
              className="block w-full pl-10 pr-3 py-2 border border-gray-700 rounded-md bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
              placeholder="Country"
              required
              disabled={loading}
            />
          </div>

          {/* Password */}
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
              <Lock className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="block w-full pl-10 pr-10 py-2 border border-gray-700 rounded-md bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent"
              placeholder="Password"
              required
              disabled={loading}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute inset-y-0 right-0 pr-3 flex items-center"
              disabled={loading}
            >
              {showPassword ? (
                <EyeOff className="h-5 w-5 text-gray-400" />
              ) : (
                <Eye className="h-5 w-5 text-gray-400" />
              )}
            </button>
          </div>

          {/* Submit Button */}
          <div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-lg font-medium text-gray-900 bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={loading}
            >
              {loading ? 'Signing up...' : 'Sign up'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupPage;