import React from 'react';
import { Loader } from 'lucide-react';

const MarketTrends = ({ isLoading, error, marketData, formatPrice, formatVolume, navigate }) => {
  return (
    <section>
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-base font-medium">Market Trends</h2>
        <div className="flex items-center gap-2">
          {isLoading && (
            <div className="flex items-center gap-2 text-gray-400 text-xs">
              <Loader size={12} className="animate-spin" />
              Updating...
            </div>
          )}
          <button className="text-yellow-600 hover:text-yellow-500 text-sm">
            View All Markets
          </button>
        </div>
      </div>

      {error ? (
        <div className="text-red-500 text-sm p-4 bg-gray-900 rounded-lg">
          Error loading market data: {error}
        </div>
      ) : (
        <>
          {/* Desktop Table View */}
          <div className="hidden md:block overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="text-gray-400 border-b border-gray-800 text-xs">
                  <th className="text-left pb-3">Asset</th>
                  <th className="text-right pb-3">Price</th>
                  <th className="text-right pb-3">24h Change</th>
                  <th className="text-right pb-3">24h Volume</th>
                  <th className="text-right pb-3">Market Cap</th>
                  <th className="text-right pb-3"></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-800">
                {marketData.map((coin) => (
                  <tr key={coin.id} className="hover:bg-gray-900">
                    <td className="py-3">
                      <div className="flex items-center gap-2">
                        <img
                          src={coin.image}
                          alt={coin.name}
                          className="w-6 h-6 rounded-full"
                        />
                        <div>
                          <span className="text-sm">{coin.name}</span>
                          <span className="text-xs text-gray-400 ml-1">
                            {coin.symbol.toUpperCase()}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="text-right text-sm">
                      ${formatPrice(coin.current_price)}
                    </td>
                    <td className={`text-right text-sm ${
                      coin.price_change_percentage_24h >= 0
                        ? 'text-green-500'
                        : 'text-red-500'
                    }`}>
                      {coin.price_change_percentage_24h?.toFixed(2)}%
                    </td>
                    <td className="text-right text-sm text-gray-400">
                      ${formatVolume(coin.total_volume)}
                    </td>
                    <td className="text-right text-sm text-gray-400">
                      ${formatVolume(coin.market_cap)}
                    </td>
                    <td className="text-right">
                      <button 
                        onClick={() => navigate('/p2p')}
                        className="px-3 py-1 bg-yellow-600 text-black rounded text-xs hover:bg-yellow-500"
                      >
                        Trade
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Mobile Card View */}
          <div className="md:hidden space-y-4">
            {marketData.map((coin) => (
              <div key={coin.id} className="bg-gray-900 rounded-lg p-4 space-y-3">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <img
                      src={coin.image}
                      alt={coin.name}
                      className="w-8 h-8 rounded-full"
                    />
                    <div>
                      <div className="text-sm font-medium">{coin.name}</div>
                      <div className="text-xs text-gray-400">
                        {coin.symbol.toUpperCase()}
                      </div>
                    </div>
                  </div>
                  <button 
                    onClick={() => navigate('/p2p')}
                    className="px-3 py-1 bg-yellow-600 text-black rounded text-xs hover:bg-yellow-500"
                  >
                    Trade
                  </button>
                </div>

                <div className="grid grid-cols-2 gap-2">
                  <div>
                    <div className="text-xs text-gray-400 mb-1">Price</div>
                    <div className="text-sm font-medium">
                      ${formatPrice(coin.current_price)}
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-gray-400 mb-1">24h Change</div>
                    <div className={`text-sm font-medium ${
                      coin.price_change_percentage_24h >= 0
                        ? 'text-green-500'
                        : 'text-red-500'
                    }`}>
                      {coin.price_change_percentage_24h?.toFixed(2)}%
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-gray-400 mb-1">24h Volume</div>
                    <div className="text-sm font-medium text-gray-300">
                      ${formatVolume(coin.total_volume)}
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-gray-400 mb-1">Market Cap</div>
                    <div className="text-sm font-medium text-gray-300">
                      ${formatVolume(coin.market_cap)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </section>
  );
};

export default MarketTrends;