import React, { useState, useEffect } from 'react';
import { 
  Wallet, ChevronRight, Shield, Check, Loader2, 
  Link, Lock, ArrowRight, CheckCircle2, XCircle,
  TrendingUp, Clock, Users, BadgeDollarSign, AlertCircle, ArrowLeft, Home
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { database, auth } from './firebase';
import { ref, get, set } from 'firebase/database';
const InvestmentPlans = ({ walletAddress }) => {
  const plans = [
    {
      name: 'Silver Plan',
      apy: '12%',
      duration: '3 months',
      minInvestment: '1,000',
      riskLevel: 'Low',
      features: ['Weekly rewards', 'Basic analytics', 'Email support']
    },
    {
      name: 'Gold Plan',
      apy: '18%',
      duration: '6 months',
      minInvestment: '5,000',
      riskLevel: 'Medium',
      features: ['Daily rewards', 'Advanced analytics', 'Priority support', 'Monthly strategy calls']
    },
    {
      name: 'Platinum Plan',
      apy: '25%',
      duration: '12 months',
      minInvestment: '10,000',
      riskLevel: 'High',
      features: ['Instant rewards', 'Premium analytics', '24/7 support', 'Weekly strategy calls', 'VIP events']
    }
  ];
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showSummary, setShowSummary] = useState(false);
  const handleProceed = () => {
    setShowSummary(true);
  };

  const handleBackToPlans = () => {
    setShowSummary(false);
  };

  if (showSummary && selectedPlan !== null) {
    return (
      <InvestmentSummary 
        plan={plans[selectedPlan]}
        onBack={handleBackToPlans}
        walletAddress={walletAddress}
      />
    );
  }
 

  return (
    <div className="max-w-6xl mx-auto px-4 py-8 animate-fadeIn">
      {/* Status Bar */}
      <div className="bg-gray-800/30 backdrop-blur-lg rounded-lg p-3 mb-8 flex items-center justify-between shadow-lg border border-gray-700/50">
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2 px-3 py-1 bg-gray-700/30 rounded-full">
            <div className="w-1.5 h-1.5 bg-green-500 rounded-full animate-pulse" />
            <span className="text-xs text-green-400">Active</span>
          </div>
          <div className="text-xs text-gray-400">
            Wallet: <span className="font-mono text-gray-300">{walletAddress}</span>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <Clock className="w-3 h-3 text-yellow-500" />
          <span className="text-xs text-gray-400">Last updated: Just now</span>
        </div>
      </div>

      {/* Investment Stats */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
        {[
          { label: 'Total Value Locked', value: '$12.5M', icon: Lock },
          { label: 'Active Investors', value: '15,420', icon: Users },
          { label: 'Avg. APY', value: '18.3%', icon: TrendingUp },
          { label: 'Total Returns Paid', value: '$890K', icon: BadgeDollarSign }
        ].map((stat, i) => (
          <div key={i} className="bg-gray-800/30 backdrop-blur-lg rounded-lg p-4 border border-gray-700/50">
            <div className="flex items-center space-x-2 mb-2">
              <stat.icon className="w-4 h-4 text-yellow-500" />
              <span className="text-xs text-gray-400">{stat.label}</span>
            </div>
            <div className="text-sm font-semibold text-white">{stat.value}</div>
          </div>
        ))}
      </div>

      <h2 className="text-lg font-semibold text-gray-200 mb-6">Select Your Investment Strategy</h2>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {plans.map((plan, index) => (
          <div
            key={index}
            className={`relative group bg-gray-800/30 backdrop-blur-lg rounded-xl p-5 border transition-all duration-300 
              ${selectedPlan === index 
                ? 'border-yellow-500/50 shadow-[0_0_15px_rgba(234,179,8,0.1)]' 
                : 'border-gray-700/50 hover:border-gray-600/50'}`}
          >
            {/* Plan Header */}
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="text-sm font-medium text-white mb-1">{plan.name}</h3>
                <div className="flex items-center space-x-2">
                  <span className="text-xs px-2 py-0.5 rounded-full bg-gray-700/50 text-gray-400">
                    {plan.duration}
                  </span>
                  <span className={`text-xs px-2 py-0.5 rounded-full ${
                    plan.riskLevel === 'Low' ? 'bg-green-500/10 text-green-400' :
                    plan.riskLevel === 'Medium' ? 'bg-yellow-500/10 text-yellow-400' :
                    'bg-red-500/10 text-red-400'
                  }`}>
                    {plan.riskLevel} Risk
                  </span>
                </div>
              </div>
              <div className="text-right">
                <div className="text-lg font-bold text-yellow-500">{plan.apy}</div>
                <div className="text-xs text-gray-400">APY</div>
              </div>
            </div>

            {/* Investment Info */}
            <div className="bg-gray-900/30 rounded-lg p-3 mb-4">
              <div className="text-xs text-gray-400 mb-1">Minimum Investment</div>
              <div className="text-sm font-medium text-white">{plan.minInvestment} USD</div>
            </div>

            {/* Features */}
            <ul className="space-y-2 mb-4">
              {plan.features.map((feature, fIndex) => (
                <li key={fIndex} className="flex items-center space-x-2">
                  <Check className="w-3 h-3 text-green-500" />
                  <span className="text-xs text-gray-300">{feature}</span>
                </li>
              ))}
            </ul>

            {/* Action Button */}
            <button
              onClick={() => setSelectedPlan(index)}
              className={`w-full py-2 rounded-lg text-xs transition-all duration-300 flex items-center justify-center space-x-2
                ${selectedPlan === index
                  ? 'bg-yellow-600 hover:bg-yellow-700 text-white'
                  : 'bg-gray-700/50 hover:bg-yellow-600/80 text-gray-300 hover:text-white'}`}
            >
              <span>{selectedPlan === index ? 'Selected' : 'Select Plan'}</span>
              <ArrowRight className="w-3 h-3" />
            </button>

            {/* Decorative Elements */}
            <div className="absolute inset-px bg-gradient-to-r from-yellow-500/0 via-yellow-500/5 to-yellow-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-xl pointer-events-none" />
          </div>
        ))}
      </div>

      {/* Proceed Button */}
      {selectedPlan !== null && (
      <div className="mt-8 flex justify-end">
        <button 
          onClick={handleProceed}
          className="bg-yellow-600 hover:bg-yellow-700 text-white px-6 py-2 rounded-lg text-sm flex items-center space-x-2 transition-all duration-300 group"
        >
          <span>Proceed with Investment</span>
          <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
        </button>
      </div>
    )}
    </div>
  );
};
const BackToHomeButton = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full bg-gray-800/50 border-b border-gray-700">
      <div className="max-w-6xl mx-auto px-4 py-3">
        <button
          onClick={() => navigate('/homepage')}
          className="flex items-center space-x-2 text-sm text-gray-400 hover:text-white transition-colors group"
        >
          <Home className="w-4 h-4 group-hover:text-yellow-500 transition-colors" />
          <span className="hidden sm:inline">Back to Home</span>
        </button>
      </div>
    </div>
  );
};
const ConnectionModal = ({ onComplete }) => {
  const [stage, setStage] = useState(0);
  const [error, setError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [walletId, setWalletId] = useState('');
  
  const stages = [
    { title: 'Checking Authentication', icon: Lock },
    { title: 'Fetching Wallet ID', icon: Wallet },
    { title: 'Connecting to NexiaCoin Network', icon: Shield },
    { title: 'Verifying Wallet', icon: Lock },
    { title: 'Saving Connection', icon: Shield },
    { title: 'Connection Complete', icon: CheckCircle2 }
  ];
  useEffect(() => {
    const connectWallet = async () => {
      try {
        // Stage 1: Check Authentication
        setStage(0);
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        const user = auth.currentUser;
        if (!user) {
          throw new Error('No authenticated user found');
        }

        // Stage 2: Fetch Wallet ID
        setStage(1);
        const userRef = ref(database, `users/${user.uid}`);
        const snapshot = await get(userRef);
        
        if (!snapshot.exists()) {
          throw new Error('User data not found');
        }

        const userId = user.uid;
        setWalletId(userId);
        await new Promise(resolve => setTimeout(resolve, 1000));

        // Stages 3-4: Connection and verification
        for (let i = 2; i < 4; i++) {
          setStage(i);
          await new Promise(resolve => setTimeout(resolve, 1000));
        }

        // Stage 5: Save to user's own node
        setStage(4);
        const userInvestRef = ref(database, `users/${userId}/investment`);
        await set(userInvestRef, {
          connected: true,
          timestamp: Date.now()
        });
        await new Promise(resolve => setTimeout(resolve, 1000));

        // Stage 6: Complete
        setStage(5);
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        // Show success message
        setShowSuccess(true);
        
        // Wait before transitioning to plans
        await new Promise(resolve => setTimeout(resolve, 2000));
        onComplete(userId);

      } catch (err) {
        console.error('Connection error:', err);
        setError('Connection failed. Please try again.');
      }
    };

    connectWallet();
  }, []);

  useEffect(() => {
    const fetchUserWallet = async () => {
      try {
        // Stage 1: Check Authentication
        setStage(0);
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        const user = auth.currentUser;
        if (!user) {
          throw new Error('No authenticated user found');
        }

        // Stage 2: Fetch Wallet ID
        setStage(1);
        const userRef = ref(database, `users/${user.uid}`);
        const snapshot = await get(userRef);
        
        if (!snapshot.exists()) {
          throw new Error('User data not found');
        }

        const userId = user.uid;
        setWalletId(userId);
        await new Promise(resolve => setTimeout(resolve, 1000));

        // Remaining connection stages
        for (let i = 2; i < stages.length; i++) {
          setStage(i);
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
        
        // Show success message
        setShowSuccess(true);
        
        // Wait before transitioning to plans
        await new Promise(resolve => setTimeout(resolve, 2000));
        onComplete(userId); // Pass the wallet ID to parent

      } catch (err) {
        setError(err.message || 'Connection failed. Please try again.');
      }
    };

    fetchUserWallet();
  }, []);

  if (showSuccess) {
    return (
      <div className="fixed inset-0 bg-black flex items-center justify-center z-50 p-4">
        <div className="bg-gray-800 rounded-xl max-w-md w-full p-8 relative overflow-hidden">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_0%,rgba(255,204,0,0.1),transparent)]" />
          
          <div className="relative z-10 text-center">
            <div className="mb-6 relative">
              <div className="w-20 h-20 mx-auto bg-green-500/10 rounded-full flex items-center justify-center animate-pulse">
                <CheckCircle2 className="w-12 h-12 text-green-500 animate-bounce" />
              </div>
              <div className="absolute inset-0 bg-green-500/10 rounded-full animate-ping" />
            </div>

            <h2 className="text-2xl font-bold text-green-500 mb-4">
              Wallet Connected Successfully!
            </h2>
            
            <div className="bg-gray-700/50 rounded-lg p-3 mb-6">
              <p className="text-sm text-gray-300">Wallet ID</p>
              <p className="text-white font-mono">{walletId}</p>
            </div>

            <p className="text-gray-400 mb-6">
              Proceeding to investment plans...
            </p>

            <div className="flex justify-center">
              <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-yellow-500" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black flex items-center justify-center z-50 p-4">
      <div className="bg-gray-800 rounded-xl max-w-md w-full p-8 relative overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_0%,rgba(255,204,0,0.1),transparent)]" />
        
        <div className="relative z-10">
          <div className="text-center mb-8">
            <h2 className="text-2xl font-bold text-yellow-500 mb-2">
              Connecting to NexiaCoin
            </h2>
            <p className="text-gray-400">
              Please wait while we establish a secure connection
            </p>
          </div>

          <div className="space-y-4">
            {stages.map((s, index) => (
              <div 
                key={index}
                className={`flex items-center space-x-4 p-4 rounded-lg transition-all duration-300 ${
                  index <= stage ? 'bg-gray-700/50' : 'bg-gray-700/20'
                }`}
              >
                <div className="relative">
                  {index === stage ? (
                    <div className="animate-spin">
                      <Loader2 className="w-6 h-6 text-yellow-500" />
                    </div>
                  ) : index < stage ? (
                    <Check className="w-6 h-6 text-green-500" />
                  ) : (
                    <s.icon className="w-6 h-6 text-gray-500" />
                  )}
                </div>
                <span className={`flex-1 ${
                  index <= stage ? 'text-white' : 'text-gray-500'
                }`}>
                  {s.title}
                </span>
                {index < stage && (
                  <Check className="w-5 h-5 text-green-500" />
                )}
              </div>
            ))}
          </div>

          {error && (
            <div className="mt-6 p-4 bg-red-900/20 border border-red-900 rounded-lg flex items-center space-x-3">
              <XCircle className="w-5 h-5 text-red-500" />
              <span className="text-red-400">{error}</span>
            </div>
          )}

          <div className="mt-8 h-1 bg-gray-700 rounded-full overflow-hidden">
            <div 
              className="h-full bg-yellow-500 transition-all duration-500"
              style={{ width: `${(stage + 1) * (100 / stages.length)}%` }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const InvestmentSummary = ({ plan, onBack, walletAddress }) => {
  const [userBalance, setUserBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const balanceRef = ref(database, `users/${walletAddress}/balance`);
        const snapshot = await get(balanceRef);
        setUserBalance(snapshot.exists() ? snapshot.val() : 0);
      } catch (error) {
        console.error('Error fetching balance:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBalance();
  }, [walletAddress]);

  const hasEnoughBalance = userBalance >= parseFloat(plan.minInvestment.replace(',', ''));

  const handleDeposit = () => {
    // Implement deposit navigation here
    console.log('Navigate to deposit page');
  };

  const handleConfirmInvestment = () => {
    // Implement investment confirmation here
    console.log('Confirm investment');
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-500" />
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto px-4 py-8">
      {/* Back Button */}
      <button 
        onClick={onBack}
        className="flex items-center space-x-2 text-xs text-gray-400 hover:text-white mb-6 group"
      >
        <ArrowLeft className="w-4 h-4 group-hover:-translate-x-1 transition-transform" />
        <span>Back to Plans</span>
      </button>

      <div className="bg-gray-800/30 backdrop-blur-lg rounded-xl border border-gray-700/50 overflow-hidden">
        {/* Header */}
        <div className="bg-gray-800/50 px-6 py-4 border-b border-gray-700/50">
          <h2 className="text-sm font-medium text-white">Investment Summary</h2>
        </div>

        {/* Content */}
        <div className="p-6 space-y-6">
          {/* Plan Details */}
          <div className="space-y-4">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="text-sm font-medium text-white mb-1">{plan.name}</h3>
                <span className="text-xs text-gray-400">{plan.duration} Investment Period</span>
              </div>
              <div className="text-right">
                <div className="text-lg font-bold text-yellow-500">{plan.apy}</div>
                <div className="text-xs text-gray-400">Expected APY</div>
              </div>
            </div>

            {/* Investment Details */}
            <div className="bg-gray-900/30 rounded-lg p-4 space-y-3">
              <div className="flex justify-between items-center">
                <span className="text-xs text-gray-400">Minimum Investment</span>
                <span className="text-sm font-medium text-white">{plan.minInvestment} USD</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-xs text-gray-400">Your Balance</span>
                <span className="text-sm font-medium text-white">{userBalance.toLocaleString()} USD</span>
              </div>
              <div className="border-t border-gray-700/50 pt-3">
                <div className="flex justify-between items-center">
                  <span className="text-xs text-gray-400">Status</span>
                  {hasEnoughBalance ? (
                    <div className="flex items-center space-x-1 text-green-400">
                      <CheckCircle2 className="w-3 h-3" />
                      <span className="text-xs">Sufficient Balance</span>
                    </div>
                  ) : (
                    <div className="flex items-center space-x-1 text-red-400">
                      <AlertCircle className="w-3 h-3" />
                      <span className="text-xs">Insufficient Balance</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Benefits Summary */}
            <div className="space-y-2">
              <h4 className="text-xs font-medium text-gray-300">Plan Benefits</h4>
              <ul className="grid grid-cols-2 gap-2">
                {plan.features.map((feature, index) => (
                  <li key={index} className="flex items-center space-x-2 text-xs text-gray-400">
                    <CheckCircle2 className="w-3 h-3 text-green-500" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Action Button */}
          {hasEnoughBalance ? (
            <button
              onClick={handleConfirmInvestment}
              className="w-full bg-yellow-600 hover:bg-yellow-700 text-white py-2 rounded-lg text-sm flex items-center justify-center space-x-2 transition-all duration-300 group"
            >
              <span>Confirm Investment</span>
              <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
            </button>
          ) : (
            <div className="space-y-3">
              <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-4">
                <div className="flex items-start space-x-3">
                  <AlertCircle className="w-5 h-5 text-red-500 flex-shrink-0" />
                  <div className="space-y-1">
                    <p className="text-sm text-red-400">Insufficient Balance</p>
                    <p className="text-xs text-gray-400">
                      You need at least {plan.minInvestment} USD to invest in this plan. 
                      Please deposit more funds to continue.
                    </p>
                  </div>
                </div>
              </div>
              <button
                onClick={handleDeposit}
                className="w-full bg-yellow-600 hover:bg-blue-700 text-white py-2 rounded-lg text-sm flex items-center justify-center space-x-2 transition-all duration-300 group"
              >
                <Wallet className="w-4 h-4" />
                <span>Deposit Funds</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};


// Rest of the code remains the same, just update the investment plans component

const NexiaInvestmentPortal = () => {
  const [showConnectionModal, setShowConnectionModal] = useState(false);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkExistingConnection = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          setIsLoading(false);
          return;
        }

        const userInvestRef = ref(database, `users/${user.uid}/investment`);
        const snapshot = await get(userInvestRef);

        if (snapshot.exists() && snapshot.val().connected) {
          setWalletAddress(user.uid);
          setIsWalletConnected(true);
        }
      } catch (error) {
        console.error('Error checking connection:', error);
      } finally {
        setIsLoading(false);
      }
    };

    // Listen for auth state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        checkExistingConnection();
      } else {
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleConnect = () => {
    setShowConnectionModal(true);
  };

  const handleConnectionComplete = (userId) => {
    setWalletAddress(userId);
    setShowConnectionModal(false);
    setIsWalletConnected(true);
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-black flex items-center justify-center">
        <div className="flex flex-col items-center space-y-4">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-500" />
          <p className="text-gray-400">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-gray-100">
        <BackToHomeButton />
      {/* Conditional Hero Section */}
      {!isWalletConnected ? (
        // Introduction for unconnected users
        <div className="relative py-20 px-4 overflow-hidden bg-gradient-to-b from-gray-800 to-gray-900">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_40%,rgba(255,204,0,0.1),transparent)]" />
          <div className="max-w-6xl mx-auto relative z-10">
            <div className="text-center max-w-3xl mx-auto">
              <h1 className="text-5xl font-bold mb-6 bg-gradient-to-r from-yellow-500 to-yellow-200 bg-clip-text text-transparent">
                Invest in NexiaCoin
              </h1>
              <p className="text-xl text-gray-300 mb-8">
                Connect your NexiaCoin wallet to access exclusive investment opportunities
              </p>
              <button
                onClick={handleConnect}
                className="bg-yellow-600 hover:bg-yellow-700 text-white px-8 py-4 rounded-lg text-lg font-medium flex items-center justify-center space-x-3 mx-auto group transition-all duration-300"
              >
                <Wallet className="w-5 h-5 group-hover:rotate-12 transition-transform" />
                <span>Connect Wallet</span>
                <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </button>
            </div>
          </div>
        </div>
      ) : null}
       

      {showConnectionModal && (
        <ConnectionModal onComplete={handleConnectionComplete} />
      )}

      {isWalletConnected && (
        <InvestmentPlans walletAddress={walletAddress} />
      )}
    </div>
  );
};

export default NexiaInvestmentPortal;